<template>
  <div class="home">
    <custom-section class="section pa-0 pa-md-5">
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>

      <TabContent
        :listTab="listMenu"
        :listContent="listContent"
        class="mb-6 mt-1"
        :activeTab="activeTab"
        @changeTab="(id) => (activeTab = id)"
      >
        <template v-slot:tabs_content="content">
          <v-row v-if="activeTab == '1' || activeTab == '2'" dense>
            <v-col cols="6" class="d-none d-lg-block">
              <div v-for="(item, index) of content.data" :key="index">
                <v-row
                  no-gutters
                  class="list-data"
                  v-if="
                    item.valueTitle == 'EMAIL' ||
                    item.valueTitle == 'MOBILE PHONE' ||
                    item.valueTitle == 'HOME PHONE' ||
                    item.valueTitle == 'ID CARD'
                  "
                >
                  <v-col class="text-title">
                    {{ item.valueTitleLang }}
                  </v-col>
                  <v-col class="text-value">
                    {{ item.valueLeft }}
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="list-data"
                  v-if="
                    item.valueTitle !== 'EMAIL' &&
                    item.valueTitle !== 'MOBILE PHONE' &&
                    item.valueTitle !== 'HOME PHONE' &&
                    item.valueTitle !== 'ID CARD'
                  "
                >
                  <v-col class="text-new-title">
                    {{ item.valueLeft }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="6" class="d-none d-lg-block">
              <div v-for="(item, index) of content.dataRight" :key="index">
                <v-row no-gutters class="list-data">
                  <v-col class="text-title">
                    {{ item.title }}
                  </v-col>
                  <v-col class="text-value">
                    {{ item.value }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="activeTab == '1' || activeTab == '2'" dense>
            <v-col class="d-block d-lg-none" cols="12">
              <div v-for="item of content.dataMobile" :key="item.id">
                <v-row no-gutters class="list-data">
                  <v-col class="text-new-title">
                    {{ item.valueLeft }}
                  </v-col>
                </v-row>
              </div>
              <div
                v-for="(item, index) of content.dataMobileRight"
                :key="index"
              >
                <v-row no-gutters class="list-data">
                  <v-col class="text-title">
                    {{ item.title }}
                  </v-col>
                  <v-col class="text-value">
                    {{ item.value }}
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="content.id == '3'" dense>
            <v-col cols="12">
              <card>
                <template v-slot:body>
                  <v-col cols="12" class="pa-0">
                    <AccountTab />
                  </v-col>
                </template>
              </card>
            </v-col>
          </v-row>

          <card v-if="content.id == '4'" dense>
            <template v-slot:body>
              <PatientList />
            </template>
          </card>
          <card v-if="content.id == '5'" dense>
            <template v-slot:body>
              <speciemt-referal />
            </template>
          </card>
        </template>
      </TabContent>
      <!-- graph and chart -->
      <v-row v-if="activeTab == '1'">
        <v-col cols="12" lg="7">
          <v-row>
            <v-col cols="12">
              <card rounded shadow>
                <template v-slot:body>
                  <v-data-table
                    :options.sync="options"
                    :headers="patientHeaders"
                    :items="patientDatas"
                    item-key="id"
                    mobile-breakpoint="0"
                    class="table-stick-sap super-dense elevation-1"
                    hide-default-footer
                    :loading="myloadingvariable"
                    :items-per-page="perPage"
                  >
                    <template v-slot:top>
                      <v-row
                        class="d-flex flex align-center justify-space-between px-0 pt-3 pl-2 pr-2 mt-0 pb-4"
                      >
                        <v-col cols="10" md="10">
                          <heading-card
                            class="pl-2"
                            :title="$t('page.profile.patient_today')"
                          />
                        </v-col>
                        <v-col class="d-flex justify-end" cols="2" md="2">
                          <div v-if="itemLength > 1">
                            <v-select
                              class="footerHide"
                              v-model="perPage"
                              :items="itemShowing"
                            ></v-select>
                          </div>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:item="{ item }">
                      <tr>
                        <td valign="top" class="pt-2">{{ item.order_date }}</td>
                        <td valign="top" class="pt-2 tb-wide">
                          <a
                            href="javascript:void(0);"
                            @click="openDialogOrder(item)"
                            >{{ item.order_no }}</a
                          >
                        </td>
                        <td valign="top" class="pt-2">
                          {{ item.patient_name }}
                        </td>
                        <td valign="top" class="pt-2">
                          {{ item.result }}
                        </td>
                        <td align="right" valign="top" class="pt-2">
                          {{ formater(item.referral) }}
                        </td>
                      </tr>
                    </template>

                    <template v-slot:footer>
                      <div class="d-flex align-end justify-end pa-3">
                        <p
                          class="mb-0 mr-3"
                          style="font-size: 12px; font-weight: 600"
                        >
                          {{ $t("page.profile.total") }} :
                        </p>
                        <p
                          class="mb-0"
                          style="font-size: 12px; font-weight: 600"
                        >
                          {{ formater(sumReferral()) }}
                        </p>
                      </div>
                      <v-divider></v-divider>
                      <v-row
                        class="d-flex flex align-center justify-space-between footer-payment"
                        v-if="itemLength > 1"
                      >
                        <v-col cols="12" md="3">
                          <p class="footer-showing">
                            showing {{ itemShowStart }} to {{ itemShowStop }} of
                            {{ itemsTotal }} entries
                          </p>
                        </v-col>
                        <v-col cols="12" md="9" class="d-flex justify-end">
                          <v-pagination
                            class="pageStyle"
                            v-model="page"
                            :length="itemLength"
                            :total-visible="7"
                          >
                          </v-pagination>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </template>
              </card>
            </v-col>
            <v-col>
              <card rounded shadow>
                <template v-slot:header>
                  <div class="card__header">
                    <div class="d-flex justify-space-between align-center">
                      <heading-card
                        :title="$t('page.profile.total_referral')"
                      />
                      <select-show
                        :items="DateFilterItems"
                        :value="dateFilterDefaultValue"
                        @changeDate="(nilai) => updateAllReport(nilai)"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:body>
                  <v-data-table
                    :headers="referralMonthHeader"
                    :items="referralMonthData"
                    :items-per-page="125"
                    hide-default-footer
                    item-key="date"
                    mobile-breakpoint="0"
                    class="table-stick super-dense"
                    single-expand
                    show-expand
                    @item-expanded="loadDetails"
                  >
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-simple-table class="table-expand-new">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  {{ $t("page.profile.order_no") }}
                                </th>
                                <th class="text-left">
                                  {{
                                    $t("page.profile.number_of_medical_record")
                                  }}
                                </th>
                                <th class="text-left">
                                  {{ $t("page.profile.patient_name") }}
                                </th>
                                <th class="text-left">
                                  {{ $t("page.profile.referral_fee") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-if="item.details.length > 0">
                                <tr
                                  v-for="(sample, index) in item.details"
                                  :key="index"
                                >
                                  <td>
                                    <a
                                      href="javascript:void(0);"
                                      @click="openDialogOrder(sample)"
                                      >{{ sample.order_no }}</a
                                    >
                                  </td>
                                  <td>{{ sample.patient_id }}</td>
                                  <td>{{ sample.patient_name }}</td>
                                  <td>{{ formater(sample.referral) }}</td>
                                </tr>
                              </template>
                              <tr v-else>
                                <td colspan="3">
                                  {{ $t("no_data") }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </td>
                    </template>

                    <template v-slot:[`footer`]>
                      <v-divider></v-divider>
                      <v-row class="pt-2">
                        <v-col cols="6" class="d-flex align-end justify-start">
                          <p
                            class="mb-0 ml-3"
                            style="font-size: 12px; font-weight: 600"
                          >
                            {{ $t("page.profile.total") }} :
                          </p>
                        </v-col>
                        <v-col cols="6" class="d-flex align-end justify-end">
                          <p
                            class="mb-0 mr-3"
                            style="font-size: 12px; font-weight: 600"
                          >
                            {{
                              formater(sumReferralMonth("total_refferal_fee"))
                            }}
                          </p>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </template>
              </card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="5">
          <v-row>
            <v-col cols="12">
              <card rounded shadow>
                <template v-slot:header>
                  <div class="card__header">
                    <div class="d-flex justify-space-between align-center">
                      <heading-card
                        :title="$t('page.profile.patient_summary')"
                      />

                      <select-show
                        :items="DateFilterItems"
                        :value="dateFilterDefaultValue"
                        @changeDate="(nilai) => updateAllReport(nilai)"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:body>
                  <v-col cols="12">
                    <div class="card-chart">
                      <h5 class="mb-5">
                        <div
                          class="d-flex justify-start align-center chart-title"
                        >
                          <radio />
                          <p class="mb-0 ml-2">
                            {{ $t("page.profile.total_patient") }}
                          </p>
                        </div>
                      </h5>
                      <apex-summary-chart
                        :data="dataChart"
                        v-if="dataChart != null"
                        :activeRange="dateFilterDefaultValue"
                      />
                    </div>
                  </v-col>
                </template>
              </card>
            </v-col>

            <v-col cols="12">
              <card rounded shadow>
                <template v-slot:header>
                  <div class="card__header">
                    <div class="d-flex justify-space-between align-center">
                      <heading-card
                        :title="$t('page.profile.referral_fee_summary')"
                      />

                      <select-show
                        :items="DateFilterItems"
                        :value="dateFilterDefaultValue"
                        @changeDate="(nilai) => updateAllReport(nilai)"
                      />
                    </div>
                  </div>
                </template>
                <template v-slot:body>
                  <v-col cols="12">
                    <div class="card-chart">
                      <h5 class="mb-5">
                        <div
                          class="d-flex justify-start align-center chart-title"
                        >
                          <description />
                          <p class="mb-0 ml-2">
                            {{ $t("page.profile.referral_fee") }}
                          </p>
                        </div>
                      </h5>
                      <apex-referral-chart
                        :data="dataReferralChart"
                        v-if="dataReferralChart != null"
                        :activeRange="dateFilterDefaultValue"
                      />
                    </div>
                  </v-col>
                </template>
              </card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="activeTab == '2'">
        <v-col cols="12">
          <card rounded shadow>
            <template v-slot:body>
              <v-col cols="12">
                <HospitalTab v-if="activeTab == '2'" />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row>

      <v-row v-if="activeTab == '2'">
        <v-col cols="12">
          <card rounded shadow>
            <template v-slot:body>
              <v-col cols="12">
                <LicenseTab v-if="activeTab == '2'" />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row>

      <v-row v-if="activeTab == '3'">
        <v-col cols="12">
          <card rounded shadow>
            <template v-slot:body>
              <v-col cols="12">
                <TaxInfo :dataTaxInfo="dataTaxInfo" v-if="activeTab == '3'" />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row>

      <!-- <v-row v-if="activeTab == '3'">
        <v-col cols="12">
          <card rounded shadow>
            <template v-slot:body>
              <v-col cols="12">
                <AccountBalanceInfo v-if="activeTab == '3'" />
              </v-col>
            </template>
          </card>
        </v-col>
      </v-row> -->

      <DialogResult
        v-if="dialog_order"
        :item="dataDialogOrder"
        :tampilkan="dialog_order"
        @toggleDialog="(is_show) => toggleDialogOrder(is_show)"
      />
    </custom-section>
  </div>
</template>

<script>
import Card from "@/components/v2/card/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
// import SubHeadingCard from "@/components/Heading/SubHeadingCard.vue";
import SelectShow from "@/components/v2/select/SelectShow.vue";
// import SelectBulanBerjalan from "@/components/v2/select/SelectBulanBerjalan.vue";
// import Progressbar from "@/components/v2/progressbar/index.vue";
import moment from "moment";
import ApexSummaryChart from "@/views/v2/Patient/Chart/ApexSummaryChart.vue";
import ApexReferralChart from "@/views/v2/Patient/Chart/ApexReferralChart.vue";
import Radio from "@/components/v2/radio/index.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
// import Tab from "@/components/v2/switch_account/tab.vue";
import Description from "@/components/v2/radio/referral.vue";
import TabContent from "@/components/v2/tabs/index.vue";
// import EducationTab from "@/views/v2/Doctor/Tabs/EducationTab.vue";
import LicenseTab from "@/views/v2/Doctor/Tabs/LicenseTab.vue";
import AccountTab from "@/views/v2/Doctor/Tabs/AccountTab.vue";
import TaxInfo from "@/views/v2/Doctor/Tabs/TaxInfo.vue";
// import AccountBalanceInfo from "@/views/v2/Doctor/Tabs/AccountBalanceInfo.vue";
import HospitalTab from "@/views/v2/Doctor/Tabs/HospitalTab.vue";
import PatientList from "@/views/v2/Doctor/Tabs/PatientList.vue";
import SpeciemtReferal from "@/views/v2/Doctor/Tabs/SpeciemtReferal.vue";
import CustomSection from "@/components/v2/section/index.vue";
import DialogResult from "@/components/v3/results/index.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
// import axios from "axios";

export default {
  components: {
    // Tab,
    Card,
    HeadingCard,
    // SubHeadingCard,
    SelectShow,
    // Progressbar,
    // Datepicker,
    // DateRangePicker,
    Radio,
    Description,
    TabContent,
    // EducationTab,
    AccountTab,
    LicenseTab,
    TaxInfo,
    // AccountBalanceInfo,
    HospitalTab,
    PatientList,
    SpeciemtReferal,
    CustomSection,
    // SelectBulanBerjalan,
    DialogResult,
    NavbarOne,
    ApexSummaryChart,
    ApexReferralChart,
  },
  data: () => ({
    expanded: [],
    singleExpand: false,
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    dateNow: moment().format("DD MMMM YYYY, dddd"),
    monthNow: moment().format("MMMM YYYY"),
    dateString_today: null,
    dateString_thisWeek: null,
    dateString_thisMonth: null,
    dateString_lastMonth: null,
    dateString_last3Month: null,
    dateString_thisYear: null,
    dateString_lifetime: null,
    DateFilterItems: [],
    // dateFilterDefaultValue : { dateValue:'this_week', dateLabel:'This Week'},
    dateFilterDefaultValue: "this_month",
    selectedDateNow: "",
    selectedMonthNow: "",
    thisWeek: [],
    activeDoughnut: 1,
    percentDoughnut: 0,
    colorPercent: "#2ED47A",
    listPatient: null,
    listMenu: null,
    listContent: null,
    dataTaxInfo: null,
    dataChart: [],
    dataReferralChart: [],
    headers: [],
    datas: [
      {
        date: "10-JAN-2022",
        patient: 5,
        fee: 1000000,
      },
      {
        date: "10-FEB-2022",
        patient: 10,
        fee: 2000000,
      },
      {
        date: "10-MAR-2022",
        patient: 7,
        fee: 6400000,
      },
      {
        date: "10-APR-2022",
        patient: 14,
        fee: 2400000,
      },
    ],
    patientHeaders: [],
    patientDatas: [
      {
        order_no: null,
      },
    ],
    patientHeadersPopup: [],
    patientDatasPopup: [],
    referralMonthHeader: [],
    referralMonthData: [],
    activeTab: "",
    dialog: false,
    dialog_referral: false,
    dialog_order: false,
    dataDialogReferral: {
      selected_date: null,
      selected_datas: null,
    },
    dataDialogOrder: {
      order_id: null,
      list: [],
    },
    bulanSekarang: null,
    bulanBerjalan: [],
  }),
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  mounted() {
    this.getFilterDateStrings();
  },
  watch: {
    page: function () {
      this.getInitialRevenue();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getInitialRevenue();
      }
    },
  },
  created() {
    (this.patientHeadersPopup = [
      {
        text: this.$t("page.profile.order_no"),
        value: "order_no",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.number_of_medical_record"),
        value: "patient_id",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient_name",
        sortable: false,
        align: "center",
      },
      // { text: "Result", value: "result", sortable: false },
      // { text: "Status", value: "status", sortable: false },
      {
        text: this.$t("page.profile.referral_fee"),
        value: "referral",
        sortable: false,
        align: "center",
      },
    ]),
      (this.DateFilterItems = [
        // { dateValue: "today", dateLabel: this.$t("today") },
        // { dateValue: "this_week", dateLabel: this.$t("this_week") },
        { dateValue: "this_month", dateLabel: this.$t("this_month") },
        { dateValue: "last_month", dateLabel: this.$t("last_month") },
        { dateValue: "last_3_month", dateLabel: this.$t("last_3_month") },
        { dateValue: "this_year", dateLabel: this.$t("this_year") },
        { dateValue: "life_time", dateLabel: this.$t("lifetime") },
      ]);
    this.headers = [
      {
        text: this.$t("page.profile.date"),
        value: "date",
        sortable: false,
        align: "center",
        width: "130px",
      },
      {
        text: this.$t("page.profile.no_of_patients"),
        value: "patient",
        sortable: false,
        align: "center",
        width: "150px",
      },
      {
        text: this.$t("page.profile.referral_fee"),
        value: "fee",
        sortable: false,
        align: "center",
        width: "150px",
      },
    ];
    this.patientHeaders = [
      {
        text: this.$t("page.profile.order_date"),
        value: "order_date",
        sortable: false,
        align: "center",
        width: "150px",
      },
      {
        text: this.$t("page.profile.order_no"),
        value: "order_no",
        sortable: false,
        align: "center",
        width: "150px",
      },
      // { text: "Patient ID", value: "patient_id", sortable: false },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient_name",
        sortable: false,
        width: "250px",
        align: "center",
      },
      // { text: "Result", value: "result", sortable: false },
      {
        text: this.$t("page.profile.status"),
        value: "result",
        sortable: false,
        width: "150px",

        align: "center",
      },
      {
        text: this.$t("page.profile.referral_fee"),
        value: "referral",
        sortable: false,
        align: "center",
        width: "150px",
      },
    ];
    this.referralMonthHeader = [
      {
        text: this.$t("page.profile.date"),
        value: "date",
        sortable: false,
        align: "center",
        width: "130px",
      },
      {
        text: this.$t("page.profile.number_of_patients"),
        value: "number_of_patients",
        sortable: false,
        align: "center",
        width: "150px",
      },
      {
        text: this.$t("page.profile.referral_fee"),
        value: "referral_fee",
        sortable: false,
        align: "center",
        width: "150px",
      },
    ];
    this.getDateThisWeek();
    if (this.dataProfile) {
      this.getProfile();
    }
    this.getInitialSummaryChart();
    this.getInitialReferralChart();
    this.getInitialReferralMonth();
    this.getInitialRevenue();
    this.getBulanBerjalan();
  },

  methods: {
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    getFilterDateStrings() {
      /* eslint-disable no-unused-vars */
      this.dateString_today = this.dateNow;
      let stringWeek = "";
      let week = this.thisWeek;
      let first_week = week[0];
      let last_week = week[week.length - 1];
      if (moment(first_week).format("MM") == moment(last_week).format("MM")) {
        stringWeek = `${moment(first_week).format("DD")} - ${moment(
          last_week
        ).format("DD MMMM YYYY")}`;
      } else {
        stringWeek = `${moment(first_week).format("DD MMMM")} - ${moment(
          last_week
        ).format("DD MMMM YYYY")}`;
      }
      this.dateString_thisWeek = stringWeek;
      this.dateString_thisMonth = moment().format("MMMM YYYY");
      this.dateString_lastMonth = moment()
        .subtract(1, "months")
        .format("MMMM YYYY");
      this.dateString_last3Month = `${moment()
        .subtract(3, "months")
        .format("MMM YYYY")} - ${moment().format("MMM YYYY")} `;
      this.dateString_thisYear = `This Year (${moment().format("YYYY")})`;
      this.dateString_lifetime = "Lifetime";

      this.selectedDateNow = this.dateString_thisWeek; // init pertama kali
      this.selectedMonthNow = this.monthNow;

      /* eslint-enable no-unused-vars */
    },
    getBulanBerjalan() {
      const d = new Date();
      let month = d.getMonth() + 1;

      let month_list = [];
      for (var i = 1; i <= month; i++) {
        let month_name = moment.months(i - 1);
        let obj = {};
        obj["id"] = i;
        obj["name"] = month_name;
        month_list.push(obj);

        if (i == month) {
          this.bulanSekarang = { id: i, name: month_name };
        }
      }

      this.bulanBerjalan = month_list;
    },
    async openDialogOrder(item) {
      this.dataDialogOrder.order_number = item.order_no;
      // this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailPopup();
    },
    async openDialogReferral(referral) {
      this.dataDialogReferral.selected_date = referral;
      this.getInitialRevenuePopup();
      this.dialog_referral = true;
    },

    async loadDetails({ item }) {
      let start_date = "";
      if (
        this.dateFilterDefaultValue == "this_year" ||
        this.dateFilterDefaultValue == "life_time"
      ) {
        start_date = moment(item.date).format("YYYY-MM");
      } else {
        start_date = moment(item.date).format("YYYY-MM-DD");
      }

      let show_by = this.dateFilterDefaultValue;

      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-order-summary?show_by=` +
            show_by +
            `&group_by=date`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        var tempData = [];
        const filterNewData = res.results.filter((dataNew) => {
          return dataNew.date == start_date;
        });

        for (const item in filterNewData[0].detail) {
          let data_chart = filterNewData[0].detail[item];

          let format_chart = {
            order_no: data_chart.order_number,
            patient_id: data_chart.medical_record_number,
            patient_name: data_chart.patient_name,
            referral:
              data_chart.referral_fee !== null ? data_chart.referral_fee : 0,
          };

          tempData.push(format_chart);
        }

        item.details = tempData;
      } catch (error) {
        console.log(error);
      }
    },

    // async getInitialRevenue() {
    //   let show_by = this.dateFilterDefaultValue;
    //   try {
    //     const res = await API.get(
    //       `${process.env.VUE_APP_API_TRUST}referral/get-order-summary?show_by=` +
    //         show_by +
    //         `&group_by=date`,
    //       {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Auth.getToken()}`,
    //       }
    //     );

    //     let dataSet = [];
    //     var tempData = [];
    //     for (const item in res.results) {
    //       let data_chart = res.results[item].detail;

    //       if (data_chart.length > 0) {
    //         dataSet = [...dataSet, ...data_chart];
    //       }
    //     }
    //     for (const dataNew in dataSet) {
    //       //logic untuk dapetin statusnya
    //       let status_invoice = dataSet[dataNew].invoice_status;
    //       let status_approved = dataSet[dataNew].approved;
    //       let overal_status = "";

    //       if (status_invoice == "paid" && status_approved == false) {
    //         overal_status = "warning";
    //       }
    //       if (status_invoice !== "paid" && status_approved == false) {
    //         overal_status = "danger";
    //       }
    //       if (status_invoice == "paid" && status_approved == true) {
    //         overal_status = "ok";
    //       }

    //       let status_parse = "-";

    //       let format_chart = {
    //         order_no: dataSet[dataNew].order_number,
    //         patient_id: dataSet[dataNew].patient_id_number,
    //         patient_name: dataSet[dataNew].patient,
    //         result: overal_status,
    //         status: status_parse,
    //         referral:
    //           dataSet[dataNew].amount !== null ? dataSet[dataNew].amount : 0,
    //         order_id: dataSet[dataNew].order_id,
    //         order_date: moment(dataSet[dataNew].invoice_date)
    //           .format("DD-MMM-YYYY")
    //           .toUpperCase(),
    //       };

    //       tempData.push(format_chart);
    //     }

    //     this.patientDatas = tempData;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatYear(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("MMM-YYYY")
        .toUpperCase();
    },
    async getInitialRevenue() {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-revenue?offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        let dataSet = [];
        var tempData = [];
        // for (const item in res.results) {

        const dateNowNew = new Date();
        const setDateNowNew = `${dateNowNew.getFullYear()}-${
          dateNowNew.getMonth() + 1
        }-${dateNowNew.getDate()}`;

        const todayFilter = res.results.filter((setDataToday) => {
          const newDateFilterData = new Date(setDataToday.invoice_date);
          const setDateFormat = `${newDateFilterData.getFullYear()}-${
            newDateFilterData.getMonth() + 1
          }-${newDateFilterData.getDate()}`;

          return setDateNowNew == setDateFormat;
        });

        let data_chart = todayFilter;

        if (data_chart.length > 0) {
          dataSet = [...dataSet, ...data_chart];
        }
        // }

        for (const dataNew in dataSet) {
          //logic untuk dapetin statusnya
          // let status_invoice = dataSet[dataNew].fee_status;
          // let status_approved = dataSet[dataNew].approved;
          // let overal_status = "";

          // if (status_invoice == "paid" && status_approved == false) {
          //   overal_status = "warning";
          // }
          // if (status_invoice !== "paid" && status_approved == false) {
          //   overal_status = "danger";
          // }
          // if (status_invoice == "paid" && status_approved == true) {
          //   overal_status = "ok";
          // }

          let status_parse = "-";

          let format_chart = {
            order_no: dataSet[dataNew].order_number,
            patient_id: dataSet[dataNew].patient_id_number,
            patient_name: dataSet[dataNew].patient,
            result: dataSet[dataNew].status_name,
            status: status_parse,
            referral:
              dataSet[dataNew].amount !== null ? dataSet[dataNew].amount : 0,
            order_id: dataSet[dataNew].order_id,
            order_date: moment(dataSet[dataNew].invoice_date)
              .format("DD-MMM-YYYY")
              .toUpperCase(),
          };

          tempData.push(format_chart);
        }

        this.patientDatas = tempData;
        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getInitialRevenuePopup() {
      let start_date = moment(this.dataDialogReferral.selected_date).format(
        "YYYY-MM-DD"
      );
      let show_by = this.dateFilterDefaultValue;

      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-order-summary?show_by=` +
            show_by +
            `&group_by=date`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        // console.log(res);

        var tempData = [];
        const filterNewData = res.results.filter((dataNew) => {
          return dataNew.date == start_date;
        });

        for (const item in filterNewData[0].detail) {
          let data_chart = filterNewData[0].detail[item];
          // //logic untuk dapetin statusnya
          // let status_invoice = data_chart.invoice_status;
          // let status_approved = data_chart.approved;
          // let overal_status = "";

          // if (status_invoice == "paid" && status_approved == false) {
          //   overal_status = "warning";
          // }
          // if (status_invoice !== "paid" && status_approved == false) {
          //   overal_status = "danger";
          // }
          // if (status_invoice == "paid" && status_approved == true) {
          //   overal_status = "ok";
          // }

          // let status_parse = "-";

          let format_chart = {
            order_no: data_chart.order_number,
            patient_id: data_chart.medical_record_number,
            patient_name: data_chart.patient_name,
            referral:
              data_chart.referral_fee !== null ? data_chart.referral_fee : 0,
          };

          tempData.push(format_chart);
        }

        this.patientDatasPopup = tempData;
      } catch (error) {
        console.log(error);
      }
    },
    async getInitialSummaryChart() {
      let show_by = this.dateFilterDefaultValue;
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-order-summary?show_by=` +
            show_by +
            `&group_by=date`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        var tempData = [];
        for (const item in resp.results) {
          let data_chart = resp.results[item];
          let format_chart = {
            ColorFlag: 0,
            MaximumValue: 200,
            MinimumValue: data_chart.number_of_patients,
            Result: data_chart.number_of_patients,
            Test: "",
            TestDate: data_chart.date,
            medical_record_number: "",
            name: "",
          };
          tempData.push(format_chart);
        }

        this.dataChart = tempData;
      } catch (error) {
        console.log(error);
      }
    },
    async getInitialReferralChart() {
      let show_by = this.dateFilterDefaultValue;
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-order-summary?show_by=` +
            show_by +
            `&group_by=date`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        // this.dataChart = {}
        var tempData = [];
        for (const item in resp.results) {
          let data_chart = resp.results[item];
          let format_chart = {
            ColorFlag: 0,
            MaximumValue: 200,
            MinimumValue: data_chart.number_of_patients,
            Result: data_chart.referral_fee,
            Test: "",
            TestDate: data_chart.date,
            medical_record_number: "",
            name: "",
          };
          tempData.push(format_chart);
        }

        this.dataReferralChart = tempData;
      } catch (error) {
        console.log(error);
      }
    },
    async getInitialReferralMonth() {
      let show_by = this.dateFilterDefaultValue;
      try {
        var url = "";
        url =
          `${process.env.VUE_APP_API_TRUST}referral/get-order-summary?show_by=` +
          show_by +
          `&group_by=date`;

        const resp = await API.get(url, {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        });

        // var tempData1 = [];
        // for (const item in resp) {
        //   let data_chart = item;
        //   let date_formatted = new Date(data_chart.BUDAT);
        //   let format_chart = {
        //     date: date_formatted,
        //     patient: 'data_chart.number_of_patients',
        //     fee: data_chart.referral_fee,
        //   };
        //   tempData1.push(format_chart);
        // }

        //  for (const item in resp) {
        //   let data_chart = item;
        //   let date_formatted = new Date(data_chart.date);
        //   let format_chart = {
        //     date: date_formatted,
        //     patient: data_chart.number_of_patients,
        //     fee: data_chart.referral_fee,
        //   };
        //   tempData1.push(format_chart);
        // }

        // tempData1 = tempData1.sort(function (a, b) {
        //   var dateA = new Date(a.date).getTime();
        //   var dateB = new Date(b.date).getTime();
        //   return dateA < dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
        // });

        // var tempData = [];
        // for (const item in tempData1) {
        //   let data_chart = tempData1[item];
        //   let format_chart = {
        //     date: moment(data_chart.date).format("DD-MMM-YYYY").toUpperCase(),
        //     patient: data_chart.patient,
        //     fee: data_chart.fee,
        //   };
        //   tempData.push(format_chart);
        // }

        // if(resp == ''){

        // }
        if (
          this.dateFilterDefaultValue == "life_time" ||
          this.dateFilterDefaultValue == "this_year"
        ) {
          this.referralMonthData = resp.results.map((item) => {
            item.date = this.formatYear(item.date);
            item.total_refferal_fee = item.referral_fee;
            item.referral_fee = this.formater(item.referral_fee);
            return {
              details: {},
              ...item,
            };
          });
        } else {
          this.referralMonthData = resp.results.map((item) => {
            item.date = this.formatDate(item.date);
            item.total_refferal_fee = item.referral_fee;
            item.referral_fee = this.formater(item.referral_fee);
            return {
              details: {},
              ...item,
            };
          });
        }
      } catch (error) {
        this.referralMonthData = [];
        // console.log(error);
      }
    },

    async getProfile() {
      try {
        this.listMenu = [
          {
            id: 1,
            title: this.$t("tab.summary"),
          },
          {
            id: 2,
            title: this.$t("tab.personal_info"),
          },
          {
            id: 3,
            title: this.$t("tab.account_info"),
          },
          {
            id: 4,
            title: this.$t("tab.patient_list"),
          },
          // {
          //   id: 5,
          //   title: this.$t("tab.speciment_referral"),
          // },
        ];
        this.listContent = [
          {
            id: 1,
            title: this.$t("tab.summary"),
            data: [
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.gender.name == "MALE"
                    ? "Mr. " + this.dataProfile.person.personal_info.name
                    : "Mrs. " + this.dataProfile.person.personal_info.name,
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.address.toUpperCase(),
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.city +
                      ", " +
                      this.dataProfile.person.address.district,
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.state +
                      " " +
                      this.dataProfile.person.personal_info.postal_code +
                      ", " +
                      this.dataProfile.person.personal_info.country,
              },
              {
                valueTitle: "EMAIL",
                valueTitleLang: this.$t("field.email"),
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.email == null
                    ? "-"
                    : this.dataProfile.person.contacts.email,
              },
              {
                valueTitle: "MOBILE PHONE",
                valueTitleLang: this.$t("field.mobile_phone"),
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.mobile_phone_number ==
                      null
                    ? "-"
                    : this.dataProfile.person.contacts.mobile_phone_number,
              },
              {
                valueTitle: "HOME PHONE",
                valueTitleLang: this.$t("field.home_phone"),
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.home_phone_number == null
                    ? "-"
                    : this.dataProfile.person.contacts.home_phone_number,
              },
            ],
            dataRight: [
              {
                title: this.$t("field.sap_bp_id"),
                value:
                  this.dataProfile.sap_code == null ||
                  this.dataProfile.sap_code == "null"
                    ? "-"
                    : this.dataProfile.sap_code,
              },

              {
                title: this.$t("field.id_card"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.registered_ids[0].description,
              },

              {
                title: this.$t("field.gender"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.gender.name,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status ==
                        "null" ||
                      this.dataProfile.person.personal_info.marital_status ==
                        null
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status,
              },
              {
                title: this.$t("field.religion"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.religion ==
                        "null" ||
                      this.dataProfile.person.personal_info.religion == null
                    ? "-"
                    : this.dataProfile.person.personal_info.religion,
              },
              {
                title: this.$t("field.currency"),
                value:
                  this.dataProfile.currency == null ||
                  this.dataProfile.currency == "null"
                    ? "-"
                    : this.dataProfile.currency,
              },
            ],
            dataMobile: [
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.gender.name == "MALE"
                    ? "Mr. " + this.dataProfile.person.personal_info.name
                    : "Mrs. " + this.dataProfile.person.personal_info.name,
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.address.toUpperCase(),
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.city +
                      ", " +
                      this.dataProfile.person.address.district,
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.state +
                      " " +
                      this.dataProfile.person.personal_info.postal_code +
                      ", " +
                      this.dataProfile.person.personal_info.country,
              },
            ],
            dataMobileRight: [
              {
                title: this.$t("field.email"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.email,
              },
              {
                title: this.$t("field.mobile_phone"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.mobile_phone_number,
              },
              {
                title: this.$t("field.home_phone"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.home_phone_number ==
                        "null" ||
                      this.dataProfile.person.contacts.home_phone_number == null
                    ? "-"
                    : this.dataProfile.person.contacts.home_phone_number,
              },

              {
                title: this.$t("field.id_card"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.registered_ids[0].description,
              },
              {
                title: this.$t("field.sap_bp_id"),
                value:
                  this.dataProfile.sap_code == null ||
                  this.dataProfile.sap_code == "null"
                    ? "-"
                    : this.dataProfile.sap_code,
              },
              {
                title: this.$t("field.gender"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.gender.name,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status ==
                        "null" ||
                      this.dataProfile.person.personal_info.marital_status ==
                        null
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status,
              },
              {
                title: this.$t("field.religion"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.religion ==
                        "null" ||
                      this.dataProfile.person.personal_info.religion == null
                    ? "-"
                    : this.dataProfile.person.personal_info.religion,
              },
              {
                title: this.$t("field.currency"),
                value:
                  this.dataProfile.currency == null ||
                  this.dataProfile.currency == "null"
                    ? "-"
                    : this.dataProfile.currency,
              },
              // {
              //   title: this.$t("field.account_number"),
              //   value: this.dataProfile.person.id,
              // },
              // {
              //   title: this.$t("field.sap_bp_id"),
              //   value: this.dataProfile.sap_code,
              // },
              // {
              //   title: this.$t("field.national_id_card"),
              //   value: this.dataProfile.person.registered_ids[1].description,
              // },
            ],
          },
          {
            id: 2,
            title: this.$t("tab.personal_info"),
            data: [
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.gender.name == "MALE"
                    ? "Mr. " + this.dataProfile.person.personal_info.name
                    : "Mrs. " + this.dataProfile.person.personal_info.name,
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.address.toUpperCase(),
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.city +
                      ", " +
                      this.dataProfile.person.address.district,
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.state +
                      " " +
                      this.dataProfile.person.personal_info.postal_code +
                      ", " +
                      this.dataProfile.person.personal_info.country,
              },
              {
                valueTitle: "EMAIL",
                valueTitleLang: this.$t("field.email"),
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.email == null
                    ? "-"
                    : this.dataProfile.person.contacts.email,
              },
              {
                valueTitle: "MOBILE PHONE",
                valueTitleLang: this.$t("field.mobile_phone"),
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.mobile_phone_number ==
                      null
                    ? "-"
                    : this.dataProfile.person.contacts.mobile_phone_number,
              },
              {
                valueTitle: "HOME PHONE",
                valueTitleLang: this.$t("field.home_phone"),
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.home_phone_number == null
                    ? "-"
                    : this.dataProfile.person.contacts.home_phone_number,
              },
            ],
            dataRight: [
              {
                title: this.$t("field.sap_bp_id"),
                value:
                  this.dataProfile.sap_code == null ||
                  this.dataProfile.sap_code == "null"
                    ? "-"
                    : this.dataProfile.sap_code,
              },
              {
                title: this.$t("field.id_card"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.registered_ids[0].description,
              },

              {
                title: this.$t("field.gender"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.gender.name,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status ==
                        "null" ||
                      this.dataProfile.person.personal_info.marital_status ==
                        null
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status,
              },
              {
                title: this.$t("field.religion"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.religion ==
                        "null" ||
                      this.dataProfile.person.personal_info.religion == null
                    ? "-"
                    : this.dataProfile.person.personal_info.religion,
              },
              {
                title: this.$t("field.currency"),
                value:
                  this.dataProfile.currency == null ||
                  this.dataProfile.currency == "null"
                    ? "-"
                    : this.dataProfile.currency,
              },
            ],
            dataMobile: [
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.gender.name == "MALE"
                    ? "Mr. " + this.dataProfile.person.personal_info.name
                    : "Mrs. " + this.dataProfile.person.personal_info.name,
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.address.toUpperCase(),
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.city +
                      ", " +
                      this.dataProfile.person.address.district,
              },
              {
                valueLeft:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.state +
                      " " +
                      this.dataProfile.person.personal_info.postal_code +
                      ", " +
                      this.dataProfile.person.personal_info.country,
              },
            ],
            dataMobileRight: [
              {
                title: this.$t("field.email"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.email,
              },
              {
                title: this.$t("field.mobile_phone"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.mobile_phone_number,
              },
              {
                title: this.$t("field.home_phone"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.contacts.home_phone_number ==
                        "null" ||
                      this.dataProfile.person.contacts.home_phone_number == null
                    ? "-"
                    : this.dataProfile.person.contacts.home_phone_number,
              },

              {
                title: this.$t("field.id_card"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.registered_ids[0].description,
              },
              {
                title: this.$t("field.sap_bp_id"),
                value:
                  this.dataProfile.sap_code == null ||
                  this.dataProfile.sap_code == "null"
                    ? "-"
                    : this.dataProfile.sap_code,
              },
              {
                title: this.$t("field.gender"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.gender.name,
              },
              {
                title: this.$t("field.marital_status"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status ==
                        "null" ||
                      this.dataProfile.person.personal_info.marital_status ==
                        null
                    ? "-"
                    : this.dataProfile.person.personal_info.marital_status,
              },
              {
                title: this.$t("field.religion"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.personal_info.religion ==
                        "null" ||
                      this.dataProfile.person.personal_info.religion == null
                    ? "-"
                    : this.dataProfile.person.personal_info.religion,
              },
              {
                title: this.$t("field.currency"),
                value:
                  this.dataProfile.currency == null ||
                  this.dataProfile.currency == "null"
                    ? "-"
                    : this.dataProfile.currency,
              },
              // {
              //   title: this.$t("field.account_number"),
              //   value: this.dataProfile.person.id,
              // },
              // {
              //   title: this.$t("field.sap_bp_id"),
              //   value: this.dataProfile.sap_code,
              // },
              // {
              //   title: this.$t("field.national_id_card"),
              //   value: this.dataProfile.person.registered_ids[1].description,
              // },
            ],
          },
          //section hospital
          {
            // id: 2,
            // title: this.$t("personal_information"),
            // data: [
            //   {
            //     title: this.$t("hospital_id"),
            //     value: this.dataProfile.person.hospital_info.hospital_id,
            //   },
            //   {
            //     title: this.$t("state"),
            //     value: this.dataProfile.person.hospital_info.hospital_state,
            //   },
            //   {
            //     title: this.$t("hospital_name"),
            //     value: this.dataProfile.person.hospital_info.hospital_name,
            //   },
            //   {
            //     title: this.$t("district"),
            //     value: this.dataProfile.person.hospital_info.hospital_district == 'null' || this.dataProfile.person.hospital_info.hospital_district == null  ? '-' : this.dataProfile.person.hospital_info.hospital_district,
            //   },
            //   {
            //     title: this.$t("address"),
            //     value: this.dataProfile.person.hospital_info.hospital_address,
            //   },
            //   {
            //     title: this.$t("postal_code"),
            //     value: this.dataProfile.person.hospital_info.hospital_postal_code,
            //   },
            //   {
            //     title: this.$t("city"),
            //     value: this.dataProfile.person.hospital_info.hospital_city,
            //   },
            //   {
            //     title: this.$t("country"),
            //     value: this.dataProfile.person.hospital_info.hospital_country,
            //   },
            // ],
          },
          //section education info
          {
            id: 3,
            title: this.$t("tab.personal_info"),
            data: [
              {
                title: "Hospital Id",
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.hospital_info == "null" ||
                      this.dataProfile.person.hospital_info == null
                    ? "-"
                    : this.dataProfile.person.hospital_info.hospital_id,
              },
              {
                title: this.$t("field.state"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.hospital_info == "null" ||
                      this.dataProfile.person.hospital_info == null
                    ? "-"
                    : this.dataProfile.person.hospital_info.hospital_state,
              },
              {
                title: "hospital name",
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.hospital_info == "null" ||
                      this.dataProfile.person.hospital_info == null
                    ? "-"
                    : this.dataProfile.person.hospital_info.hospital_name,
              },
              {
                title: this.$t("field.district"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.hospital_info == "null" ||
                      this.dataProfile.person.hospital_info == null
                    ? "-"
                    : this.dataProfile.person.hospital_info.hospital_district,
              },
              {
                title: this.$t("field.address"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.hospital_info == "null" ||
                      this.dataProfile.person.hospital_info == null
                    ? "-"
                    : this.dataProfile.person.hospital_info.hospital_address,
              },
              {
                title: this.$t("field.postal_code"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.hospital_info == "null" ||
                      this.dataProfile.person.hospital_info == null
                    ? "-"
                    : this.dataProfile.person.hospital_info.postal_code,
              },
              {
                title: this.$t("field.city"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.hospital_info == "null" ||
                      this.dataProfile.person.hospital_info == null
                    ? "-"
                    : this.dataProfile.person.hospital_info.hospital_city,
              },
              {
                title: this.$t("field.country"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.hospital_info == "null" ||
                      this.dataProfile.person.hospital_info == null
                    ? "-"
                    : this.dataProfile.person.hospital_info.hospital_countrys,
              },
            ],
          },
          {
            id: 4,
            title: this.$t("page.profile.tax_info"),
            data: [
              {
                title: this.$t("page.profile.npwp_number"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.tax == "null" ||
                      this.dataProfile.person.tax == null
                    ? "-"
                    : this.dataProfile.person.tax.npwp_number,
              },
              {
                title: this.$t("page.profile.npwp_registration_date"),
                value: "-",
              },
              {
                title: this.$t("page.profile.npwp_address"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.tax == "null" ||
                      this.dataProfile.person.tax == null
                    ? "-"
                    : this.dataProfile.person.tax.npwp_address,
              },
            ],
          },
          {
            id: 5,
            title: this.$t("page.profile.bank_info"),
            data: [
              {
                title: this.$t("page.profile.bank_name"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.bank_account == "null" ||
                      this.dataProfile.person.bank_account == null
                    ? "-"
                    : this.dataProfile.person.bank_account.bank_name,
              },
              {
                title: this.$t("page.profile.bank_branch"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.bank_account == "null" ||
                      this.dataProfile.person.bank_account == null
                    ? "-"
                    : this.dataProfile.person.bank_account.bank_branch,
              },
              {
                title: this.$t("page.profile.bank_account_number"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.bank_account == "null" ||
                      this.dataProfile.person.bank_account == null
                    ? "-"
                    : this.dataProfile.person.bank_account.bank_account_number,
              },
              {
                title: this.$t("page.profile.bank_account_name"),
                value:
                  this.dataProfile.person == undefined
                    ? "-"
                    : this.dataProfile.person.bank_account == "null" ||
                      this.dataProfile.person.bank_account == null
                    ? "-"
                    : this.dataProfile.person.bank_account.bank_account_name,
              },
            ],
          },
        ];
        this.dataTaxInfo =
          this.dataProfile.person == undefined
            ? null
            : this.dataProfile.person.tax_info;
      } catch (error) {
        console.log(error);
      }

      if (this.listContent != null) {
        this.activeTab = this.listContent[0].id;
      }
    },
    async getOrderDetailPopup() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${this.dataDialogOrder.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          this.dataDialogOrder.results = res.results;

          this.dialog_order = true;
        } else if (res.statusCode == 400) {
          this.dataDialogOrder.list = [];
          (this.dialog_order = false),
            this.$swal({
              icon: "error",
              text: res.message,
            });
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    calculateDoughnut() {
      var totalDoughnut = 0;
      this.dataDoughnut.forEach((element) => {
        totalDoughnut = element.Result + totalDoughnut;
      });
      const activeDoughnutIndex = this.dataDoughnut.findIndex(
        (item) => item.id == this.activeDoughnut
      );
      if (activeDoughnutIndex >= 0) {
        this.percentDoughnut = parseInt(
          (this.dataDoughnut[activeDoughnutIndex].Result / totalDoughnut) * 100
        );
      }
    },
    setActiveDoughnut(param) {
      if (param == 1) {
        this.colorPercent = "#2ED47A";
      } else if (param == 2) {
        this.colorPercent = "#FFB946";
      } else if (param == 3) {
        this.colorPercent = "#F7685B";
      } else {
        this.colorPercent = "#0000DE";
      }
      this.activeDoughnut = param;
      this.calculateDoughnut();
    },
    getDateThisWeek() {
      const date = moment();
      const startThisWeek = moment(date).startOf("isoWeek");
      const endThisWeek = moment(date).endOf("isoWeek");
      this.thisWeek = this.getListDateRange(startThisWeek, endThisWeek);
    },
    getListDateRange(startDate, endDate) {
      var start = new Date(startDate);
      var end = new Date(endDate);
      var dates = [];

      while (start <= end) {
        dates.push(new Date(start));
        start.setDate(start.getDate() + 1);
      }
      return dates;
    },
    sumField(key) {
      // sum data in give key (property)
      return this.datas.reduce((a, b) => a + (b[key] || 0), 0);
    },
    sumReferral() {
      // sum data in give key (property)
      let sum = 0;

      for (let i = 0; i < this.patientDatas.length; i++) {
        // if (this.patientDatas[i].status == "Active" ||  this.patientDatas[i].status == "Completed") {
        //   sum = sum + parseInt(this.patientDatas[i].referral);
        // }

        // if (
        //   this.patientDatas[i].result == "ok" ||
        //   this.patientDatas[i].result == "warning"
        // ) {
        sum = sum + parseInt(this.patientDatas[i].referral);
        // }
      }
      return sum;
    },
    sumReferralPopup() {
      let sum = 0;
      for (let i = 0; i < this.patientDatasPopup.length; i++) {
        // if (
        //   this.patientDatasPopup[i].result == "ok" ||
        //   this.patientDatasPopup[i].result == "warning"
        // ) {

        sum = sum + parseInt(this.patientDatasPopup[i].referral);
        // }
      }
      return sum;
    },
    sumReferralMonth(key) {
      // sum data in give key (property)
      return this.referralMonthData.reduce(
        (a, b) => a + (parseFloat(b[key]) || 0),
        0
      );
    },
    updateSummaryChart(e) {
      // this.dateFilterDefaultValue.dateValue = e;
      this.dateFilterDefaultValue = e;
      this.getInitialSummaryChart();
    },
    updateReferralChart(e) {
      this.dateFilterDefaultValue = e;
      this.getInitialReferralChart();
    },
    updateRevenue(e) {
      this.dateFilterDefaultValue = e;
      this.getInitialRevenue();
    },
    updateDailyReferralMonth(e) {
      // let current_year = moment().format("YYYY");
      // let month = e;
      // let string_first_date = `${current_year}-${month}-1`;
      // let first_date = new Date(string_first_date);
      // let first_date_format = moment(first_date).format("YYYYMMDD");
      // let last_date_format = moment(first_date)
      //   .endOf("month")
      //   .format("YYYYMMDD");

      // this.selectedMonthNow = moment(first_date).format("MMMM YYYY");
      // this.getInitialReferralMonth(first_date_format, last_date_format);
      this.dateFilterDefaultValue = e;
      this.getInitialReferralMonth();
    },
    updateAllReport(e) {
      this.dateFilterDefaultValue = e;
      this.getInitialRevenue();
      this.getInitialReferralChart();
      this.getInitialSummaryChart();
      this.getInitialReferralMonth();
      switch (e) {
        case "today":
          this.selectedDateNow = this.dateString_today;
          break;
        case "this_week":
          this.selectedDateNow = this.dateString_thisWeek;
          break;
        case "this_month":
          this.selectedDateNow = this.dateString_thisMonth;
          break;
        case "last_month":
          this.selectedDateNow = this.dateString_lastMonth;
          break;
        case "last_3_month":
          this.selectedDateNow = this.dateString_last3Month;
          break;
        case "this_year":
          this.selectedDateNow = this.dateString_thisYear;
          break;
        case "life_time":
          this.selectedDateNow = this.dateString_lifetime;
          break;
        default:
          this.selectedDateNow = this.dateString_thisWeek;
          break;
      }
    },
    formater(value) {
      let angka = parseInt(value) || 0;
      let nilai = angka.toLocaleString("en-US", { minimumFractionDigits: 2 });
      return nilai;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
.center-empty {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-switch {
  color: $main_2;
  margin-right: 8px;
}

.h4 {
  color: $main_2;
}
.list-data {
  border-bottom: 1px solid $gray_2;
  // padding: 4px 8px 0 8px;
  padding: 12px 8px 0 8px;
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.card-chart {
  position: relative;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}

.v-tabs {
  width: 720px;
}

.center-chart {
  background: transparent;
  height: 80%;
  width: 80%;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 72px;
    margin-bottom: 0;
  }
}
.label-doughnut {
  align-self: center;
}
.doughnut-title {
  padding: 4px;
}
.chart-title {
  padding: 4px 12px;
}

.v-input__control {
  height: 30px;
}
.v-input__slot {
  height: 30px;
}

.v-select__selections {
  width: 30px;
  height: 30px;
}

.v-select {
  height: 30px;
  background: #ffb946;
  color: #000;
}

.sticky-table-footer {
  td {
    font-weight: bold;
  }
}

.card-chart .v-data-table {
  td {
    font-size: 12px;
  }
}

.table-patient {
  th {
    .text-gray {
      color: $main_2;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
  td {
    .text-gray {
      color: $gray_table;
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .text-black {
      color: $black_table;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}

.grey-color {
  background: #eeeeee;
}

.head-vir {
  background: #1a428a;
  color: #fff;
}

.head-vir2 {
  background: #6dc6d6;
  font-weight: bold;
}

.head-vir2:hover {
  background: #6dc6d6 !important;
}

.head-vir2 th {
  color: #000 !important;
}

.bold {
  font-weight: bold;
}

.hover-apri:hover {
  background: rgba(113, 188, 225, 0.1) !important;
}
.hover-apri:hover td {
  font-weight: bold;
}
.text-title {
  font-size: 12px;
  text-transform: uppercase;
  color: $main_2;
  font-weight: bold;
}
.text-new-title {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}
.text-value {
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  text-align: right;
}
.theme--light.v-data-table .footerHide {
  border: unset !important;
}
</style>
<style>
.table-expand-new .v-data-table__wrapper {
  height: 100% !important;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 407px;
}
.table-stick thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.select-show {
  z-index: 60 !important;
}
.table-stick .v-data-table-header {
  z-index: 50;
}
.table-stick-sap .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px;
}
.table-stick-sap thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 99 !important;
}

.table-list-patient thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-list-patient thead tr th {
  height: 38px !important;
}
.table-list-patient tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
}
.table-list-patient tbody tr:hover {
  background-color: transparent !important;
}
</style>
<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.pageStyle > .theme--light.v-pagination .v-pagination__navigation {
  height: 25px;
  font-size: 12px;
  min-width: 25px;
  width: 25px;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
