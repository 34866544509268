var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"700px","persistent":""},model:{value:(_vm.is_show),callback:function ($$v) {_vm.is_show=$$v},expression:"is_show"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('div',{staticClass:"tab-section-test"},[_c('div',{staticClass:"tab-section-test__item",class:{ active: _vm.activeData.type == 'test' },on:{"click":function($event){_vm.activeData.type = _vm.cantChangeService ? _vm.activeData.type : 'test'}}},[_vm._v(" Order for Test ")]),_c('div',{staticClass:"tab-section-test__item",class:{ active: _vm.activeData.type == 'package' },on:{"click":function($event){_vm.activeData.type = _vm.cantChangeService
                ? _vm.activeData.type
                : 'package'}}},[_vm._v(" Order for Package ")])]),_c('div',{staticClass:"pa-5 pb-0"},[_c('v-autocomplete',{attrs:{"hide-details":"","items":_vm.familyList,"item-text":"related_person_name","item-value":"patient_id","label":`${_vm.$t('select')} ${_vm.$t('patient')}`,"rules":[
              v =>
                !!v ||
                `${_vm.$t('page.office_care.family_member')} ${_vm.$t(
                  'is_required'
                )}`,
            ],"outlined":"","dense":""},model:{value:(_vm.activeData.memberFamily),callback:function ($$v) {_vm.$set(_vm.activeData, "memberFamily", $$v)},expression:"activeData.memberFamily"}}),(_vm.step == 2)?_c('card-test',{staticClass:"mt-4",attrs:{"active":_vm.checkItem(_vm.itemsSelected[0].id),"disable-title-link":"","plant":_vm.filters.plant_id,"data":_vm.itemsSelected[0],"title":_vm.itemsSelected[0].name,"sub_title":`${
              _vm.itemsSelected[0].prices != undefined
                ? _vm.itemsSelected[0].prices.length !== 0
                  ? `IDR ${_vm.formater(_vm.itemsSelected[0].prices[0].amount)}.00`
                  : '0'
                : '0'
            }`,"desc":_vm.itemsSelected[0][`description_${_vm.$i18n.locale}`]}}):_vm._e(),(_vm.step == 2)?_c('h5',{staticClass:"mt-4"},[_vm._v("Add ons")]):_vm._e()],1),_c('v-card-text',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"60vh"}},[_c('div',{staticStyle:{"min-height":"500px"}},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('icon-loading',{staticClass:"icon-loading"})],1):_vm._e(),(_vm.step == 1)?_c('v-row',{staticClass:"mb-16"},_vm._l((_vm.listTest),function(test,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6"}},[_c('card-test',{attrs:{"active":_vm.checkItem(test.id),"action":"","all-gender":"","disable-title-link":"","plant":_vm.filters.plant_id,"data":test,"title":_vm.activeData.type == 'test'
                      ? test[
                          _vm.$i18n.locale == 'id'
                            ? `name`
                            : `name_${_vm.$i18n.locale}`
                        ]
                      : test.name,"sub_title":`${
                    test.prices != undefined
                      ? test.prices.length !== 0
                        ? `IDR ${_vm.formater(test.prices[0].amount)}.00`
                        : '0'
                      : '0'
                  }`,"desc":test[`description_${_vm.$i18n.locale}`]},on:{"addCart":item => _vm.addCart(item),"removeCart":item => _vm.removeCart(item)}})],1)}),1):_c('v-row',_vm._l((_vm.listAddon),function(test,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"6"}},[_c('card-test',{attrs:{"active":_vm.checkItem(test.id),"action":"","all-gender":"","disable-title-link":"","plant":_vm.filters.plant_id,"data":test,"title":test[
                      _vm.$i18n.locale == 'id'
                        ? `product_name`
                        : `product_name_${_vm.$i18n.locale}`
                    ],"sub_title":`${`IDR ${_vm.formater(test.amount)}.00`}`},on:{"addCart":item => _vm.addCart(item),"removeCart":item => _vm.removeCart(item)}})],1)}),1)],1)]),_c('div',{staticClass:"summary-action pa-4"},[_c('div',{staticClass:"d-flex cart"},[_c('v-badge',{staticClass:"badge mr-4",attrs:{"color":"pink","content":_vm.itemsSelected.length,"value":_vm.itemsSelected.length}},[_c('IconCart',{attrs:{"color":"#00A4B3"}})],1),_c('h4',[_vm._v(_vm._s(`IDR ${this.formater(_vm.calculateItemsSelected)}.00`))])],1),_c('div',[(_vm.step == 1)?_c('v-btn',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.cancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]):_vm._e(),(_vm.step == 2)?_c('v-btn',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();_vm.step = 1}}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]):_vm._e(),(
                _vm.itemsSelected.length == 0 ||
                _vm.step == 2 ||
                (_vm.step == 1 && _vm.activeData.type != 'package') ||
                (_vm.activeData.type == 'package' &&
                  _vm.itemsSelected.length > 0 &&
                  _vm.itemsSelected[0].is_set_addons == false)
              )?_c('v-btn',{staticClass:"bg-blue",attrs:{"small":"","disabled":!_vm.valid || _vm.itemsSelected.length == 0},on:{"click":function($event){$event.stopPropagation();return _vm.save.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]):_vm._e(),(
                _vm.step == 1 &&
                _vm.activeData.type == 'package' &&
                _vm.itemsSelected.length > 0 &&
                _vm.itemsSelected[0].is_set_addons
              )?_c('v-btn',{staticClass:"bg-blue",attrs:{"small":"","disabled":!_vm.valid || _vm.itemsSelected.length == 0},on:{"click":function($event){$event.stopPropagation();return _vm.next.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("next"))+" ")]):_vm._e()],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }