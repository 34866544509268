<template>
  <v-card v-if="contentImg" class="card card-test">
    <div class="card__body px-3 pa-2">
      <icons icon="simple-profile" />
    </div>
    <div class="card__body px-3 pa-2 mt-5">
      <localized-link :to="`/patient/test/${data.id}?branch=${plant}`">
        <h5 class="card__title">{{ title }}</h5>
      </localized-link>
      <h6 class="card__sub-title" v-if="action">{{ sub_title }}</h6>
    </div>
    <template v-if="action && checkAvailableGender">
      <div
        class="card__action card__action--remove pa-3"
        v-if="active"
        @click="removeCart(data)"
      >
        <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
      </div>
      <div
        class="card__action card__action--add pa-3"
        v-else
        @click="addCart(data)"
      >
        <v-icon color="#e1e8f5"> mdi-cart-plus </v-icon>
      </div>
    </template>
  </v-card>

  <v-card v-else class="card card-test">
    <div class="card__body px-3 pa-2">
      <h5 class="card__title" v-if="disableTitleLink">{{ title }}</h5>
      <localized-link :to="`/patient/test/${data.id}?branch=${plant}`" v-else>
        <h5 class="card__title">{{ title }}</h5>
      </localized-link>
      <h6 class="card__sub-title" v-if="action">
        {{ sub_title_wrong || sub_title }}
        <!-- {{ sub_title }} -->
        <span class="card__sub-title-wrong d-none">{{ sub_title_wrong }}</span>
      </h6>
      <p class="card__desc">
        {{ desc }}
      </p>
    </div>
    <template v-if="action && checkAvailableGender">
      <div
        class="card__action card__action--remove pa-3"
        v-if="active"
        @click="removeCart(data)"
      >
        <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
      </div>
      <div
        class="card__action card__action--add pa-3"
        v-else
        @click="addCart(data)"
      >
        <v-icon color="#e1e8f5"> mdi-cart-plus </v-icon>
      </div>
    </template>
  </v-card>
</template>

<script>
import icons from "@/components/elements/Icons.vue";
export default {
  components: {
    icons,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "-",
    },
    sub_title: {
      type: String,
      default: "-",
    },
    sub_title_wrong: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "-",
    },
    plant: {
      type: [String, Number],
      default: "",
    },
    contentImg: {
      type: Boolean,
      default: false,
    },
    allGender: {
      type: Boolean,
      default: false,
    },
    disableTitleLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkAvailableGender() {
      if (this.allGender) return true;
      if (this.data.gender == null || this.data.gender == "All") {
        return true;
      } else {
        return (
          this.$store.state.profileData.person.gender.name.toLowerCase() ==
          this.data.gender.toLowerCase()
        );
      }
    },
  },
  methods: {
    addCart(param) {
      this.$emit("addCart", param);
    },
    removeCart(param) {
      this.$emit("removeCart", param);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.card {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  height: 100%;
  &__action {
    border-radius: 0 !important;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  &__action--add {
    display: flex;
    background: $main_1;
  }
  &__action--remove {
    display: flex;
    background: $red;
  }
  &__title {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $dark_blue_3;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $main_1;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__sub-title-wrong {
    text-decoration: line-through;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $gray_3;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__desc {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $gray_4;
  }
  a {
    text-decoration: none;
  }
}
</style>
