export default {
  replaceSpecialChars(str) {
    if (typeof str !== "string") {
      return "";
    }

    // Decode HTML entities to handle double-encoded scenarios
    const decodeHtmlEntities = text => {
      // Create a temporary DOM element to decode HTML entities
      const txt = document.createElement("textarea");
      txt.innerHTML = text;
      return txt.value;
    };

    // First decode any entities
    let decodedStr = decodeHtmlEntities(str);

    // Now, proceed to replace known special characters
    return decodedStr
      .replace(/&amp;/gi, "&")
      .replace(/&lt;/gi, "<")
      .replace(/&gt;/gi, ">")
      .replace(/&quot;/gi, '"')
      .replace(/&apos;/gi, "'")
      .replace(/&reg;/gi, "®")
      .replace(/&trade;/gi, "™")
      .replace(/&copy;/gi, "©")
      .replace(/&#33;/g, "!")
      .replace(/&#64;/g, "@")
      .replace(/&#35;/g, "#")
      .replace(/&#36;/g, "$")
      .replace(/&#37;/g, "%")
      .replace(/&#94;/g, "^")
      .replace(/&#38;/g, "&")
      .replace(/&#42;/g, "*")
      .replace(/&#40;/g, "(")
      .replace(/&#41;/g, ")")
      .replace(/&#45;/g, "-")
      .replace(/&#95;/g, "_")
      .replace(/&#61;/g, "=")
      .replace(/&#43;/g, "+")
      .replace(/&#92;/g, "\\")
      .replace(/&#124;/g, "|")
      .replace(/&#91;/g, "[")
      .replace(/&#93;/g, "]")
      .replace(/&#123;/g, "{")
      .replace(/&#125;/g, "}")
      .replace(/&#59;/g, ";")
      .replace(/&#58;/g, ":")
      .replace(/&#47;/g, "/")
      .replace(/&#63;/g, "?")
      .replace(/&#46;/g, ".")
      .replace(/&#039;/g, "'")
      .replace(/&#62;/g, ">");
  },
};
