<template>
  <div class="home">
    <custom-section class="section pa-0 pa-md-5">
      <NavbarOne
        class="d-md-none d-lg-none"
        :showNavbar="showNavbar"
        :showCart="showCart"
        :showCartDesktop="showCartDesktop"
        :showSearch="showSearch"
        :showLogo="showLogo"
      >
      </NavbarOne>
      <div class="mb-6 corp-m">
        <div class="card--cart-order">
          <v-form v-model="valid" lazy-validation ref="form">
            <div class="payment-info">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="employeeList"
                item-key="id"
                class="pa-3 table-officecare-detail"
                :class="{
                  'table-stick-approved': employeeList.length > 15,
                }"
                :mobile-breakpoint="0"
                hide-default-footer
                :loading="myloadingvariable"
                :items-per-page="-1"
              >
                <template v-slot:top>
                  <v-row
                    class="d-flex flex align-center justify-space-between px-0 mt-0"
                  >
                    <v-col cols="12" md="2" class="d-flex align-center">
                      <router-link to="/company/examination-approval">
                        <v-icon class="ml-4">mdi-arrow-left</v-icon>
                      </router-link>
                      <!-- </v-col>
                        <v-col
                          cols="10"
                          md="2"
                          class="d-flex align-center pl-4"
                        > -->
                      <h4 class="pl-3">
                        {{ $t("page.employee.list_employee") }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="3" class="d-flex align-center">
                      <v-select
                        v-model="statusApproval"
                        :items="listStatusApprove"
                        item-text="title"
                        item-value="id"
                        dense
                        @change="selectedStatusApprove(statusApproval)"
                        :label="$t('select_status')"
                        outlined
                        class="pl-3"
                        hide-details
                      />
                    </v-col>
                    <v-col md="3" class="d-none d-md-flex"></v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="d-flex align-center justify-end pr-0"
                    >
                      <v-text-field
                        class="searchField fontSet pl-3 pr-3"
                        outlined
                        dense
                        hide-details
                        v-model="search"
                        :label="$t('search') + ' ...'"
                        append-icon="mdi-magnify"
                      ></v-text-field>

                      <div>
                        <v-select
                          class="footerHide pt-0"
                          v-model="perPage"
                          :items="itemShowing"
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item="{ item, isSelected, select }">
                  <tr v-if="item.rejected == true" class="grey lighten-2">
                    <td>
                      <v-checkbox disabled>
                        <v-icon class="px-1" color="black">mdi-check</v-icon>
                      </v-checkbox>
                    </td>
                    <td>
                      <v-text-field
                        disabled
                        style="
                          padding-top: 0px !important;
                          margin-top: 0px !important;
                          font-size: 12px;
                        "
                        v-model="item.reason"
                        >{{ item.reason_for_rejection }}</v-text-field
                      >
                    </td>
                    <td class="pt-2">{{ item.id_number }}</td>
                    <td class="pt-2">
                      {{ $fnc.replaceSpecialChars(item.patient) }}
                    </td>
                    <td class="pt-2">{{ item.department }}</td>
                    <td class="pt-2">{{ item.job_position }}</td>
                    <td class="pt-2">{{ item.type }}</td>
                    <td class="pt-2">{{ item.item_name }}</td>
                    <td class="pt-2">{{ item.status }}</td>
                    <td class="pt-2 text-right">
                      {{ formatPrice(item.amount_total) }}
                    </td>
                  </tr>
                  <tr v-else :class="isSelected ? 'grey lighten-2' : ''">
                    <td>
                      <v-checkbox @click="toggle(isSelected, select, item)">
                        <v-icon class="px-1" color="black" v-if="isSelected"
                          >mdi-check</v-icon
                        >
                      </v-checkbox>
                    </td>
                    <td>
                      <v-text-field
                        :disabled="!isSelected"
                        style="
                          padding-top: 0px !important;
                          margin-top: 0px !important;
                          font-size: 12px;
                        "
                        v-model="item.reason"
                        required
                      ></v-text-field>
                    </td>
                    <td class="pt-2">{{ item.id_number }}</td>
                    <td class="pt-2">
                      {{ $fnc.replaceSpecialChars(item.patient) }}
                    </td>
                    <td class="pt-2">{{ item.department }}</td>
                    <td class="pt-2">{{ item.job_position }}</td>
                    <td class="pt-2">{{ item.type }}</td>
                    <td class="pt-2">{{ item.item_name }}</td>
                    <td class="pt-2">{{ item.status }}</td>
                    <td class="pt-2 text-right">
                      {{ formatPrice(item.amount_total) }}
                    </td>
                  </tr>
                </template>
                <template v-slot:footer>
                  <v-row
                    class="d-flex flex align-center justify-space-between footer-payment"
                  >
                    <v-col cols="12" md="8">
                      <p class="footer-showing">
                        showing {{ itemShowStart }} to {{ itemShowStop }} of
                        {{ itemsTotal }} entries
                      </p>
                    </v-col>
                    <v-col cols="12" md="4" class="d-flex justify-end">
                      <custom-pagination
                        @changePage="val => (page = val)"
                        :totalPage="itemLength"
                        :activePage="page"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
              <v-row class="pa-3">
                <v-col cols="12">
                  <span
                    style="font-size: 12px; color: #808080"
                    v-html="$t('page.employee.note_1')"
                  ></span
                  ><br />
                  <span
                    style="font-size: 12px; color: #808080"
                    v-html="$t('page.employee.note_2')"
                  ></span
                  ><br />
                  <span
                    style="font-size: 12px; color: #808080"
                    v-html="$t('page.employee.note_3')"
                  ></span
                  ><br />
                  <span
                    style="font-size: 12px; color: #808080"
                    v-html="$t('page.employee.note_4')"
                  ></span
                  ><br />
                  <span
                    style="font-size: 12px; color: #808080"
                    v-html="$t('page.employee.note_5')"
                  ></span>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  :style="{
                    display: showButtons ? 'block' : 'none !important',
                  }"
                >
                  <v-btn
                    class="mt-6 button-approve"
                    :class="{ 'hide-buttons': disabledButton == false }"
                    @click="approveAll"
                    color="white"
                    dark
                    v-show="approveAllButton == true"
                  >
                    {{ $t("page.employee.approve_all") }}
                  </v-btn>

                  <v-btn
                    class="mt-6 button-reject"
                    :class="{ 'hide-buttons': disabledButton == false }"
                    @click="rejectAll"
                    color="white"
                  >
                    {{ $t("page.employee.reject_all") }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-end"
                  :style="{
                    display: showButtons ? 'block' : 'none !important',
                  }"
                >
                  <v-btn
                    :disabled="!valid"
                    :class="{ 'hide-buttons': disabledButton == true }"
                    class="mt-6 button-approve"
                    @click="approveSelected"
                    color="white"
                    v-show="approveSelectedButton == true"
                  >
                    {{ $t("page.employee.approve_selected") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-progress-linear
                class="mt-6"
                indeterminate
                color="teal"
                v-show="showingLoader == true"
              ></v-progress-linear>
            </div>
          </v-form>
        </div>
      </div>
      <!-- rating modal popup -->
      <v-dialog v-model="dialogEdit" max-width="500px">
        <v-form ref="formReject" lazy-validation>
          <v-card>
            <v-card-title>Give Reason</v-card-title>
            <v-divider class="mt-1"></v-divider>
            <v-card-text style="padding-bottom: 3px !important">
              <v-textarea
                ref="comment"
                v-model="editedItem.comment"
                :rules="[
                  () => !!editedItem.comment || 'This field is required',
                ]"
                :error-messages="errorMessages"
                solo
                name="comment"
                :label="$t('write_reason')"
                row-height="2"
                required
              ></v-textarea>
            </v-card-text>
            <v-divider class="mt-1"></v-divider>
            <v-card-actions class="justify-end">
              <v-btn text @click="closeEdit"> Cancel </v-btn>
              <v-btn
                color="#00a4b3"
                text
                v-show="rejectAllButton == true"
                @click="saveRejectAll"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </custom-section>
  </div>
</template>

<script>
// import TabContent from "@/components/v2/tabs/index.vue";
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import CustomPagination from "@/components/v3/pagination.vue";
import CustomSection from "@/components/v2/section/index.vue";

export default {
  components: { NavbarOne, CustomPagination, CustomSection },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    footerProps: { "items-per-page-options": [25, 50, 70, -1] },
    search: "",
    valid: false,
    modal: false,
    found: false,
    dialog: false,
    dialogEdit: false,
    editedIndex: -1,
    payload: {
      approval: true,
    },
    editedItem: {
      comment: "",
      approval: false,
    },
    employeeList: [],
    selected: [],
    headers: [],
    setInput: [],
    errorMessages: "",
    disabledReason: true,
    disabledButton: true,
    dataTampung: [],
    getDataErr: [],
    inputSelection: [],
    statusApproval: "",
    listStatusApprove: [],
    approveAllButton: true,
    rejectAllButton: true,
    approveSelectedButton: true,
    showingLoader: false,
    showButtons: false,
  }),
  watch: {
    dialogEdit(val) {
      val || this.closeEdit();
    },
    page: function () {
      this.getDataEmployee();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getDataEmployee();
      }
    },
    search: function () {
      this.page = 1;
      this.getDataEmployee();
    },
  },
  created() {
    this.headers = [
      {
        sortable: false,
      },
      {
        text: this.$t("input_reason"),
        sortable: false,
        value: "reason",
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_id"),
        sortable: false,
        value: "id_number",
        align: "center",
      },
      {
        text: this.$t("page.profile.patient_name"),
        value: "patient",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.department"),
        value: "department",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.jobpost"),
        value: "job_position",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("field.type"),
        sortable: false,
        value: "type",
        align: "center",
      },
      {
        text: this.$t("page.profile.item_name"),
        value: "item_name",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.status"),
        value: "status",
        sortable: false,
        align: "center",
      },
      {
        text: this.$t("page.profile.amount"),
        value: "amount_total",
        sortable: false,
        align: "center",
      },
    ];
    this.getDataEmployee();
  },
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  mounted() {
    // filter status
    API.get(`${process.env.VUE_APP_API_TRUST}employee/get-booking-status`, {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth.getToken()}`,
    }).then(res => {
      if (res.statusCode == 200) {
        this.listStatusApprove = res.results.map(item => ({
          id: item.status,
          title: item.status,
        }));
      }
    });

    API.get(
      `${process.env.VUE_APP_API_TRUST}employee/validation-approval?id=${this.$route.params.id}`,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then(res => {
      if (res.statusCode == 200) {
        this.showButtons = res.results.validation;
      }
    });
  },
  methods: {
    formatPrice(price) {
      return Intl.NumberFormat().format(price);
    },
    toggle(isSelected, select, item) {
      if (this.showButtons) {
        select(!isSelected);

        if (isSelected == true) {
          const getIndex = this.dataTampung.findIndex(
            data => data.id == item.id
          );
          if (getIndex > -1) {
            this.dataTampung.splice(getIndex, 1);
          }
        } else if (isSelected == false) {
          this.dataTampung = [...this.dataTampung, item];
        }
        if (this.dataTampung.length > 0) {
          this.disabledButton = false;
        } else {
          this.disabledButton = true;
        }
      }
    },
    rejectAll() {
      if (this.$refs.form.validate() == true) {
        this.selected = this.employeeList;
        this.dialogEdit = true;
      }
    },
    closeEdit() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.dialogEdit = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();

      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },

    async getDataEmployee() {
      // console.log(`${Auth.getToken()}`);
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${this.search}&id=${this.$route.params.id}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // Check result exist
        if (res.results) {
          // this.showButtons = res.results.some(item => item.status === 'WAITING FOR APPROVAL' || item.status ==='APPROVED BY CEO');

          this.getDataErr = res.results.filter(errGet => {
            return true == errGet.rejected;
          });
          if (this.getDataErr.length > 0) {
            this.disabledButton = false;
          } else {
            this.disabledButton = true;
          }

          var new_data = [];
          res.results.map(function (e) {
            new_data.push({
              id: typeof e.id !== "undefined" ? e.id : "",
              rejected: e.rejected !== null ? e.rejected : "",
              patient:
                typeof e.patient !== "undefined" ? e.patient.toUpperCase() : "",
              id_number:
                typeof e.id_number !== "undefined" && e.id_number !== null
                  ? e.id_number.toUpperCase()
                  : "",
              reason_for_rejection:
                e.reason_for_rejection !== null
                  ? e.reason_for_rejection.toUpperCase()
                  : "",
              department:
                e.department !== null ? e.department.toUpperCase() : "",
              job_position:
                e.job_position !== null ? e.job_position.toUpperCase() : "-",
              type: typeof e.type !== "undefined" ? e.type.toUpperCase() : "",
              item_name:
                typeof e.item_name !== "undefined"
                  ? e.item_name.toUpperCase()
                  : "",
              status:
                typeof e.status !== "undefined" ? e.status.toUpperCase() : "",
              amount_total:
                typeof e.amount_total !== "undefined" ? e.amount_total : "",
            });
          });

          this.employeeList = new_data;

          this.itemsTotal = res.total;
          this.itemLength = Math.ceil(this.itemsTotal / showPage);
          if (this.itemShowStop < 0) {
            this.itemShowStop = res.total;
          }
          if (this.itemShowStop > res.total) {
            this.itemShowStop = res.total;
          }
          this.myloadingvariable = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async selectedStatusApprove(param) {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}employee/get-booking-detail?query=${param}&id=${this.$route.params.id}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        if (res.results) {
          // Check result exist
          if (this.dataProfile) {
            var new_data = [];
            var checkData = [];

            if (param != null || param != "") {
              checkData = res.results.filter(res => {
                return res.status == param;
              });
            }

            // if(dataProfile.temp_type == 'PAYOR_HRDMGR'){
            //   checkData = res.results.filter(res => {
            //     return res.status == 'WAITING FOR APPROVAL';
            //   });
            // }
            // else if(dataProfile.temp_type == 'PAYOR_FINANCE'){
            //   checkData = res.results.filter(res => {
            //     return res.status == 'APPROVED BY HRD';
            //   });
            // }

            checkData.map(function (e) {
              new_data.push({
                id: typeof e.id !== "undefined" ? e.id : "",
                rejected: e.rejected !== null ? e.rejected : "",
                patient:
                  typeof e.patient !== "undefined"
                    ? e.patient.toUpperCase()
                    : "",
                id_number:
                  typeof e.id_number !== "undefined"
                    ? e.id_number.toUpperCase()
                    : "",
                reason_for_rejection:
                  e.reason_for_rejection !== null
                    ? e.reason_for_rejection.toUpperCase()
                    : "",
                department:
                  e.department !== null ? e.department.toUpperCase() : "",
                job_position:
                  e.job_position !== null ? e.job_position.toUpperCase() : "-",
                type: typeof e.type !== "undefined" ? e.type.toUpperCase() : "",
                item_name:
                  typeof e.item_name !== "undefined"
                    ? e.item_name.toUpperCase()
                    : "",
                status:
                  typeof e.status !== "undefined" ? e.status.toUpperCase() : "",
                amount_total:
                  typeof e.amount_total !== "undefined" ? e.amount_total : "",
              });
            });

            this.employeeList = new_data;

            this.itemsTotal = res.total;
            this.itemLength = Math.ceil(this.itemsTotal / showPage);
            if (this.itemShowStop < 0) {
              this.itemShowStop = res.total;
            }
            if (this.itemShowStop > res.total) {
              this.itemShowStop = res.total;
            }
            this.myloadingvariable = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    approveAll() {
      if (this.$refs.form.validate() == true) {
        this.setInput = [];
        this.selected = this.employeeList;
        this.approveAllButton = false;
        this.showingLoader = true;

        // if (this.selected.length > 0) {
        //   this.selected.forEach(element => {
        //     this.setInput = [
        //       ...this.setInput,
        //       {
        //         approve: this.payload.approval,
        //         reason: "",
        //         detail_id: element.id,
        //       },
        //     ];
        //   });
        // }

        API.post(
          `${process.env.VUE_APP_API_TRUST}employee/approve-transaction`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          {
            approve_all: true,
            reject_all: false,
            booking_header_id: parseInt(this.$route.params.id),
            data: this.setInput,
          }
        )
          .then(res => {
            if (res.statusCode === 200) {
              this.$router.push(
                `/${this.$i18n.locale}/company/examination-approval`
              );
              this.setInput = [];
              this.approveAllButton = false;
              this.showingLoader = true;
            } else if (res.statusCode == 400) {
              this.toast("error", res.message);
              // Object.keys(res.errors).forEach(element => {
              //   this.toast(
              //     "error",
              //     typeof res.errors[element] == "object"
              //       ? res.errors[element][0]
              //       : res.errors[element]
              //   );
              // });
              this.setInput = [];
              this.approveAllButton = true;
              this.showingLoader = false;
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
                //text: this.$t("error_try_again_latter"),
              });
              this.approveAllButton = true;
              this.showingLoader = false;
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              text: "Error Add Employee!",
            });
            this.approveAllButton = true;
            this.showingLoader = false;
          });
      } else {
        this.approveAllButton = true;
        this.showingLoader = false;
      }
    },
    saveRejectAll() {
      if (this.$refs.formReject.validate() == true) {
        this.setInput = [];
        this.rejectAllButton = false;
        this.showingLoader = true;
        // if (this.selected.length > 0) {
        //   this.selected.forEach(element => {
        //     this.setInput = [
        //       ...this.setInput,
        //       {
        //         approve: this.editedItem.approval,
        //         reason: this.editedItem.comment,
        //         detail_id: element.id,
        //       },
        //     ];
        //   });
        // } else {
        //   this.rejectAllButton = true;
        //   this.showingLoader = false;
        // }

        API.post(
          `${process.env.VUE_APP_API_TRUST}employee/approve-transaction`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          {
            approve_all: false,
            reject_all: true,
            reason: this.editedItem.comment,
            booking_header_id: parseInt(this.$route.params.id),
            data: this.setInput,
          }
        )
          .then(res => {
            if (res.statusCode === 200) {
              this.$router.push(
                `/${this.$i18n.locale}/company/examination-approval`
              );
              this.setInput = [];
              this.rejectAllButton = false;
              this.showingLoader = true;
            } else if (res.statusCode == 400) {
              this.toast("error", res.message);
              // Object.keys(res.errors).forEach(element => {
              //   this.toast(
              //     "error",
              //     typeof res.errors[element] == "object"
              //       ? res.errors[element][0]
              //       : res.errors[element]
              //   );
              // });
              this.setInput = [];
              this.rejectAllButton = true;
              this.showingLoader = false;
            } else {
              this.$swal({
                icon: "error",
                text: res.message,
                //text: this.$t("error_try_again_latter"),
              });
              this.rejectAllButton = true;
              this.showingLoader = false;
            }
          })
          .catch(() => {
            this.$swal({
              icon: "error",
              text: "Error Add Employee!",
            });
            this.rejectAllButton = true;
            this.showingLoader = false;
          });
      } else {
        this.setInput = [];
        this.rejectAllButton = true;
        this.showingLoader = false;
      }
    },
    approveSelected() {
      if (this.$refs.form.validate() == true) {
        this.setInput = [];
        this.approveSelectedButton = false;
        this.showingLoader = true;

        if (this.getDataErr.length > 0) {
          this.inputSelection = [...this.selected, ...this.getDataErr];
        } else {
          this.inputSelection = this.selected;
        }

        if (this.inputSelection.length > 0) {
          this.inputSelection.forEach(element => {
            if (element.rejected == true) {
              if (
                typeof element.reason_for_rejection == "undefined" ||
                element.reason_for_rejection == ""
              ) {
                this.disabledReason = false;
                this.$swal({
                  icon: "error",
                  text: this.$t("page.employee.reject_reason"),
                });
                this.approveSelectedButton = true;
                this.showingLoader = false;
              } else {
                this.disabledReason = true;
                this.setInput = [
                  ...this.setInput,
                  {
                    approve: false,
                    reason: element.reason_for_rejection,
                    detail_id: element.id,
                  },
                ];
              }
            } else {
              if (
                typeof element.reason == "undefined" ||
                element.reason == ""
              ) {
                this.disabledReason = false;
                this.$swal({
                  icon: "error",
                  text: this.$t("page.employee.reject_reason"),
                });
                this.approveSelectedButton = true;
                this.showingLoader = false;
              } else {
                this.disabledReason = true;
                this.setInput = [
                  ...this.setInput,
                  {
                    approve: false,
                    reason: element.reason,
                    detail_id: element.id,
                  },
                ];
              }
            }
          });
        } else {
          this.approveSelectedButton = true;
          this.showingLoader = false;
        }

        if (this.disabledReason) {
          const filterNew = this.employeeList.filter(dataNew => {
            const findIndexNew = this.inputSelection.findIndex(
              dataBaruFindIndex => dataBaruFindIndex.id == dataNew.id
            );

            return findIndexNew < 0;
          });

          if (filterNew.length > 0) {
            filterNew.forEach(element => {
              this.setInput = [
                ...this.setInput,
                {
                  approve: true,
                  reason: "",
                  detail_id: element.id,
                },
              ];
            });
          }

          API.post(
            `${process.env.VUE_APP_API_TRUST}employee/approve-transaction`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            {
              approve_all: false,
              reject_all: false,
              booking_header_id: parseInt(this.$route.params.id),
              data: this.setInput,
            }
          )
            .then(res => {
              if (res.statusCode === 200) {
                this.$router.push(
                  `/${this.$i18n.locale}/company/examination-approval`
                );
                this.approveSelectedButton = false;
                this.setInput = [];
                this.showingLoader = true;
              } else if (res.statusCode == 400) {
                this.toast("error", res.message);
                // Object.keys(res.errors).forEach(element => {
                //   this.toast(
                //     "error",
                //     typeof res.errors[element] == "object"
                //       ? res.errors[element][0]
                //       : res.errors[element]
                //   );
                // });
                this.setInput = [];
                this.approveSelectedButton = true;
                this.showingLoader = false;
              } else {
                this.$swal({
                  icon: "error",
                  text: res.message,
                  //text: this.$t("error_try_again_latter"),
                });
                this.setInput = [];
                this.approveSelectedButton = true;
                this.showingLoader = false;
              }
            })
            .catch(() => {
              this.$swal({
                icon: "error",
                text: "Error Add Employee!",
              });
              this.setInput = [];
              this.approveSelectedButton = true;
              this.showingLoader = false;
            });
        }
      } else {
        this.setInput = [];
        this.approveSelectedButton = true;
        this.showingLoader = false;
      }
    },
  },
};
</script>
<style>
.table-officecare-detail thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}

.table-officecare-detail thead tr th {
  height: 38px !important;
  white-space: nowrap;
}

.table-officecare-detail tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}

.table-officecare-detail tbody tr:hover {
  background-color: #eee !important;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
}
.card--cart-order {
  padding: 0px 0px 15px 0px;
  border-radius: 4px;
  background: #fff;
  //   margin-top: 10px;
}
.button-approve {
  background-color: #00a4b3 !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 130px !important;
  margin-right: 10px;
}

.button-reject {
  background-color: #e96d6d !important;
  border-radius: 5px;
  color: white !important;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
  height: 30px !important;
  min-width: 130px !important;
  margin-right: 10px;
}

.searchField {
  max-width: 500px;
}

.button--active {
  background: $main_3;
  color: white;
}

.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}

.button--download {
  background: #79c4c6;
  color: white;
}

.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}

.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;

  h4 {
    color: $gray_1;
    font-size: 20px;
  }

  p {
    margin: 0;
  }

  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;

    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--inprogress {
  color: $yellow_1;
  font-weight: 600;
}

.status--complete {
  color: $green_1;
  font-weight: 600;
}

.status--cancel {
  color: $red_1;
  font-weight: 600;
}

.tabs {
  background: #e5e5e5 !important;
}

.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}

a {
  text-decoration: none;
}

.fontSet {
  font-size: 14px !important;
}

.box-page-new {
  margin: 20px;
  border-radius: 4px !important;
}

.hide-buttons {
  visibility: hidden;
}
</style>

<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stick-approved .v-data-table__wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stick-approved .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
.table-stick-approved .v-data-table__wrapper {
  /* overflow-x: auto; */
  overflow-y: auto;
  height: 400px !important;
}
.table-stick-approved thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
}
</style>
