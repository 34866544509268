<template>
  <v-row justify="center">
    <v-dialog v-model="is_show" max-width="700px" persistent>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <!-- start tab section package or test -->
          <div class="tab-section-test">
            <div
              class="tab-section-test__item"
              :class="{ active: activeData.type == 'test' }"
              @click="
                activeData.type = cantChangeService ? activeData.type : 'test'
              "
            >
              Order for Test
            </div>
            <div
              class="tab-section-test__item"
              :class="{ active: activeData.type == 'package' }"
              @click="
                activeData.type = cantChangeService
                  ? activeData.type
                  : 'package'
              "
            >
              Order for Package
            </div>
          </div>
          <div class="pa-5 pb-0">
            <v-autocomplete
              hide-details
              v-model="activeData.memberFamily"
              :items="familyList"
              item-text="related_person_name"
              item-value="patient_id"
              :label="`${$t('select')} ${$t('patient')}`"
              :rules="[
                v =>
                  !!v ||
                  `${$t('page.office_care.family_member')} ${$t(
                    'is_required'
                  )}`,
              ]"
              outlined
              dense
            >
            </v-autocomplete>
            <card-test
              class="mt-4"
              v-if="step == 2"
              :active="checkItem(itemsSelected[0].id)"
              disable-title-link
              :plant="filters.plant_id"
              :data="itemsSelected[0]"
              :title="itemsSelected[0].name"
              :sub_title="`${
                itemsSelected[0].prices != undefined
                  ? itemsSelected[0].prices.length !== 0
                    ? `IDR ${formater(itemsSelected[0].prices[0].amount)}.00`
                    : '0'
                  : '0'
              }`"
              :desc="itemsSelected[0][`description_${$i18n.locale}`]"
            />
            <h5 class="mt-4" v-if="step == 2">Add ons</h5>
          </div>
          <!-- end tab section package or test -->

          <v-card-text class="overflow-y-auto" style="max-height: 60vh">
            <div style="min-height: 500px">
              <div class="text-center" v-if="loading">
                <icon-loading class="icon-loading" />
              </div>
              <v-row class="mb-16" v-if="step == 1">
                <v-col
                  cols="12"
                  md="6"
                  v-for="(test, index) of listTest"
                  :key="index"
                >
                  <card-test
                    :active="checkItem(test.id)"
                    action
                    all-gender
                    disable-title-link
                    :plant="filters.plant_id"
                    :data="test"
                    :title="
                      activeData.type == 'test'
                        ? test[
                            $i18n.locale == 'id'
                              ? `name`
                              : `name_${$i18n.locale}`
                          ]
                        : test.name
                    "
                    :sub_title="`${
                      test.prices != undefined
                        ? test.prices.length !== 0
                          ? `IDR ${formater(test.prices[0].amount)}.00`
                          : '0'
                        : '0'
                    }`"
                    :desc="test[`description_${$i18n.locale}`]"
                    @addCart="item => addCart(item)"
                    @removeCart="item => removeCart(item)"
                  />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  cols="12"
                  md="6"
                  v-for="(test, index) of listAddon"
                  :key="index"
                >
                  <card-test
                    :active="checkItem(test.id)"
                    action
                    all-gender
                    disable-title-link
                    :plant="filters.plant_id"
                    :data="test"
                    :title="
                      test[
                        $i18n.locale == 'id'
                          ? `product_name`
                          : `product_name_${$i18n.locale}`
                      ]
                    "
                    :sub_title="`${`IDR ${formater(test.amount)}.00`}`"
                    @addCart="item => addCart(item)"
                    @removeCart="item => removeCart(item)"
                  />
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <div class="summary-action pa-4">
            <div class="d-flex cart">
              <v-badge
                color="pink"
                :content="itemsSelected.length"
                :value="itemsSelected.length"
                class="badge mr-4"
              >
                <IconCart color="#00A4B3" />
              </v-badge>
              <h4>{{ `IDR ${this.formater(calculateItemsSelected)}.00` }}</h4>
            </div>
            <div>
              <v-btn @click.stop="cancel" small class="mr-3" v-if="step == 1">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn @click.stop="step = 1" small class="mr-3" v-if="step == 2">
                {{ $t("back") }}
              </v-btn>
              <v-btn
                v-if="
                  itemsSelected.length == 0 ||
                  step == 2 ||
                  (step == 1 && activeData.type != 'package') ||
                  (activeData.type == 'package' &&
                    itemsSelected.length > 0 &&
                    itemsSelected[0].is_set_addons == false)
                "
                class="bg-blue"
                @click.stop="save"
                small
                :disabled="!valid || itemsSelected.length == 0"
              >
                {{ $t("save") }}
              </v-btn>
              <v-btn
                v-if="
                  step == 1 &&
                  activeData.type == 'package' &&
                  itemsSelected.length > 0 &&
                  itemsSelected[0].is_set_addons
                "
                class="bg-blue"
                @click.stop="next"
                small
                :disabled="!valid || itemsSelected.length == 0"
              >
                {{ $t("next") }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>
<script>
import CardTest from "@/components/v3/cards/card-test.vue";
import IconCart from "@/components/elements/icv2_test_cart_blue.vue";
import IconLoading from "@/components/elements/IconLoading.vue";

import API from "@/service/api_service";
import Auth from "@/service/auth_service";
export default {
  components: {
    CardTest,
    IconCart,
    IconLoading,
  },
  props: {
    tampilkan: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    familyList: {
      type: Array,
      default: () => [],
    },
    cantChangeService: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      valid: true,
      is_show: this.tampilkan,
      listTest: [],
      listAddon: [],
      itemsSelected: this.editData.items,
      activeData: this.editData,
      loading: false,
      step: 1,
    };
  },
  watch: {
    "activeData.type": function (now, then) {
      if (now != then) {
        this.itemsSelected = [];
        if (now == "test") {
          this.getProduct();
        } else {
          this.getPackage();
        }
      }
    },
  },
  computed: {
    calculateItemsSelected() {
      let total = 0;
      this.itemsSelected.forEach(item => {
        if (item.prices && item.prices.length > 0) {
          total += parseFloat(item.prices[0].amount);
        } else {
          if (item.amount) {
            total += parseFloat(item.amount);
          } else {
            total += 0;
          }
        }
      });
      return total;
    },
  },
  mounted() {
    if (this.activeData.type == "test") {
      this.getProduct();
    } else {
      this.getPackage();
    }
  },
  methods: {
    // start calculate discount per item
    calculateDiscount(price, discount) {
      // if not percent
      if (discount.amount != 0) {
        const finalPrice =
          parseFloat(price.amount) - parseFloat(discount.amount);
        return finalPrice <= 0
          ? this.$t("free")
          : `IDR ${this.formater(finalPrice)}.00`;
      }
      // if percent
      else {
        const totalDiscount = Math.min(
          parseFloat(price.amount) * (discount.percent / 100),
          parseFloat(discount.amount_max)
        );
        const finalPrice = parseFloat(price.amount) - totalDiscount;
        return finalPrice <= 0
          ? this.$t("free")
          : `IDR ${this.formater(finalPrice)}.00`;
      }
    },
    // end calculate discount per item

    cancel() {
      this.$emit("cancelChooseTest");
    },
    next() {
      if (this.$refs.form.validate() == true) {
        this.valid = true;
        this.step = 2;
        this.getAddon();
      }
    },
    save() {
      if (this.$refs.form.validate() == true) {
        this.valid = true;
        this.$emit("updateChooseTest", {
          type: this.activeData.type,
          items: this.itemsSelected,
          memberFamily: this.activeData.memberFamily,
        });
      }
    },
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    checkItem(id) {
      if (this.step == 2) {
        return (
          this.itemsSelected.find((item, i) => i != 0 && item.id === id) !==
          undefined
        );
      }
      return this.itemsSelected.find(item => item.id === id) !== undefined;
    },
    addCart(product) {
      let existingItem = this.itemsSelected.find(
        item => item.id === product.id
      );
      if (this.step == 2) {
        existingItem = this.itemsSelected.find(
          (item, i) => i != 0 && item.id === product.id
        );
      }
      if (existingItem) {
        return;
      }
      // reset when package
      if (this.activeData.type == "package" && this.step == 1)
        this.itemsSelected = [];
      this.itemsSelected.push(product);
    },
    removeCart(item) {
      this.itemsSelected = this.itemsSelected.filter(i => i.id !== item.id);
    },
    async getProduct() {
      try {
        this.loading = true;
        this.listTest = [];
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get?plant_id=${this.filters.plant_id}&home_care=true`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          this.listTest = [...this.listTest, ...resp.results];
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getPackage() {
      try {
        this.loading = true;
        this.listTest = [];
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}package/get?plant_id=${this.filters.plant_id}&homecare=true`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          this.listTest = [...this.listTest, ...resp.results];
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getAddon() {
      try {
        this.loading = true;
        this.listAddon = [];
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}package/get-addons?package_id=${this.itemsSelected[0].id}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          this.listAddon = [...this.listAddon, ...resp.results];
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.summary-action {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 1px 1px 8px gray;
  left: 0;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tab-section-test {
  display: flex;
  &__item {
    color: #00a4b3;
    flex: 1;
    box-shadow: 1px 1px 8px gray;
    cursor: pointer;
    padding: 18px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    &.active {
      cursor: default;
      background: #00a4b3;
      color: white;
    }
  }
}
</style>
