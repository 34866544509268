<template>
  <div>
    <div class="corp-m">
      <div class="payment-info">
        <v-data-table
          :options.sync="options"
          :headers="headerTable"
          :items="revenueList"
          hide-default-footer
          :loading="myloadingvariable"
          class="table-stickoy-patient-list"
          :mobile-breakpoint="0"
          :items-per-page="-1"
        >
          <template v-slot:top>
            <div class="card__header">
              <v-row class="content-table-new">
                <v-col cols="12" md="6" class="d-flex align-center">
                  <v-dialog
                    ref="dialog"
                    v-model="modalStartDate"
                    :return-value.sync="start_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mt-0"
                        v-model="dateFormatedStart"
                        :label="$t('page.profile.start_date')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        append-icon="mdi-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      v-model="start_date"
                      :max="
                        new Date(
                          new Date(end_date != '' ? end_date : null) -
                            new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modalStartDate = false"
                      >
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn text color="primary" @click="changeFilterDate">
                        Ok
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <span class="mx-2">-</span>
                  <v-dialog
                    ref="dialogend"
                    v-model="modalEndDate"
                    :return-value.sync="end_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="mt-0"
                        v-model="dateFormatedEnd"
                        :label="$t('page.profile.end_date')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        hide-details
                        append-icon="mdi-calendar"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :locale="$i18n.locale"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      :min="
                        new Date(
                          new Date(start_date != '' ? start_date : null) -
                            new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      v-model="end_date"
                      no-title
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modalEndDate = false">
                        {{ $t("cancel") }}
                      </v-btn>
                      <v-btn text color="primary" @click="changeFilterDate">
                        {{ $t("ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <!-- <v-select
                      v-model="intervalValue"
                      :items="listInterval"
                      item-text="title"
                      item-value="id"
                      dense
                      @change="selectedInterval(intervalValue)"
                      :label="$t('field.contract')"
                      outlined
                      class="mt-0 ml-2"
                      hide-details
                    /> -->
                </v-col>
                <v-col cols="12" md="6" class="d-flex justify-end">
                  <!-- <v-text-field
                      class="searchField pr-3"
                      outlined
                      dense
                      hide-details
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('search') + ' ...'"
                      single-line
                    ></v-text-field> -->

                  <div>
                    <v-select
                      class="footerHide"
                      v-model="perPage"
                      :items="itemShowing"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td valign="top" class="pt-2">
                {{ formatDateStandardWithTime(item.registration_date) }}
              </td>
              <td valign="top" class="pt-2">
                {{ item.invoice_number }}
              </td>
              <td valign="top" class="pt-2">
                <!-- <a href="javascript:void(0);" @click="openDialogOrder(item)"> -->
                  {{ item.order_number }}
                <!-- </a> -->
              </td>
              <td valign="top" class="pt-2 tb-wide">
                {{ item.patient_name }}
              </td>
              <td valign="top" class="pt-2 tb-wide">
                {{ item.contract_name }}
              </td>
              <td valign="top" class="pt-2 tb-wide">
                {{ item.treatment_name }}
              </td>
              <td valign="top" class="pt-2">
                <p class="list-table text-right" style="margin-bottom: 0px">
                  {{ formater(item.amount) }}
                </p>
              </td>
            </tr>
          </template>
          <template v-slot:footer>
            <div class="d-flex align-end justify-end pa-3">
              <p class="mb-0 mr-3" style="font-size: 12px; font-weight: 600">
                Total :
              </p>
              <p class="mb-0" style="font-size: 12px; font-weight: 600">
                {{ formater(totalRevenue) }}
              </p>
            </div>
            <v-divider></v-divider>
            <v-row
              class="d-flex flex align-center justify-space-between footer-payment"
            >
              <v-col cols="12" md="8">
                <p class="footer-showing">
                  showing {{ itemShowStart }} to {{ itemShowStop }} of
                  {{ itemsTotal }} entries
                </p>
              </v-col>
              <v-col cols="12" md="4" class="d-flex justify-end">
                <custom-pagination
                  @changePage="val => (page = val)"
                  :totalPage="itemLength"
                  :activePage="page"
                />
              </v-col>
            </v-row>
          </template>
        </v-data-table>
        <DialogResult
          v-if="dialog_order"
          :item="dataDialogOrder"
          :tampilkan="dialog_order"
          @toggleDialog="is_show => toggleDialogOrder(is_show)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
import moment from "moment";
import DialogResult from "@/components/v3/results/index.vue";
import CustomPagination from "@/components/v3/pagination.vue";
// import CustomSection from "@/components/v2/section/index.vue";

export default {
  components: {
    DialogResult,
    CustomPagination,
  },
  data: () => ({
    page: 1,
    options: null,
    itemsTotal: 0,
    itemLength: 1,
    itemShowStart: 0,
    itemShowStop: 0,
    itemShowing: [
      { value: 25, text: "25" },
      { value: 50, text: "50" },
      { value: 70, text: "70" },
      { value: 100, text: "100" },
    ],
    perPage: 25,
    myloadingvariable: true,
    activeTab: 2,
    checkItemMonth: false,
    checkItemYear: false,
    pageDataTable: 1,
    pageCountDataTable: 2,
    dataPerPage: 25,
    modalStartDate: false,
    modalEndDate: false,
    start_date: "",
    end_date: "",
    start_date_filter: "",
    end_date_filter: "",
    now: "",
    search: "",
    revenueList: [],
    totalPaid: 0,
    totalUnpaid: 0,
    totalRevenue: 0,
    listPerPage: [
      {
        value: -1,
        text: "All",
      },
      {
        value: 10,
        text: "10",
      },
      {
        value: 25,
        text: "25",
      },
      {
        value: 50,
        text: "50",
      },
    ],
    columns: [
      {
        label: "No Referal",
        field: "refer_number",
      },
      {
        label: "No Order",
        field: "order_number",
      },
      {
        label: "ID Pasien",
        field: "patient_id_number",
      },

      {
        label: "Nama Pasien",
        field: "patient",
      },
      {
        label: "Jasa Rujukan",
        field: "revenue",
      },
      {
        label: "Status",
        field: "claimed",
      },
    ],
    listInterval: [],
    intervalValue: "",
    headerTable: [],
    listTab: [
      {
        id: 1,
        title: "Referral Report",
      },
      {
        id: 2,
        title: "Fee Report",
      },
    ],
    dialog_order: false,
    dataDialogOrder: {
      order_id: null,
      list: [],
    },
  }),
  computed: {
    dateFormatedStart() {
      return this.formatDateNow(this.start_date);
    },
    dateFormatedEnd() {
      return this.formatDateNow(this.end_date);
    },
  },

  watch: {
    page: function () {
      this.getInitialRevenue();
    },
    perPage: function () {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.getInitialRevenue();
      }
    },
    search: function () {
      this.page = 1;
      this.getInitialRevenue();
    },
  },
  created() {
    (this.listInterval = [
      {
        id: 1,
        title: this.$t("this_month"),
      },
      {
        id: 2,
        title: this.$t("this_year"),
      },
    ]),
      (this.headerTable = [
        {
          text: this.$t("page.profile.registration_date"),
          value: "registration_date",
          align: "center",
          width: "150px",
        },
        {
          text: this.$t("page.profile.invoice_number"),
          value: "invoice_number",
          align: "center",
          width: "150px",
        },
        {
          text: this.$t("page.profile.order_no"),
          value: "order_number",
          align: "center",
          width: "150px",
        },
        {
          text: this.$t("page.profile.patient_name"),
          value: "patient_name",
          align: "center",
          width: "200px",
        },
        {
          text: this.$t("page.profile.contract_name"),
          value: "contract_name",
          align: "center",
          width: "200px",
        },
        {
          text: this.$t("page.profile.treatment_name"),
          value: "treatment_name",
          align: "center",
          width: "250px",
        },
        {
          text: this.$t("page.profile.amount"),
          value: "amount",
          align: "center",
          width: "150px",
        },
      ]);
    this.getInitialRevenue();
  },
  methods: {
    async getInitialRevenue() {
      try {
        this.myloadingvariable = true;
        // pagination showing start of entries
        let startPage = (this.page - 1) * this.perPage;
        let showPage = this.perPage;
        this.itemShowStart = startPage + 1;
        this.itemShowStop = startPage + showPage;

        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}referral/get-invoice-specimen?query=${this.search}&offset=${startPage}&per_page=${showPage}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode == 200) {
          this.revenueList = res.results;

          const filterNewUnpaid = res.results.filter(dataNewUnpaid => {
            return dataNewUnpaid.claimed == false;
          });
          filterNewUnpaid.forEach(data => {
            this.totalUnpaid += parseInt(data.amount);
          });
          // this.totalUnpaid = filterNewUnpaid.length;

          const filterNewPaid = res.results.filter(dataNewPaid => {
            return dataNewPaid.claimed == true;
          });
          filterNewPaid.forEach(data => {
            this.totalPaid += parseInt(data.amount);
          });
          // this.totalPaid = filterNewPaid.length;

          this.revenueList.forEach(data => {
            data.amount = data.amount || 0;
            this.totalRevenue += parseInt(data.amount);
          });
        }

        this.now = this.formatDateNow(new Date());
        this.start_date = new Date(
          `${new Date().getFullYear()}-${
            new Date().getMonth() + 1
          }-${new Date().getDate()}`
        )
          .toISOString()
          .substr(0, 10);
        this.end_date = new Date().toISOString().substr(0, 10);

        this.itemsTotal = res.total;
        this.itemLength = Math.ceil(this.itemsTotal / showPage);
        if (this.itemShowStop < 0) {
          this.itemShowStop = res.total;
        }
        if (this.itemShowStop > res.total) {
          this.itemShowStop = res.total;
        }
        this.myloadingvariable = false;
      } catch (error) {
        console.log("Error 403!");
      }
    },
    toggleDialogOrder(toggle) {
      this.dialog_order = toggle;
    },
    async openDialogOrder(item) {
      this.dataDialogOrder.order_number = item.order_number;
      this.dataDialogOrder.type_test = item.type;
      this.getOrderDetailPopup();
    },
    async getOrderDetailPopup() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}medical-record/inspection/list?order_number=${this.dataDialogOrder.order_number}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.dataDialogOrder.results = res.results;
        this.dialog_order = true;
      } catch (error) {
        console.log("Error 403!");
      }
    },
    currentDataItems() {
      if (this.activeTab == 1) {
        this.$router.push("/doctor/summary-report/referral");
      } else {
        this.$router.push("/doctor/summary-report/fee");
      }
    },
    getDateRange() {
      this.totalPaid = 0;
      this.totalUnpaid = 0;
      this.totalRevenue = 0;
      this.checkItemYear = false;
      this.checkItemMonth = false;

      API.get(`${process.env.VUE_APP_API_TRUST}referral/get-revenue`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then(res => {
        this.start_date_filter = new Date(this.start_date).getTime();
        this.end_date_filter = new Date(this.end_date).getTime();

        const dateRangeFilter = res.results.filter(dateRangeData => {
          const newDateRangeData = new Date(dateRangeData.registration_date);
          const setDateRange = `${newDateRangeData.getFullYear()}-${(
            "0" +
            (newDateRangeData.getMonth() + 1)
          ).slice(-2)}-${("0" + newDateRangeData.getDate()).slice(-2)}`;
          const dateRangeGetTime = newDateRangeData.getTime();

          if (setDateRange == this.start_date) {
            const setNewDateStart = new Date(this.start_date);
            const setStartFilter = `${setNewDateStart.getFullYear()}-${
              setNewDateStart.getMonth() + 1
            }-${setNewDateStart.getDate() - 1}`;
            const startFilterGetTime = new Date(setStartFilter).getTime();

            return dateRangeGetTime > startFilterGetTime;
          } else if (setDateRange == this.end_date) {
            const setDateFilterRange = `${newDateRangeData.getFullYear()}-${
              newDateRangeData.getMonth() + 1
            }-${newDateRangeData.getDate() - 1}`;
            const DateNewDateFilter = new Date(setDateFilterRange).getTime();
            const endFilterGetTime = new Date(this.end_date).getTime();

            return DateNewDateFilter < endFilterGetTime;
          } else {
            return (
              dateRangeGetTime > this.start_date_filter &&
              dateRangeGetTime < this.end_date_filter
            );
          }
        });
        this.revenueList = dateRangeFilter;

        const filterNewUnpaid = this.revenueList.filter(dataNewUnpaid => {
          return dataNewUnpaid.claimed == false;
        });
        filterNewUnpaid.forEach(data => {
          this.totalUnpaid += parseInt(data.amount);
        });
        // this.totalUnpaid = filterNewUnpaid.length;

        const filterNewPaid = this.revenueList.filter(dataNewPaid => {
          return dataNewPaid.claimed == true;
        });
        filterNewPaid.forEach(data => {
          this.totalPaid += parseInt(data.amount);
        });
        // this.totalPaid = filterNewPaid.length;

        this.revenueList.forEach(data => {
          data.amount = data.amount || 0;
          this.totalRevenue += parseInt(data.amount);
        });
      });
    },
    formatDateNow(param) {
      if (!param) return null;
      const date = new Date(param);
      // return `${("0" + date.getDate()).slice(-2)}-${(
      //     "0" +
      //     (date.getMonth() + 1)
      // ).slice(-2)}-${date.getFullYear()}`;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    changeFilterDate() {
      this.$refs.dialog.save(this.start_date);
      this.$refs.dialogend.save(this.end_date);
      // this.getDateRange();
      this.modalStartDate = false;
      this.modalEndDate = false;
    },
    formater(value) {
      // let val = (value / 1).toFixed(0).replace(".", ",");
      // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      let angka = parseInt(value) || 0;
      let nilai = angka.toLocaleString("en-US", { minimumFractionDigits: 2 });
      return nilai;
    },
    formatDateStandard(value) {
      return moment(value)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },

    formatDateStandardWithTime(value) {
      return moment(value).format("DD-MMM-YYYY HH:mm:ss").toUpperCase();
    },

    getYearClick() {
      this.totalPaid = 0;
      this.totalUnpaid = 0;
      this.totalRevenue = 0;
      const dateNow = new Date();
      const setYearNow = `${dateNow.getFullYear()}`;

      API.get(`${process.env.VUE_APP_API_TRUST}referral/get-revenue`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then(res => {
        const yearFilter = res.results.filter(setDataYear => {
          const newYearFilterData = new Date(setDataYear.registration_date);
          const setDateFormat = `${(
            "0" +
            (newYearFilterData.getMonth() + 1)
          ).slice(-2)}/${("0" + newYearFilterData.getDate()).slice(
            -2
          )}/${newYearFilterData.getFullYear()}`;
          const yearSlice = +setDateFormat.slice(-4);
          return setYearNow == yearSlice;
        });

        this.revenueList = yearFilter;
        this.checkItemYear = true;
        this.checkItemMonth = false;
        const filterNewUnpaid = this.revenueList.filter(dataNewUnpaid => {
          return dataNewUnpaid.claimed == false;
        });
        filterNewUnpaid.forEach(data => {
          this.totalUnpaid += parseInt(data.amount);
        });
        // this.totalUnpaid = filterNewUnpaid.length;

        const filterNewPaid = this.revenueList.filter(dataNewPaid => {
          return dataNewPaid.claimed == true;
        });
        filterNewPaid.forEach(data => {
          this.totalPaid += parseInt(data.amount);
        });
        // this.totalPaid = filterNewPaid.length;

        this.revenueList.forEach(data => {
          data.amount = data.amount || 0;
          this.totalRevenue += parseInt(data.amount);
        });
      });
    },
    getMonthClick() {
      this.totalPaid = 0;
      this.totalUnpaid = 0;
      this.totalRevenue = 0;
      const dateNow = new Date();
      const setMonthYearNow = `${("0" + (dateNow.getMonth() + 1)).slice(
        -2
      )}-${dateNow.getFullYear()}`;

      API.get(`${process.env.VUE_APP_API_TRUST}referral/get-revenue`, {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then(res => {
        const monthYearFilter = res.results.filter(setDataMonthYear => {
          const newMonthYearFilterData = new Date(
            setDataMonthYear.registration_date
          );
          const setMonthYearFormat = `${(
            "0" +
            (newMonthYearFilterData.getMonth() + 1)
          ).slice(-2)}-${newMonthYearFilterData.getFullYear()}`;

          return setMonthYearNow == setMonthYearFormat;
        });

        this.revenueList = monthYearFilter;
        this.checkItemYear = false;
        this.checkItemMonth = true;

        const filterNewUnpaid = this.revenueList.filter(dataNewUnpaid => {
          return dataNewUnpaid.claimed == false;
        });
        filterNewUnpaid.forEach(data => {
          this.totalUnpaid += parseInt(data.amount);
        });
        // this.totalUnpaid = filterNewUnpaid.length;

        const filterNewPaid = this.revenueList.filter(dataNewPaid => {
          return dataNewPaid.claimed == true;
        });
        filterNewPaid.forEach(data => {
          this.totalPaid += parseInt(data.amount);
        });
        // this.totalPaid = filterNewPaid.length;

        this.revenueList.forEach(data => {
          data.amount = data.amount || 0;
          this.totalRevenue += parseInt(data.amount);
        });
      });
    },
    selectedInterval(param) {
      if (param == 1) {
        this.getMonthClick();
      } else {
        this.getYearClick();
      }
    },
    reloadPage() {
      window.location.href = `/dashboard/summary-report/fee`;
    },
  },
};
</script>

<style>
.corp-m {
  margin-top: 4px !important;
}
@media screen and (max-width: 767px) {
  .corp-m {
    margin-top: -10px !important;
  }
}
@media screen and (min-width: 1023px) {
  .sticky-c1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 2;
    background: #fff;
    font-size: 12px !important;
  }
  .sticky-c2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 150px;
    z-index: 2;
    background: #fff;
  }
  .sticky-c3 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 400px;
    z-index: 2;
    background: #fff;
  }
  .sticky-h1 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 2;
    background: #ddd;
  }
  .sticky-h2 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 150px;
    z-index: 2;
    background: #ddd;
  }
  .sticky-h3 {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 400px;
    z-index: 2;
    background: #ddd;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.table-stickoy-patient-list .v-data-table__wrapper::-webkit-scrollbar {
  height: 8px;
  width: 0px;
  border: 1px solid #fff;
}

.table-stickoy-patient-list .v-data-table__wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.table-stickoy-patient-list .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}
.table-stickoy-patient-list tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-stickoy-patient-list .v-data-table__wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.super-dense > .v-data-table__wrapper > table > tbody > tr > td,
.super-dense > .v-data-table__wrapper > table > tbody > tr > th,
.super-dense > .v-data-table__wrapper > table > thead > tr > td,
.super-dense > .v-data-table__wrapper > table > thead > tr > th,
.super-dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 35px !important;
}
/* .table-stickoy-patient-list .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 400px;
}
.table-stickoy-patient-list thead {
  position: sticky;
  inset-block-start: 0;
  top: 0;
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
  z-index: 5;
} */

/* @media (min-width: 600px) {
  .table-stickoy .v-data-table__wrapper {
    overflow: unset;
  }
  .table-stickoy thead {
    position: sticky;
    inset-block-start: 0;
    top: 0;
    background: #eeeeee !important;
    font-weight: bold;
    color: #000 !important;
  }
}
@media (max-width: 599px) {
  .table-stickoy .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: auto;
    height: 400px;
  }
  .table-stickoy thead tr {
    border-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
} */
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.hd-sticky:hover {
  .sticky-c1 {
    background: #eee !important;
  }
  .sticky-c2 {
    background: #eee !important;
  }
  .sticky-c3 {
    background: #eee !important;
  }
  .v-data-table
    > .v-data-table__wrapper
    tbody
    tr:first-child:hover
    td:first-child {
    background: #eee !important;
  }
}

.searchField {
  max-width: 500px;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
  text-transform: capitalize;
}
.card--result {
  background: $gray_3;
  padding: 18px;
  border-radius: 16px;
  width: 100%;
  // max-width: 300px;
  color: $gray_2;
  h4 {
    color: $gray_1;
    font-size: 20px;
  }
  p {
    margin: 0;
  }
  .card--result__item {
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray_2;
    &:nth-last-child(1) {
      border: none;
    }
  }
}

.status--paid {
  color: $green_1;
  font-weight: 600;
}
.status--unpaid {
  color: $red_1;
  font-weight: 600;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 15px;
  @media screen and (max-width: 960px) {
    padding: 5px 18px 18px 18px;
  }
}
.content-table-new {
  padding: 5px 0px 5px 0px;
  @media screen and (max-width: 960px) {
    padding: 5px 0px 18px 0px;
  }
}
.total-fee {
  padding: 5px 18px 18px 18px;
}

.v-input {
  font-size: 12px !important;
}

@media screen and (max-width: 480px) {
  .content-table {
    padding: 0px !important;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
  height: 25px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: 35px !important;
}
</style>
<style>
.table-stickoy-patient-list thead {
  background: #ddd !important;
  font-weight: bold;
  color: #000 !important;
}
.table-stickoy-patient-list thead tr th {
  height: 38px !important;
  white-space: nowrap;
}
.table-stickoy-patient-list tbody tr td {
  font-size: 12px !important;
  height: 35px !important;
  white-space: nowrap;
}
.table-stickoy-patient-list tbody tr:hover {
  background-color: #eee !important;
}
</style>

<style lang="scss">
.footerHide {
  background: none !important;
  align-items: center;
  width: 50px;
  font-size: 12px;
}
.footerHide > .v-data-footer__pagination {
  display: none !important;
}
.footerHide > .v-data-footer__icons-before {
  display: none !important;
}
.footerHide > .v-data-footer__icons-after {
  display: none !important;
}
.payment-info .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-toolbar__title {
  font-size: 16px;
}
.footer-payment {
  margin: 0 auto !important;
}
.footer-showing {
  font-size: 12px !important;
  margin: 0 auto !important;
}

.footerHide > .v-text-field .v-input__prepend-inner,
.v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}
</style>
