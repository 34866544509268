<template>
  <v-card class="card card-test">
    <div class="card__body px-3 pa-2">
      <localized-link
        :to="`/patient/buy-package/${paket}?plant=${plant}`"
        v-if="plant != null && plant != ''"
      >
        <h5 class="card__title">{{ title }}</h5>
      </localized-link>
      <h5 class="card__title" v-else @click="$emit('detail')">{{ title }}</h5>
      <p class="card__disc" v-if="action">
        {{ sub_title }}
        <span class="card__sub-title-wrong">{{ sub_title_wrong }}</span>
      </p>
      <h6 class="card__sub-title" v-if="action">
        {{ disc }}
      </h6>
      <p class="card__desc">
        {{ desc }}
      </p>
    </div>
    <template v-if="action">
      <div
        class="card__action card__action--remove pa-3"
        v-if="active"
        @click="removeCart(data, paket)"
      >
        <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
      </div>
      <div
        class="card__action card__action--add pa-3"
        v-else
        @click="addCart(data, paket)"
      >
        <v-icon color="#e1e8f5"> mdi-cart-plus </v-icon>
      </div>

      <!-- <localized-link
        class="d-flex"
        :to="`/patient/order-deal/${data.id}?package=${paket}`"
      >
        <div class="card__action card__action--remove pa-3" v-if="active">
          <v-icon color="#e1e8f5"> mdi-cart-off </v-icon>
        </div>
        <div
          class="card__action card__action--add pa-3 text-uppercase font-weight-bold d-flex justify-center align-center"
          v-else
        >
          {{ $t("buy") }}
        </div>
      </localized-link> -->
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
    action: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "-",
    },
    sub_title: {
      type: [String, Number],
      default: "-",
    },
    sub_title_wrong: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "-",
    },
    disc: {
      type: [String, Number],
      default: "-",
    },
    paket: {
      type: [String, Number],
      default: "",
    },
    plant: {
      type: [String, Number],
      default: "",
    },
  },
  methods: {
    addCart(param, param2) {
      this.$emit("addCart", param, param2);
    },
    removeCart(param, param2) {
      this.$emit("removeCart", param, param2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.card {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  &__action {
    border-radius: 0 !important;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  &__action--add {
    display: flex;
    background: $main_1;
    color: white;
  }
  &__action--remove {
    display: flex;
    background: $red;
  }
  &__title {
    cursor: pointer;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $dark_blue_3;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $main_1;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__sub-title-wrong {
    text-decoration: line-through;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $gray_3;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__desc {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $gray_4;
  }
  &__disc {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $gray_3;
    text-decoration: line-through;
  }
  a {
    text-decoration: none;
  }
}
</style>
