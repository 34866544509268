<template>
  <div class="my-package pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <!-- </section> -->
    <v-row class="ma-1 mt-16 ma-md-3">
      <v-col md="3" class="d-none d-md-block filter">
        <div class="filter--sticky">
          <v-card class="mb-6 section filter__main">
            <custom-section>
              <v-row>
                <v-col cols="12" class="filter">
                  <v-autocomplete
                    v-model="branch"
                    :items="selectBranch"
                    item-value="id"
                    item-text="name"
                    hide-details
                    outlined
                    dense
                    color="#00a4b3"
                    prepend-inner-icon="mdi-home-modern"
                    id="category"
                    class="inp-text err-inp-text inp-elipse input-filter mb-3"
                    :placeholder="$t('field.branch')"
                    ref="autoBranch"
                  >
                  </v-autocomplete>

                  <h5 class="my-2 text-uppercase title-list-category">
                    {{ $t("category_package") }}
                    {{
                      `${
                        getTestItemHeader.length == 0
                          ? ""
                          : `(${getTestItemHeader.length})`
                      }`
                    }}
                  </h5>
                  <v-list nav class="list-category pa-0 text-uppercase">
                    <v-list-item-group mandatory v-model="filter.category">
                      <v-list-item
                        active-class="category--active"
                        class="mb-1"
                        :value="-1"
                        :key="-1"
                      >
                        <v-list-item-content class="py-1">
                          <v-list-item-title>{{
                            $t("all_category")
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        active-class="category--active"
                        class="mb-1"
                        v-for="(category, i) in getTestItemHeader"
                        :key="i"
                        :value="i"
                      >
                        <v-list-item-content class="py-1 text-uppercase">
                          <v-list-item-title>{{
                            category.groupName
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider dark style="border-color: white"></v-divider>
                      <v-list-item
                        active-class="category--active"
                        class="my-1"
                        :value="-2"
                        :key="-2"
                      >
                        <v-list-item-content class="py-1 text-uppercase">
                          <v-list-item-title>{{
                            $t("subscription")
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-divider dark style="border-color: white"></v-divider>
                </v-col>
              </v-row>
            </custom-section>
          </v-card>
          <v-card>
            <custom-section>
              <v-row>
                <v-col cols="12">
                  <h5 class="mb-1 text-uppercase">{{ $t("field.gender") }}</h5>
                  <v-list nav class="pa-0">
                    <v-list-item-group mandatory v-model="filter.gender">
                      <v-list-item
                        class="list-height margin-item mb-1"
                        v-for="gender in genders"
                        :key="gender.id"
                        :value="gender.name.toLowerCase()"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action class="mr-1 my-1">
                            <v-icon
                              color="#00a6b6"
                              class="radio-size"
                              v-if="active"
                            >
                              mdi-radiobox-marked
                            </v-icon>
                            <v-icon class="radio-size" v-else>
                              mdi-radiobox-blank
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="py-1">
                            <v-list-item-title
                              class="text-uppercase title-item"
                              >{{
                                $fnc.replaceSpecialChars(
                                  gender[
                                    $i18n.locale == "en"
                                      ? `name`
                                      : `name_${$i18n.locale}`
                                  ]
                                )
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </custom-section>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="9">
        <!-- loading -->
        <div class="d-flex" v-if="loading">
          <icon-loading class="icon-loading mx-auto" />
        </div>
        <template v-else>
          <v-card v-if="filter.category != -2">
            <custom-section>
              <v-alert
                dense
                type="warning"
                color="#FF5457"
                icon="mdi-alert-outline"
                style="font-size: 14px"
                v-if="dialogFilter && arrTest.length > 0"
              >
                {{ $t("showing_lab_for") }} {{ $t("u_can_modif_filter") }}
              </v-alert>
              <!-- disabled sementara selain bali nrc -->
              <v-row dense>
                <v-col class="d-flex d-md-none">
                  <div class="filter d-flex align-center">
                    <v-btn outlined rounded sm @click="filterDialog = true">
                      <v-icon left> mdi-filter-variant </v-icon>
                      {{ $t("filter") }}
                    </v-btn>

                    <v-autocomplete
                      v-model="branch"
                      :items="selectBranch"
                      item-value="id"
                      item-text="name"
                      hide-details
                      outlined
                      rounded
                      dense
                      id="category"
                      color="#00a4b3"
                      prepend-inner-icon="mdi-home-modern"
                      class="d-inline-block ml-3 branch-mobile"
                      :label="$t('field.branch')"
                      ref="autoBranchMobile"
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
                <template v-if="arrTest.length > 0">
                  <v-col
                    cols="12"
                    v-for="(category, index) of arrTest"
                    :key="index"
                  >
                    <v-card-actions
                      :id="`category${category.param}`"
                      class="pa-0"
                    >
                      <v-row>
                        <v-col cols="12" md="6">
                          <h3 class="title-page mb-3 d-inline-block">
                            {{ category.groupName }}
                          </h3>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                    <div>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          v-for="service in category.groupDetail"
                          :key="service.id"
                        >
                          <card-package
                            :active="checkItem(service, null)"
                            :action="branch != '' && branch != null"
                            :plant="
                              branch != '' && branch != null ? branch : ''
                            "
                            :data="service"
                            :title="service.name"
                            :sub_title="`${
                              service.prices != undefined
                                ? service.prices.length !== 0
                                  ? service.discount
                                    ? calculateDiscount(
                                        service.prices[0],
                                        service.discount[0]
                                      )
                                    : `IDR ${formater(
                                        parseFloat(service.prices[0].amount)
                                      )}`
                                  : '0'
                                : '0'
                            }`"
                            :sub_title_wrong="`${
                              service.discount
                                ? service.prices.length !== 0
                                  ? `IDR ${formater(
                                      parseFloat(service.prices[0].amount)
                                    )}`
                                  : ''
                                : ''
                            }`"
                            @detail="openWarn($t('branch_cannot_empty'))"
                            @addCartPackage="item => addCartPackage(item)"
                            @removeCartPackage="
                              item => removePackageNonSubs(item)
                            "
                          />
                          <!-- <Card shadow roundedMd class="h-full">
                        <template v-slot:body>
                          <card-test
                            hide
                            v-if="branch !== '' && branch !== null"
                            :title="service.name"
                            :discountPrice="`IDR ${
                              service.prices != null &&
                              service.prices.length !== 0
                                ? formater(service.prices[0].amount)
                                : '0'
                            }`"
                            :subTitle="`${service.total_products} ${$t(
                              'test_covered'
                            )}`"
                          >
                            <template v-slot:footer>
                              <v-row dense>
                                <v-col class="text-left" cols="6">
                                  <localized-link
                                    :to="`/patient/buy-package/${service.id}?plant=${branch}`"
                                  >
                                    <v-btn
                                      class="button btn-detail"
                                      rounded
                                      text
                                      @click="
                                        linkBranch(
                                          $t('branch_cannot_empty'),
                                          $event
                                        )
                                      "
                                    >
                                      {{ $t("detail") }}
                                    </v-btn>
                                  </localized-link>
                                </v-col>
                                <v-col class="text-right" cols="6">
                                  <localized-link
                                    :to="`/patient/order-package/${service.id}?plant=${branch}`"
                                  >
                                    <v-btn
                                      class="button btn-detail"
                                      rounded
                                      text
                                      @click="
                                        linkBranch(
                                          $t('branch_cannot_empty'),
                                          $event
                                        )
                                      "
                                    >
                                      {{ $t("book_now") }}
                                    </v-btn>
                                  </localized-link>
                                </v-col>
                              </v-row>
                            </template>
                          </card-test>

                          <card-test
                            v-if="branch == null || branch === ''"
                            :title="service.name"
                            :subTitle="`${service.total_products} ${$t(
                              'test_covered'
                            )}`"
                          >
                            <template v-slot:footer>
                              <v-btn
                                class="button btn-detail"
                                @click="
                                  linkBranch($t('branch_cannot_empty'), $event)
                                "
                                rounded
                                text
                              >
                                {{ $t("detail") }}
                              </v-btn>
                            </template>
                          </card-test>
                        </template>
                      </Card> -->
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </template>
                <v-col v-else cols="12">
                  <div
                    class="d-flex flex-column align-center flex-sm-row justify-sm-center not-available text-center"
                  >
                    <img
                      :src="require('@/assets/images/banner/coming_soon.png')"
                    />
                    <p class="mb-0">
                      {{ $t("product_not_available_at_this_branch") }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <!-- <coming-soon v-else class="mb-3" /> -->
            </custom-section>
          </v-card>

          <!-- subscribe list -->
          <v-card v-if="filter.category == -2">
            <custom-section>
              <v-alert
                dense
                type="warning"
                color="#FF5457"
                icon="mdi-alert-outline"
                style="font-size: 14px"
                v-if="dialogFilter && arrTestSubscribe.length > 0"
              >
                {{ $t("showing_lab_for") }} {{ $t("u_can_modif_filter") }}
              </v-alert>
              <!-- disabled sementara selain bali nrc -->
              <v-row dense>
                <v-col class="d-flex d-md-none">
                  <div class="filter d-flex align-center">
                    <v-btn outlined rounded sm @click="filterDialog = true">
                      <v-icon left> mdi-filter-variant </v-icon>
                      {{ $t("filter") }}
                    </v-btn>
                    <v-autocomplete
                      v-model="branch"
                      :items="selectBranch"
                      item-value="id"
                      item-text="name"
                      hide-details
                      outlined
                      rounded
                      dense
                      id="category"
                      color="#00a4b3"
                      prepend-inner-icon="mdi-home-modern"
                      class="d-inline-block ml-3 branch-mobile"
                      :label="$t('field.branch')"
                      ref="autoBranchMobile"
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
                <template v-if="arrTestSubscribe.length > 0">
                  <v-col
                    cols="12"
                    v-for="(category, index) of arrTestSubscribe"
                    :key="index"
                  >
                    <v-card-actions
                      :id="`category${category.param}`"
                      class="pa-0"
                    >
                      <v-row>
                        <v-col cols="12" md="6">
                          <h3 class="title-page mb-3 d-inline-block">
                            {{ category.groupName }}
                          </h3>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                    <div>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          v-for="service in category.groupDetail"
                          :key="service.id"
                        >
                          <card-package
                            :active="checkItem(service, null)"
                            subscribe
                            :action="branch != '' && branch != null"
                            :plant="
                              branch != '' && branch != null ? branch : ''
                            "
                            :data="service"
                            :title="service.name"
                            :sub_title="`${
                              service.prices != undefined
                                ? service.prices.length !== 0
                                  ? service.discount
                                    ? calculateDiscount(
                                        service.prices[0],
                                        service.discount[0]
                                      )
                                    : `IDR ${formater(
                                        parseFloat(service.prices[0].amount)
                                      )}`
                                  : '0'
                                : '0'
                            }`"
                            :sub_title_wrong="`${
                              service.discount
                                ? service.prices.length !== 0
                                  ? `IDR ${formater(
                                      parseFloat(service.prices[0].amount)
                                    )}`
                                  : ''
                                : ''
                            }`"
                            @detail="openWarn($t('branch_cannot_empty'))"
                            @addCart="item => addCart(item, null)"
                            @removeCart="item => removeCart(item, null)"
                            @openModalDeal="item => openModalDeal(item)"
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </template>
                <v-col v-else cols="12">
                  <div
                    class="d-flex flex-column align-center flex-sm-row justify-sm-center not-available text-center"
                  >
                    <img
                      :src="require('@/assets/images/banner/coming_soon.png')"
                    />
                    <p class="mb-0">
                      {{ $t("product_not_available_at_this_branch") }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </custom-section>
          </v-card>

          <!-- deal list disable -->
          <!-- <v-card
            class="mt-3 mt-md-5"
            v-if="filter.category == -2 && arrTestSubscribe.length > 0"
          >
            <custom-section>
              <v-row v-for="(deal, index) of listDeal" :key="index">
                <v-col
                  cols="12"
                  :class="{ 'py-0': index != 0, 'pb-0': index == 0 }"
                >
                  <v-row dense>
                    <v-col cols="12" md="6">
                      <h3 class="type-deal">{{ deal.name }}</h3>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-for="(product, index) of deal.available_packages"
                  :key="index"
                >
                  <card-deal
                    :active="checkItem(product, deal)"
                    :action="
                      branch != '' && branch != null && branch != undefined
                    "
                    :title="product.name"
                    :data="deal"
                    :paket="product.id"
                    :plant="branch != '' && branch != null ? branch : ''"
                    :disc="`IDR ${formater(product.amount_after_deal)}`"
                    :sub_title="`IDR ${formater(product.amount_before_deal)}`"
                    :desc="`${product.products.length} ${$t('test_covered')}`"
                    @detail="openWarn($t('branch_cannot_empty'))"
                    @addCart="item => addCart(item, product.id)"
                    @removeCart="item => removeCart(item, product.id)"
                  />
                </v-col>
              </v-row>
            </custom-section>
          </v-card> -->
          <DialogMenu
            :tampilkan="dialog"
            @toggleDialog="dialog => toggleDialogMenu(dialog)"
          />
        </template>
      </v-col>
    </v-row>
    <!-- dialog filter -->
    <v-dialog
      v-model="filterDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      style="z-index: 999"
    >
      <v-card>
        <v-toolbar dark color="#00a6b6">
          <v-toolbar-title>
            {{ $t("filter") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="filterDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <div class="pa-3 filter">
          <v-card class="mb-6 section filter__main">
            <custom-section>
              <v-row>
                <v-col cols="12" class="filter">
                  <v-autocomplete
                    v-model="branch"
                    :items="selectBranch"
                    item-value="id"
                    item-text="name"
                    hide-details
                    outlined
                    dense
                    color="#00a4b3"
                    prepend-inner-icon="mdi-home-modern"
                    id="category"
                    class="inp-text err-inp-text inp-elipse input-filter mb-3"
                    :placeholder="$t('field.branch')"
                    ref="autoBranch"
                  >
                  </v-autocomplete>

                  <h5 class="my-2 text-uppercase title-list-category">
                    {{ $t("category_package") }}
                    {{
                      `${
                        getTestItemHeader.length == 0
                          ? ""
                          : `(${getTestItemHeader.length})`
                      }`
                    }}
                  </h5>
                  <v-list nav class="list-category pa-0 text-uppercase">
                    <v-list-item-group v-model="filter.category" mandatory>
                      <v-list-item
                        active-class="category--active"
                        class="mb-1"
                        :value="-1"
                        :key="-1"
                      >
                        <v-list-item-content class="py-1">
                          <v-list-item-title>{{
                            $t("all_category")
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        active-class="category--active"
                        class="mb-1"
                        v-for="(category, i) in getTestItemHeader"
                        :key="i"
                        :value="i"
                      >
                        <v-list-item-content class="py-2 text-uppercase">
                          <v-list-item-title>{{
                            category.groupName
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider dark style="border-color: white"></v-divider>
                      <v-list-item
                        active-class="category--active"
                        class="my-1"
                        :value="-2"
                        :key="-2"
                      >
                        <v-list-item-content class="py-1 text-uppercase">
                          <v-list-item-title>{{
                            $t("subscription")
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-divider dark style="border-color: white"></v-divider>
                </v-col>
              </v-row>
            </custom-section>
          </v-card>
          <v-card>
            <custom-section>
              <v-row>
                <v-col cols="12">
                  <h5 class="mb-2 text-uppercase">
                    {{ $t("field.gender") }}
                  </h5>
                  <v-list nav class="pa-0">
                    <v-list-item-group mandatory v-model="filter.gender">
                      <v-list-item
                        class="list-height margin-item mb-1"
                        v-for="gender in genders"
                        :key="gender.id"
                        :value="gender.name.toLowerCase()"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action class="mr-2 my-1">
                            <v-icon color="#00a6b6" v-if="active">
                              mdi-radiobox-marked
                            </v-icon>
                            <v-icon v-else> mdi-radiobox-blank </v-icon>
                          </v-list-item-action>
                          <v-list-item-content class="py-1">
                            <v-list-item-title
                              class="text-uppercase title-item"
                              >{{
                                $fnc.replaceSpecialChars(
                                  gender[
                                    $i18n.locale == "en"
                                      ? `name`
                                      : `name_${$i18n.locale}`
                                  ]
                                )
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </custom-section>
          </v-card>
        </div>
      </v-card>
    </v-dialog>
    <!-- end dialog filter -->
    <!-- dialog confirmation -->
    <v-dialog v-model="dialogConfirmation" persistent max-width="290">
      <v-card class="pa-3">
        <div class="d-flex justify-center mb-3">
          <v-icon x-large color="yellow"> mdi-information-outline </v-icon>
        </div>
        <p class="text-center">
          {{ $t("some_product_not_available_in_this_branch") }}
        </p>
        <div class="d-flex justify-center">
          <c-button class="mx-2 py-2" rounded @click="continueAddToCart()">{{
            $t("continue")
          }}</c-button>
          <c-button
            class="mx-2 py-2"
            rounded
            outline
            @click="
              () => {
                dialogConfirmation = false;
                editedItem = {};
              }
            "
            >{{ $t("cancel") }}</c-button
          >
        </div>
      </v-card>
    </v-dialog>
    <!-- end dialog confirmation -->
    <!-- dialog detail deal -->
    <v-dialog v-model="dialogDetailDeal" max-width="400">
      <v-card class="pa-3">
        <card-detail-deal
          :active="checkItem(detailDeal)"
          :action="branch != '' && branch != null"
          :plant="branch != '' && branch != null ? branch : ''"
          :data="detailDeal"
          :title="detailDeal.name"
          :sub_title="`IDR ${
            detailDeal.prices != null && detailDeal.prices.length !== 0
              ? formater(detailDeal.prices[0].amount)
              : '0'
          }`"
          :desc="`${detailDeal.total_products} ${$t('test_covered')}`"
          :listDeal="listDeal"
          @addCart="(item, id) => addCart(item, id)"
          @removeCart="(item, id) => removeCart(item, id)"
        />
      </v-card>
    </v-dialog>
    <!-- end dialog detail -->
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import CardPackage from "@/components/v3/cards/card-package.vue";
import CardDetailDeal from "@/components/v3/cards/card-detail-deal.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service.js";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
// import CardDeal from "@/components/v3/cards/card-deal.vue";
import IconLoading from "@/components/elements/IconLoading.vue";
import CButton from "@/components/v2/button/index.vue";

export default {
  components: {
    CustomSection,
    DialogMenu,
    NavbarOne,
    CardPackage,
    // CardDeal,
    IconLoading,
    CButton,
    CardDetailDeal,
  },
  data: () => ({
    loading: true,
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    dialog: false,
    filterDialog: false,
    testBtn: "",
    packages_check: [],
    getCategory: [],
    selectBranch: [],
    getTestItem: [],
    checkFilter: false,
    active: false,
    isActive: true,
    categoryActive: "all",
    getTestItemHeaderSubscribe: [],
    getTestItemHeader: [],
    branch: "",
    paramBranchSLug: "",
    branchPar: "",
    getListPackage: [],
    listpackage: [],
    valid: true,
    groupPackage: [],
    groupPackageSubscribe: [],
    arrTest: [],
    dataFilterBranch: [],
    filter: {
      category: -1,
      gender: "all",
    },
    genders: [],
    userGender: "",
    dialogFilter: false,
    payload: {
      branch: "",
      dateSearch: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      serviceName: [],
    },
    dialogConfirmation: false,
    editedItem: {},
    // detail deal
    dialogDetailDeal: false,
    detailDeal: [],
    listDeal: null,
    // end detail deal
  }),
  computed: {
    dataProfile() {
      return this.$store.state.profileData;
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    this.getPlant();
    if (this.dataProfile) {
      this.getProfile();
    }
    this.getGender();
    if (this.$store.state.cart.length > 0) {
      if (this.branch == "" || this.branch == null) {
        this.branch = parseInt(this.$store.state.cart[0].plant.id);
      }
    }
    if (typeof this.$route.query.branch == "string") {
      this.payload.branch = parseInt(this.$route.query.branch);
    } else {
      this.getProduct();
    }
    this.$store.commit("setLoading", false);
    //this.removeCartz();
  },
  watch: {
    "filter.category": function () {
      this.filterPackages();
    },
    "filter.gender": function () {
      if (this.filter.gender == "all") {
        this.dialogFilter = false;
      } else {
        this.dialogFilter = true;
      }
      this.getProduct();
    },
    branch: function () {
      history.replaceState(null, null, `?branch=${this.branch}`);
      this.getProduct();
    },
  },
  methods: {
    // start calculate discount per item
    calculateDiscount(price, discount) {
      // if not percent
      if (discount.amount != 0) {
        const finalPrice =
          parseFloat(price.amount) - parseFloat(discount.amount);
        return finalPrice <= 0
          ? this.$t("free")
          : `IDR ${this.formater(finalPrice)}`;
      }
      // if percent
      else {
        const totalDiscount = Math.min(
          parseFloat(price.amount) * (discount.percent / 100),
          parseFloat(discount.amount_max)
        );
        const finalPrice = parseFloat(price.amount) - totalDiscount;
        return finalPrice <= 0
          ? this.$t("free")
          : `IDR ${this.formater(finalPrice)}`;
      }
    },
    // end calculate discount per item

    // async getListDeal() {
    //   try {
    //     this.loading = true;
    //     const resp = await API.get(
    //       `${process.env.VUE_APP_API_TRUST}deal/get?plant_id=${this.branch}&lang=${this.$i18n.locale}`,
    //       {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Auth.getToken()}`,
    //       }
    //     );
    //     if (resp.statusCode == 200) {
    //       this.listDeal = resp.results;
    //     } else {
    //       this.$swal({
    //         icon: "error",
    //         text: resp.message,
    //       });
    //     }
    //     this.loading = false;
    //   } catch (error) {
    //     this.loading = false;
    //     console.log("Error 403!");
    //   }
    // },

    // Get Branch
    async getPlant() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get `,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          this.selectBranch = resp.results;
        }
        if (this.$store.state.plantPackage != "") {
          this.branch = parseInt(this.$store.state.plantPackage);
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    // get product package
    async getProduct() {
      try {
        this.loading = true;
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}package/get?plant_id=${
            this.branch || ""
          }&gender=${this.filter.gender.toLowerCase()}&subscription=false`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        const respSub = await API.get(
          `${process.env.VUE_APP_API_TRUST}package/get?plant_id=${
            this.branch || ""
          }&gender=${this.filter.gender.toLowerCase()}&subscription=true`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );

        // package
        if (resp.statusCode === 200) {
          if (resp.results.length > 0) {
            let group = resp.results.reduce((r, a) => {
              r[a.category] = [...(r[a.category] || []), a];
              return r;
            }, {});
            this.groupPackage = Object.entries(group).map(arr => ({
              groupName: arr[0],
              groupDetail: arr[1],
            }));
            this.getTestItemHeader = this.groupPackage;
            this.filterPackages();
          } else {
            this.getTestItemHeader = [];
            this.groupPackage = [];
            this.filterPackages();
          }
        } else if (resp.statusCode == 400) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
            //text: this.$t("error_try_again_latter"),
          });
        }

        // subscribe
        if (respSub.statusCode === 200) {
          if (respSub.results.length > 0) {
            let group = respSub.results.reduce((r, a) => {
              r[a.category] = [...(r[a.category] || []), a];
              return r;
            }, {});
            this.groupPackageSubscribe = Object.entries(group).map(arr => ({
              groupName: arr[0],
              groupDetail: arr[1],
            }));
            this.getTestItemHeaderSubscribe = this.groupPackageSubscribe;
            this.filterPackages();
          } else {
            this.getTestItemHeaderSubscribe = [];
            this.groupPackageSubscribe = [];
            this.filterPackages();
          }
        } else if (respSub.statusCode == 400) {
          Object.keys(respSub.errors).forEach(element => {
            this.toast(
              "error",
              typeof respSub.errors[element] == "object"
                ? respSub.errors[element][0]
                : respSub.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: respSub.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
        this.loading = false;
      } catch (error) {
        console.log("Error 403!");
        this.loading = false;
      }
    },
    // get gender
    async getGender() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}gender/get`,
          {}
        );
        if (resp.statusCode == 200) {
          this.genders = [
            { code: "all", name: this.$t("all"), name_id: this.$t("all") },
            ...resp.results.sort((a, b) => this.sortByName(a, b)),
          ];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getProfile() {
      this.userGender = this.dataProfile.person.gender;
      this.filter.gender = this.userGender.name.toLowerCase();
      if (this.filter.gender.length == 1) {
        this.dialogFilter = true;
      }
    },
    // sorting
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    formater(value) {
      let val = (value / 1).toFixed(2).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    filterPackages() {
      this.arrTestSubscribe = this.groupPackageSubscribe;
      if (this.filter.category < 0) {
        this.arrTest = this.groupPackage;
      } else {
        this.arrTest = [this.groupPackage[this.filter.category]];
      }
    },
    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
    openWarn(message) {
      if (this.branch == "") {
        this.$swal({
          icon: "error",
          text: message,
        }).then(() => {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            this.$refs.autoBranchMobile.focus();
          } else {
            this.$refs.autoBranch.focus();
          }
        });
      }
    },
    linkBranch(message, event) {
      if (this.branch == "") {
        event.preventDefault();
        this.$swal({
          icon: "error",
          text: message,
        }).then(() => {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            this.$refs.autoBranchMobile.focus();
          } else {
            this.$refs.autoBranch.focus();
          }
        });
      }
    },
    addCartPackage(data) {
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.branch;
      } else {
        //jika ada param url branch tapi memilih branch lain di menu pilihan branch
        if (this.$route.query.branch === this.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.branch;
        }
      }

      const addCartTemp = {
        from_package: false,
        qty: 1,
        plant: {
          id: this.branchPar,
        },
        product: null,
        package: {
          id: data.id,
          name: data.name,
          prices: [
            {
              amount: data.prices[0].amount,
            },
          ],
        },
        deal: null,
      };

      const indexCartTest = this.$store.state.cart.find(
        res => res.package === null
      );
      if (typeof indexCartTest !== "undefined") {
        this.$swal({
          text: this.$t("not_have_deal_cart"),
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          showCancelButton: true,
          showCloseButton: true,
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.state.cart.forEach(el => {
              this.removeCartAllPackage(el);
            });
            this.addPackageNonSubs(addCartTemp);
          }
        });
      } else {
        const indexCartSubs = this.$store.state.cart.find(
          res => res.package.subscription === true
        );
        if (typeof indexCartSubs !== "undefined") {
          this.$swal({
            text: this.$t("not_have_deal_cart"),
            icon: "warning",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
            showCancelButton: true,
            showCloseButton: true,
          }).then(result => {
            if (result.isConfirmed) {
              this.$store.state.cart.forEach(el => {
                this.removeCartAllPackage(el);
              });
              this.addPackageNonSubs(addCartTemp);
            }
          });
        } else {
          if (this.$store.state.cart.length > 0) {
            this.$swal({
              text: this.$t("not_have_deal_cart"),
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: this.$t("yes"),
              cancelButtonText: this.$t("no"),
              showCancelButton: true,
              showCloseButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                this.$store.state.cart.forEach(el => {
                  this.removeCartAllPackage(el);
                });
                this.addPackageNonSubs(addCartTemp);
              }
            });
            // if (this.branchPar == this.$store.state.cart[0].plant.id) {
            //   this.addPackageNonSubs(addCartTemp)
            // }else{
            //   this.dialogConfirmation = true;
            //   this.editedItem = addCartTemp;
            // }
          } else {
            this.addPackageNonSubs(addCartTemp);
          }
        }
      }
    },
    addPackageNonSubs(data) {
      this.$store.commit("updateCart", [...this.$store.state.cart, data]);
      const dataReady = [
        {
          plant_id: data.plant.id,
          package_id: data.package.id,
          deal_id: null,
          from_package: false,
          product_id: null,
          qty: 1,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    addCart(data, product_id) {
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.branch;
      } else {
        //jika ada param url branch tapi memilih branch lain di menu pilihan branch
        if (this.$route.query.branch === this.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.branch;
        }
      }

      const checkCart = this.$store.state.cart.some(
        data => data.package != null && data.package.id == product_id
      );
      if (checkCart) {
        this.$store.state.cart.forEach(item => {
          if (item.package.id == product_id) {
            this.removeCartAllPackage(item);
          }
        });
      }
      var addCartTemp = {};
      if (data.from_deal) {
        // const indexAvailablePackage = data.available_packages.findIndex(
        //   res => res.id == product_id
        // );
        addCartTemp = {
          from_package: false,
          qty: 1,
          plant: {
            id: this.branch,
          },
          product: null,
          package: {
            id: product_id,
            name: data.name,
            prices: [
              {
                amount_before_deal: data.amount_before_deal,
                amount_after_deal: data.amount_after_deal,
              },
            ],
            product: data.products,
          },
          deal: {
            id: data.id,
            name: data.name,
            start_date: data.start_date,
            end_date: data.end_date,
            qty_item: data.qty_item,
            qty_bonus: data.qty_bonus,
          },
        };
      } else {
        addCartTemp = {
          from_package: false,
          qty: 1,
          plant: {
            id: this.branch,
          },
          product: null,
          package: {
            id: data.id,
            name: data.name,
            prices: [
              {
                amount: data.prices[0].amount,
              },
            ],
          },
          deal: null,
        };
      }

      const indexCartTest = this.$store.state.cart.find(
        res => res.package === null
      );
      if (typeof indexCartTest !== "undefined") {
        this.$swal({
          text: this.$t("not_have_deal_cart"),
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          showCancelButton: true,
          showCloseButton: true,
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.state.cart.forEach(el => {
              this.removeCartAllPackage(el);
            });
            this.addPackageDeal(addCartTemp);
          }
        });
      } else {
        const indexCartSubs = this.$store.state.cart.find(
          res => res.package.subscription === false
        );
        if (typeof indexCartSubs !== "undefined") {
          this.$swal({
            text: this.$t("not_have_deal_cart"),
            icon: "warning",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("yes"),
            cancelButtonText: this.$t("no"),
            showCancelButton: true,
            showCloseButton: true,
          }).then(result => {
            if (result.isConfirmed) {
              this.$store.state.cart.forEach(el => {
                this.removeCartAllPackage(el);
              });
              this.addPackageDeal(addCartTemp);
            }
          });
        } else {
          if (this.$store.state.cart.length > 0) {
            const indexCartNameDeal = this.$store.state.cart.find(
              res => res.package.name == data.name
            );
            if (typeof indexCartNameDeal !== "undefined") {
              this.removeCart(indexCartNameDeal.deal, data.id);
              this.addPackageDeal(addCartTemp);
            } else {
              if (typeof data.available_packages !== "undefined") {
                const availablePackages = data.available_packages.find(
                  res => res.id == product_id
                );
                const indexCartNamePackage = this.$store.state.cart.find(
                  res => res.package.name == availablePackages.name
                );
                if (typeof indexCartNamePackage !== "undefined") {
                  this.removeCart(indexCartNamePackage, product_id);
                  this.addPackageDeal(addCartTemp);
                } else {
                  if (this.branchPar == this.$store.state.cart[0].plant.id) {
                    this.addPackageDeal(addCartTemp);
                  } else {
                    this.dialogConfirmation = true;
                    this.editedItem = addCartTemp;
                  }
                }
              } else {
                if (this.branchPar == this.$store.state.cart[0].plant.id) {
                  this.addPackageDeal(addCartTemp);
                } else {
                  this.dialogConfirmation = true;
                  this.editedItem = addCartTemp;
                }
              }
            }
          } else {
            this.addPackageDeal(addCartTemp);
          }
        }
      }
    },
    addPackageDeal(data) {
      var deal_id = null;
      if (data.deal != null) {
        deal_id = data.deal.id;
      }

      this.$store.commit("updateCart", [...this.$store.state.cart, data]);
      const dataReady = [
        {
          plant_id: data.plant.id,
          package_id: data.package.id,
          deal_id: deal_id,
          from_package: false,
          product_id: null,
          qty: 1,
        },
      ];

      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    removeCartAllPackage(data) {
      var dataReady = [];
      if (data.deal === null && data.package === null) {
        dataReady = [
          {
            plant_id: data.plant.id,
            package_id: null,
            deal_id: null,
            from_package: false,
            product_id: data.product.id,
            qty: 0,
          },
        ];
      } else {
        if (data.deal === null) {
          dataReady = [
            {
              plant_id: data.plant.id,
              package_id: data.package.id,
              deal_id: null,
              from_package: false,
              product_id: null,
              qty: 0,
            },
          ];
        } else {
          dataReady = [
            {
              plant_id: data.plant.id,
              package_id: data.package.id,
              deal_id: data.deal.id,
              from_package: false,
              product_id: null,
              qty: 0,
            },
          ];
        }
      }
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        // ?lang=${this.$i18n.locale}
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        } else if (res.statusCode == 403) {
          Object.keys(res.errors).forEach(element => {
            this.toast(
              "error",
              typeof res.errors[element] == "object"
                ? res.errors[element][0]
                : res.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: res.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      });
    },
    removeCart(data, package_id) {
      var deal_id = null;
      var id_package = data.id;
      const cart = this.$store.state.cart;
      var indexCart = cart.findIndex(
        res => res.package.id == id_package && res.deal == null
      );
      if (package_id != null) {
        deal_id = data.id;
        id_package = package_id;
        indexCart = cart.findIndex(
          res => res.package.id == id_package && res.deal != null
        );
      }
      // remove before store
      cart.splice(indexCart, 1);
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.branch;
      } else {
        if (this.$route.query.branch === this.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.branch;
        }
      }
      const dataReady = [
        {
          plant_id: this.branchPar,
          package_id: id_package,
          deal_id: deal_id,
          from_package: false,
          product_id: null,
          qty: 0,
        },
      ];

      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    removePackageNonSubs(data) {
      const cart = this.$store.state.cart;
      const indexCart = cart.findIndex(res => res.package.id == data.id);
      cart.splice(indexCart, 1);
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.branch;
      } else {
        if (this.$route.query.branch === this.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.branch;
        }
      }
      const dataReady = [
        {
          plant_id: this.branchPar,
          package_id: data.id,
          deal_id: null,
          from_package: false,
          product_id: null,
          qty: 0,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    continueAddToCart() {
      if (this.editedItem.deal != null) {
        this.$store.state.cart.forEach(el => {
          this.removeCartAllPackage(el);
        });
        this.addPackageDeal(this.editedItem);
        this.dialogConfirmation = false;
      } else {
        this.$store.state.cart.forEach(el => {
          this.removeCartAllPackage(el);
        });
        this.addPackageNonSubs(this.editedItem);
        this.dialogConfirmation = false;
      }
    },
    async updateCartApi() {
      await this.$store.dispatch("fetchCartData");
    },
    checkItem(item, deal) {
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.branch;
      } else {
        if (this.$route.query.branch === this.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.branch;
        }
      }

      if (typeof item.subscription === "undefined") {
        if (
          this.$store.state.cart.length > 0 &&
          this.$store.state.cart[0].plant != undefined
        ) {
          if (this.branchPar == this.$store.state.cart[0].plant.id) {
            return this.$store.state.cart.findIndex(
              res =>
                res.package != null &&
                res.package.id == item.id &&
                res.deal != null &&
                res.deal.id == deal.id
            ) < 0
              ? false
              : true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        if (
          this.$store.state.cart.length > 0 &&
          this.$store.state.cart[0].plant != undefined
        ) {
          if (this.branchPar == this.$store.state.cart[0].plant.id) {
            return this.$store.state.cart.findIndex(
              res => res.package != null && res.package.id == item.id
            ) < 0
              ? false
              : true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    // start deal popup
    openModalDeal(item) {
      this.detailDeal = item;
      this.getListDeal();
      this.dialogDetailDeal = true;
    },
    async getListDeal() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}deal/get-detail?plant_id=${
            this.branch || ""
          }&package_id=${this.detailDeal.id}&subscription=true`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          this.listDeal = resp.results;
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
          });
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    // end deal popup
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
// start only desktop sticky
.filter--sticky {
  position: sticky;
  top: 20px;
}
// end only desktop sticky

.not-available {
  img {
    width: 100%;
    max-width: 300px;
  }
  h4,
  p {
    color: $dark_blue_2;
    font-size: 20px;
  }
}
// deal
.type-deal {
  color: white;
  font-size: 14px;
  text-transform: capitalize !important;
  background: $dark_blue_2;
  border-radius: 0 16px 16px 0;
  padding: 4px 12px;
  min-width: 50%;
  width: max-content;
}

.branch-selector {
  font-size: 12px;
  width: 162px;
}

@media screen and (max-width: 600px) {
  .btn-filter {
    width: 100% !important;
  }
  .branch-selector {
    width: 100% !important;
  }

  .text-field {
    margin-left: 0px !important;
    max-width: 85% !important;
  }

  .title-page {
    color: white;
    font-size: 14px;
    text-transform: capitalize !important;
    background: $dark_blue_2;
    border-radius: 0 16px 16px 0;
    padding: 4px 12px;
    min-width: 50%;
  }

  .cart-right {
    text-align: right;
    justify-content: flex-end;
    background: none;
    width: 95%;
    margin-top: -19px;
    margin-left: 10px;
  }

  .cart {
    margin-left: 14px !important;
    margin-right: 58px;
    margin-top: -18px !important;
    z-index: 1;
  }

  .bell {
    margin-left: 8px;
  }

  .user-card {
    margin-top: -42px;
    max-width: 60% !important;
    margin-left: 142px;
  }

  .card-top {
    width: 100%;
    left: 0;
    right: 0;
  }

  .notification {
    height: 55px;
    border-radius: 3px;
    margin-top: -15px;
    margin-left: -80px;
  }

  .row-card {
    margin-top: -40px;
  }
}

.my-package {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  a {
    text-decoration: none;
  }
  .h-full {
    height: 100%;
    border: 1px solid #777777;
  }
  &__section {
    padding: 0 20px;
  }
}
.title-page {
  color: white;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
  text-transform: capitalize !important;
  background: $dark_blue_2;
  border-radius: 0 16px 16px 0;
  padding: 4px 12px;
  min-width: 50%;
}

.text-field {
  max-width: 70%;
  margin-top: -3px;
}

.card-col {
  padding: 10px 20px;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: 105px;
}
.theme--light.v-btn {
  color: #00a4b3;
  // border-radius: 5px;
  font-weight: bold;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: transparent;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #00a4b3 !important;
}

// .v-btn:not(.v-btn--round).v-size--default {
//   height: 33px;
//   width: 162px;
//   padding: 0 16px;
//   text-transform: capitalize;
// }

.v-btn {
  border-left-style: 1;
}

.active {
  background-color: #00a4b3;
  color: white !important;
}

.btn-filter {
  font-size: 12px;
}

.btn-detail {
  border-radius: 5px !important;
  width: 100% !important;
  background-color: #00a4b3 !important;
  color: white !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.btn-disabled {
  border-radius: 5px !important;
  width: 100% !important;
  background-color: grey !important;
  color: white !important;
  text-transform: capitalize !important;
  font-weight: normal !important;
  border-radius: 4px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}

.button {
  font-weight: normal !important;
  border-radius: 4px;
  background-color: #00a4b3 !important;
  color: white !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
}
.card--shadow {
  border: thin solid rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}
.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  margin-top: -20px;
  float: right;
}
// custom filter
.filter {
  h5 {
    font-size: 14px;
  }
  .category--active {
    background: white;
  }
  .inp-text {
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 12px !important;
  }
  &__main {
    background: #00a4b3;
    .title-list-category {
      color: white;
    }
    .list-category {
      background: #00a4b3;
      color: white;
    }
  }
  .title-item {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/color.scss";

// custom filter
.filter {
  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    min-height: 36px !important;
  }

  .branch-mobile.v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    width: 200px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .v-list-item,
  .v-list .v-list-item {
    min-height: 25px;
  }
  .v-list-item .v-list-item__title {
    font-size: 12px;
  }
  .category--active .v-list-item__content .v-list-item__title {
    font-weight: 600;
    color: $gray_4;
  }
  .theme--light.v-list-item--active::before {
    opacity: 0;
  }
  &__main {
    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: white;
    }
  }
  .list-height {
    min-height: 18px !important;
  }
  .margin-item {
    height: 18px;
  }
  .radio-size {
    font-size: 20px;
    .v-input__control
      .v-input__slot
      .v-input--selection-controls__input
      .v-icon {
      font-size: 20px !important;
    }
  }
}
.inp-elipse
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > input[type="text"] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
}
</style>
