<template>
  <v-card class="card card-hc flex-column flex-sm-row">
    <div class="card__body px-3 pa-2">
      <h5 class="card__title">{{ title }}</h5>
      <h6 class="card__sub-title">
        {{ sub_title }}
      </h6>
      <p class="card__desc">
        {{ desc }}
      </p>
    </div>
    <div class="d-flex">
      <div
        class="card__action card__action--remove pa-1 pa-sm-3"
        @click="$emit('deleteRow', index)"
      >
        <v-icon color="#e1e8f5"> mdi-delete </v-icon>
      </div>
      <div
        class="card__action card__action--edit pa-1 pa-sm-3"
        @click="$emit('editRow', index)"
      >
        <v-icon color="#e1e8f5"> mdi-pencil-box </v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "-",
    },
    sub_title: {
      type: String,
      default: "-",
    },
    desc: {
      type: String,
      default: "-",
    },
    index: {
      type: Number,
      default: -1,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.card {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  height: 100%;
  &__action {
    border-radius: 0 !important;
    cursor: pointer;
    flex-grow: 1;
    justify-content: center;
    &:hover {
      opacity: 0.9;
    }
  }
  &__action--edit {
    display: flex;
    background: $yellow;
  }
  &__action--remove {
    display: flex;
    background: $red;
  }
  &__title {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $dark_blue_3;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__sub-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $main_1;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  &__desc {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: $gray_4;
  }
}
</style>
