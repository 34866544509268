<template>
  <div class="my-package pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>

    <!-- </section> -->
    <v-card class="ma-md-5 mr-mobile">
      <custom-section class="section">
        <v-alert
          dense
          type="warning"
          color="#FF5457"
          icon="mdi-alert-outline"
          v-if="this.payload.serviceName.length > 0"
        >
          {{ $t("showing_lab_for_search") }}
        </v-alert>
        <v-alert
          dense
          type="warning"
          color="#FF5457"
          icon="mdi-alert-outline"
          v-if="dialogFilter && this.payload.serviceName == 0"
        >
          {{ $t("showing_lab_for") }} {{ $t("u_can_modif_filter") }}
        </v-alert>
        <v-row dense class="mb-2 ma-n1 ma-md-n2">
          <v-col cols="12" md="3" class="pr-card pa-1 pa-md-2 filter">
            <template>
              <div class="filter--sticky">
                <v-form v-model="valid" lazy-validation ref="form">
                  <div class="card-search pa-3">
                    <p class="branch-title">{{ $t("branch") }}</p>
                    <v-row no-gutters>
                      <v-autocomplete
                        v-model="payload.branch"
                        :items="listBranch"
                        item-value="id"
                        item-text="name"
                        class="inp-text err-inp-text inp-elipse input-filter inp-filter-test"
                        rounded
                        dense
                        hide-details
                        prepend-icon="mdi-home-modern"
                        append-icon=""
                        ref="autoBranch"
                      ></v-autocomplete>
                    </v-row>

                    <p class="branch-title">{{ $t("date") }}</p>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :close-on-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="inp-text input-filter inp-filter-test"
                              v-model="dateFormatted"
                              clearable
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              rounded
                              dense
                              hide-details
                            ></v-text-field>
                          </template>
                          <c-datepicker
                            :plant="payload.branch"
                            checkQueue
                            :minDate="minDate"
                            :value="payload.dateSearch"
                            :holiday="getHoliday"
                            :enableDays="getEnableDays"
                            @changeValue="payload.dateSearch = $event"
                            @change="menu = false"
                          ></c-datepicker>
                        </v-menu>
                      </v-col>
                      <v-spacer></v-spacer>
                    </v-row>

                    <p class="branch-title">
                      {{ $t("service_name") }}
                    </p>
                    <v-row no-gutters>
                      <v-col>
                        <v-autocomplete
                          v-model="payload.serviceName"
                          :items="listService"
                          :loading="loadingSearch"
                          :search-input.sync="searchServiceName"
                          multiple
                          item-value="id"
                          :item-text="
                            $i18n.locale == 'id'
                              ? 'name'
                              : `name_${$i18n.locale}`
                          "
                          class="inp-text input-filter service-search"
                          rounded
                          dense
                          prepend-icon="mdi-magnify"
                          append-icon=""
                          default=""
                          hide-details
                          no-data-text="Test belum tersedia"
                          ref="serviceName"
                        >
                          <template v-slot:selection="{ index }">
                            <span
                              v-if="index < 1"
                              class="grey--text text-caption"
                              >{{ payload.serviceName.length }}
                              {{ $t("service_selected") }}</span
                            >
                          </template>
                          <template v-slot:item="{ item }">
                            <div class="d-flex align-center">
                              <v-checkbox
                                :value="isSelected(item)"
                                @input="toggleSelection(item)"
                                dense
                                hide-details
                                color="#00a6b6"
                                class="pa-0 ma-0"
                              >
                                <template v-slot:label>
                                  <span class="text--black">
                                    {{
                                      $i18n.locale == "id"
                                        ? item.name
                                        : item[`name_${$i18n.locale}`]
                                    }}
                                  </span>
                                </template>
                              </v-checkbox>
                            </div>
                          </template>
                          <template
                            v-slot:prepend-item
                            v-if="payload.serviceName.length > 0"
                          >
                            <div
                              class="service--close px-5 py-2"
                              @click="payload.serviceName = []"
                            >
                              <span>{{ $t("uncheck_all") }}</span>
                              <v-icon small color="white" class="ml-1">
                                mdi-close-box-outline
                              </v-icon>
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>

                <div class="card-popular mt-3 mt-md-5">
                  <v-row dense>
                    <v-col>
                      <p class="filter-title">
                        {{ $t("page.lab_test.filter_by") }}
                      </p>
                    </v-col>
                    <v-col cols="3" align="right">
                      <p
                        class="popular-detail crs-check mb-0"
                        @click="resetCheck()"
                      >
                        {{ $t("reset") }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-1"></v-divider>

                  <!-- desktop -->
                  <div class="d-none d-md-block test-filter">
                    <v-expansion-panels accordion v-model="filterActive">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <p class="filter-title">
                            {{ $t("page.lab_test.gender") }}
                          </p>
                          <template v-slot:actions>
                            <v-icon color="primary"> mdi-menu-down </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mrt5">
                          <v-list-item-group mandatory v-model="filter.gender">
                            <template v-for="(item, i) in gender">
                              <v-divider
                                v-if="!item.name.toLowerCase()"
                                :key="`divider-${i}`"
                              ></v-divider>

                              <v-list-item
                                v-else
                                :key="`item-${i}`"
                                :value="item.name.toLowerCase()"
                                class="list-height margin-item"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action class="mr-1 my-1">
                                    <v-icon
                                      color="#00a6b6"
                                      v-if="active"
                                      class="radio-size padding-left-radio"
                                    >
                                      mdi-radiobox-marked
                                    </v-icon>
                                    <v-icon
                                      class="radio-size padding-left-radio"
                                      v-else
                                    >
                                      mdi-radiobox-blank
                                    </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title class="title-item">{{
                                      $fnc.replaceSpecialChars(
                                        item[
                                          $i18n.locale == "en"
                                            ? `name`
                                            : `name_${$i18n.locale}`
                                        ]
                                      )
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-divider class="mrt7"></v-divider>
                    <v-expansion-panels accordion style="display: none">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <p class="filter-title">
                            {{ $t("page.lab_test.category") }}
                          </p>
                          <template v-slot:actions>
                            <v-icon color="primary"> mdi-menu-down </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list-item-group v-model="filter.category" multiple>
                            <template v-for="(item, i) in groupCategory">
                              <v-divider
                                v-if="!item.sub_category_name_id"
                                :key="`divider-${i}`"
                              ></v-divider>

                              <v-list-item
                                v-else
                                :key="`item-${i}`"
                                :value="item.id"
                                class="list-height margin-item"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action class="mr-1 my-1">
                                    <v-checkbox
                                      :input-value="active"
                                      color="#00a6b6"
                                      class="radio-size"
                                    ></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title class="title-item">
                                      {{
                                        item[
                                          `sub_category_name_${$i18n.locale}`
                                        ]
                                      }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-divider class="mrt7"></v-divider>
                    <v-expansion-panels accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <p class="filter-title">
                            {{ $t("page.lab_test.category") }}
                          </p>
                          <template v-slot:actions>
                            <v-icon color="primary"> mdi-menu-down </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="sub-group-item">
                          <v-expansion-panels>
                            <v-expansion-panel
                              v-for="(item, i) in groupCategory"
                              :key="i"
                            >
                              <v-expansion-panel-header>
                                <template v-slot:actions>
                                  <v-icon color="primary" class="icon-sub">
                                    mdi-menu-down
                                  </v-icon>
                                </template>
                                <p
                                  class="filter-title-sub header-sub"
                                  style="font-weight: 500px !important"
                                >
                                  {{ item[`name_${$i18n.locale}`] }}
                                </p>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list-item-group
                                  v-model="filter.category"
                                  multiple
                                >
                                  <template
                                    v-for="(item_sub, i) in item.sub_categories"
                                  >
                                    <v-divider
                                      v-if="!item_sub.name_id"
                                      :key="`divider-${i}`"
                                    ></v-divider>

                                    <v-list-item
                                      v-else
                                      :key="`item-${i}`"
                                      :value="item_sub.id"
                                      class="list-height margin-item listbox-sub"
                                    >
                                      <template v-slot:default="{ active }">
                                        <v-list-item-action class="mr-1 my-1">
                                          <v-checkbox
                                            :input-value="active"
                                            color="#00a6b6"
                                            class="radio-size"
                                          ></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title class="title-item">
                                            {{
                                              item_sub[`name_${$i18n.locale}`]
                                            }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-divider class="mrt7"></v-divider>
                    <v-expansion-panels accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="mg-symptom">
                          <p class="filter-title">
                            {{ $t("page.lab_test.symptom") }}
                          </p>
                          <template v-slot:actions>
                            <v-icon color="primary"> mdi-menu-down </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list-item-group v-model="filter.symptoms" multiple>
                            <template v-for="(item, i) in symptoms">
                              <v-divider
                                v-if="!item"
                                :key="`divider-${i}`"
                              ></v-divider>

                              <v-list-item
                                v-else
                                :key="`item-${i}`"
                                :value="item.id"
                                class="list-height margin-item"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action class="mr-1 my-1">
                                    <v-checkbox
                                      :input-value="active"
                                      color="#00a6b6"
                                      class="radio-size"
                                    ></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title class="title-item">{{
                                      item[`name_${$i18n.locale}`]
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>

                  <!-- mobile -->
                  <div class="d-block d-md-none test-filter">
                    <v-expansion-panels accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <p class="popular-title px-1">
                            {{ $t("page.lab_test.gender") }}
                          </p>
                          <template v-slot:actions>
                            <v-icon color="primary"> mdi-menu-down </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list-item-group mandatory v-model="filter.gender">
                            <template v-for="(item, i) in gender">
                              <v-divider
                                v-if="!item.name.toLowerCase()"
                                :key="`divider-${i}`"
                              ></v-divider>

                              <v-list-item
                                v-else
                                :key="`item-${i}`"
                                :value="item.name.toLowerCase()"
                                class="list-height margin-item"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action class="mr-2 my-1">
                                    <v-icon color="#00a6b6" v-if="active">
                                      mdi-radiobox-marked
                                    </v-icon>
                                    <v-icon v-else> mdi-radiobox-blank </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title class="popular-title">{{
                                      $fnc.replaceSpecialChars(
                                        item[
                                          $i18n.locale == "en"
                                            ? `name`
                                            : `name_${$i18n.locale}`
                                        ]
                                      )
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-divider></v-divider>
                    <v-expansion-panels accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <p class="popular-title px-1 mt-3">
                            {{ $t("page.lab_test.category") }}
                          </p>
                          <template v-slot:actions>
                            <v-icon color="primary"> mdi-menu-down </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="sub-group-item">
                          <v-expansion-panels>
                            <v-expansion-panel
                              v-for="(item, i) in groupCategory"
                              :key="i"
                            >
                              <v-expansion-panel-header>
                                <template v-slot:actions>
                                  <v-icon color="primary" class="icon-sub">
                                    mdi-menu-down
                                  </v-icon>
                                </template>
                                <p
                                  class="filter-title-sub header-sub"
                                  style="font-weight: 500px !important"
                                >
                                  {{ item[`name_${$i18n.locale}`] }}
                                </p>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list-item-group
                                  v-model="filter.category"
                                  multiple
                                >
                                  <template
                                    v-for="(item_sub, i) in item.sub_categories"
                                  >
                                    <v-divider
                                      v-if="!item_sub.name_id"
                                      :key="`divider-${i}`"
                                    ></v-divider>

                                    <v-list-item
                                      v-else
                                      :key="`item-${i}`"
                                      :value="item_sub.id"
                                      class="list-height margin-item listbox-sub"
                                    >
                                      <template v-slot:default="{ active }">
                                        <v-list-item-action class="mr-1 my-1">
                                          <v-checkbox
                                            :input-value="active"
                                            color="#00a6b6"
                                            class="radio-size"
                                          ></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title class="title-item">
                                            {{
                                              item_sub[`name_${$i18n.locale}`]
                                            }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-divider></v-divider>
                    <v-expansion-panels accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="mg-symptom">
                          <p class="popular-title px-1 mt-3">
                            {{ $t("page.lab_test.symptom") }}
                          </p>
                          <template v-slot:actions>
                            <v-icon color="primary"> mdi-menu-down </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="mt-3">
                          <v-list-item-group v-model="filter.symptoms" multiple>
                            <template v-for="(item, i) in symptoms">
                              <v-divider
                                v-if="!item"
                                :key="`divider-${i}`"
                              ></v-divider>

                              <v-list-item
                                v-else
                                :key="`item-${i}`"
                                :value="item.keyword"
                                class="list-height margin-item"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action class="mr-2 my-1">
                                    <v-checkbox
                                      :input-value="active"
                                      color="#00a6b6"
                                    ></v-checkbox>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title class="popular-title">{{
                                      item[`name_${$i18n.locale}`]
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <!-- end mobile -->
                </div>
              </div>
            </template>
          </v-col>

          <v-col cols="12" md="9" class="pa-1 pa-md-2">
            <v-row dense class="mx-n2 mx-md-n1">
              <template v-if="listTest.length > 0">
                <!-- Senemtara disabled selain bari dan nrc -->
                <!-- <template
                    v-if="
                      payload.branch == 4 ||
                      payload.branch == 12 ||
                      payload.branch == '' ||
                      payload.branch == null
                    "
                  > -->
                <v-col
                  cols="12"
                  md="6"
                  sm="6"
                  v-for="(test, index) in listTest"
                  :key="index"
                  class="px-2 pb-0"
                  :class="{
                    'pt-3 pt-md-5': index > 1,
                    'pt-3 pt-md-1': index <= 1,
                  }"
                >
                  <!-- v3 card test-->
                  <card-test
                    :active="checkItem(test)"
                    :action="payload.branch != '' && payload.branch != null"
                    :plant="
                      payload.branch != '' && payload.branch != null
                        ? payload.branch
                        : ''
                    "
                    :data="test"
                    :title="
                      test[
                        $i18n.locale == 'id' ? `name` : `name_${$i18n.locale}`
                      ]
                    "
                    :sub_title="`${
                      test.prices != undefined
                        ? test.prices.length !== 0
                          ? test.discount
                            ? calculateDiscount(
                                test.prices[0],
                                test.discount[0]
                              )
                            : `IDR ${formater(test.prices[0].amount)}.00`
                          : '0'
                        : '0'
                    }`"
                    :sub_title_wrong="`${
                      test.discount
                        ? test.prices.length !== 0
                          ? `IDR ${formater(test.prices[0].amount)}.00`
                          : ''
                        : ''
                    }`"
                    :desc="test[`description_${$i18n.locale}`]"
                    @addCart="item => addCart(item)"
                    @removeCart="item => removeCart(item)"
                  />
                  <!-- <div class="card-popular pa-3">
                      <div class="d-flex">
                        <div class="d-none d-sm-block mt-n2">
                          <icons
                            icon="icon-microscope-lg"
                            v-if="test.category == 'HEMATOLOGI'"
                          />
                          <icons
                            icon="icon-blood-lg"
                            v-else-if="test.category == 'KIMIA KLINIK'"
                          />
                          <icons
                            icon="icon-heart-lg"
                            v-else-if="test.category == 'IMUNOLOGI'"
                          />
                          <icons icon="icon-microscope-lg" v-else />
                        </div>

                        <div>
                          <localized-link
                            :to="`/patient/test/detail?branch=${paramBranchSLug}&product=${test.id}`"
                          >
                            <p
                              class="popular-title-card"
                              v-if="
                                test[
                                  $i18n.locale == 'id'
                                    ? `name`
                                    : `name_${$i18n.locale}`
                                ].length > 22
                              "
                            >
                              {{
                                test[
                                  $i18n.locale == "id"
                                    ? `name`
                                    : `name_${$i18n.locale}`
                                ].substring(0, 22)
                              }}...
                            </p>
                            <p class="popular-title-card" v-else>
                              {{
                                test[
                                  $i18n.locale == "id"
                                    ? `name`
                                    : `name_${$i18n.locale}`
                                ]
                              }}
                            </p>
                          </localized-link>
                          <p class="popular-price" v-if="test.prices">
                            IDR
                            {{
                              test.prices.length !== 0
                                ? formater(test.prices[0].amount)
                                : "0"
                            }}.00
                          </p>

                          <p class="popular-detail">
                            {{ test[`description_${$i18n.locale}`] }}
                          </p>
                        </div>
                      </div>
                      <div v-if="test.prices" class="footer-class">
                        <v-row class="row mb-4">
                          <v-col class="text-left d-btn-show" cols="6">
                            <localized-link
                              :to="`/patient/test/detail?branch=${paramBranchSLug}&product=${test.id}`"
                            >
                              <v-btn class="button" outlined rounded text>
                                {{ $t("detail") }}
                              </v-btn>
                            </localized-link>
                          </v-col>
                          <v-col class="text-right m-btn-show" cols="6">
                            <v-btn
                              class="button"
                              outlined
                              rounded
                              text
                              @click="addCart(test)"
                              v-if="!checkItem(test)"
                            >
                              {{ $t("page.lab_test.add_to_cart") }}
                            </v-btn>

                            <v-btn
                              class="button remove"
                              outlined
                              rounded
                              text
                              @click="removeCart(test)"
                              v-if="checkItem(test)"
                            >
                              {{ $t("page.lab_test.cancel") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                    </div> -->
                </v-col>
                <!-- </template>
                  <template v-else>
                    <v-col cols="12">
                      <coming-soon />
                    </v-col>
                  </template> -->
              </template>
              <template v-else>
                <div class="mx-auto mt-3 comming-soon">
                  <img
                    :src="require('@/assets/images/banner/coming_soon.png')"
                  />
                  <h4 class="mt-2 text-center">
                    {{ $t("services_coming_soon") }}
                  </h4>
                </div>
              </template>
              <v-col
                cols="12"
                v-if="
                  pageProduct <= Math.ceil(totalPageProduct / perPageProduct)
                "
              >
                <div class="d-flex mt-4">
                  <icon-loading v-if="loading" class="icon-loading mx-auto" />
                  <c-button
                    class="mx-auto"
                    rounded
                    outline
                    v-else
                    @click="getProduct()"
                    >{{ $t("load_more") }}</c-button
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <DialogMenu
          :tampilkan="dialog"
          @toggleDialog="dialog => toggleDialogMenu(dialog)"
        />
        <v-dialog v-model="dialogConfirmation" persistent max-width="290">
          <v-card class="pa-3">
            <div class="d-flex justify-center mb-3">
              <v-icon x-large color="yellow"> mdi-information-outline </v-icon>
            </div>
            <p class="text-center">
              {{ $t("some_product_not_available_in_this_branch") }}
            </p>
            <div class="d-flex justify-center">
              <c-button
                class="mx-2 py-2"
                rounded
                @click="continueAddToCart()"
                >{{ $t("continue") }}</c-button
              >
              <c-button
                class="mx-2 py-2"
                rounded
                outline
                @click="
                  () => {
                    dialogConfirmation = false;
                    editedItem = {};
                  }
                "
                >{{ $t("cancel") }}</c-button
              >
            </div>
          </v-card>
        </v-dialog>
      </custom-section>
    </v-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import CustomSection from "@/components/v2/section/index.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service.js";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import CButton from "@/components/v2/button/index.vue";
import IconLoading from "@/components/elements/IconLoading.vue";
import CardTest from "@/components/v3/cards/card-test.vue";
import CDatepicker from "@/components/v3/date/custom-datepicker.vue";

export default {
  components: {
    CustomSection,
    DialogMenu,
    NavbarOne,
    CButton,
    IconLoading,
    CardTest,
    CDatepicker,
  },
  data: () => ({
    pageProduct: 1,
    perPageProduct: 30,
    totalPageProduct: 0,
    filterActive: 0,
    filter: { gender: "all", category: [], symptoms: [], serviceName: "" },
    listProduct: [],
    editedItem: {},
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    dialog: false,
    dialogConfirmation: false,
    dialogFilter: false,
    dialogSearch: false,
    listTest: [],
    getListTest: [],
    groupCategory: [],
    listCategory: [],
    modal_datePicker: false,
    datePicker: null,
    serviceModel: [],
    services: [
      "Pemeriksaan Medis",
      "Biomolekuler",
      "Hematologi",
      "Imunologi",
      "Kimia",
      "Mikrobiologi",
      "Patologi Anatomi",
      "Radiologi",
      "Toksikologi",
    ],
    listBranch: [],
    listService: [],
    symptoms: {},
    category: [
      { name: "Headache", id: 1 },
      { name: "Fever", id: 2 },
      { name: "Sore Throat", id: 3 },
      { name: "Cough", id: 4 },
      { name: "Eye Sore", id: 5 },
      { name: "Breathing Difficulty", id: 6 },
    ],
    payload: {
      branch: "",
      dateSearch: "",
      serviceName: [],
    },
    valid: true,
    paramBranchSLug: "",
    testTo: "",
    menu: false,
    getParBranch: "",
    getParDate: "",
    getParCategory: "",
    listFilterCategory: [],
    dataMyPackage: [],
    mypackage: false,
    service: { prices: [] },
    branchPar: "",
    gender: [],
    userGender: "",
    loading: true,
    serviceSelected: {},
    // start available date
    availableDates: [],
    // end available date
    loadingSearch: false,
    searchServiceName: "",
  }),
  watch: {
    "payload.branch": async function () {
      history.replaceState(
        null,
        null,
        `?branch=${this.payload.branch || ""}&date=${
          this.payload.dateSearch || ""
        }&category=${this.filter.category}&symptoms=${
          this.filter.symptoms.length == 0 ? "" : this.filter.symptoms.join()
        }`
      );
      this.pageProduct = 1;
      this.getProduct();
      const minDate = this.minDate; // Replace with your minimum date or leave as null
      const maxDate = null; // Replace with your maximum date or leave as null
      this.payload.dateSearch = this.findClosestValidDate(minDate, maxDate);
    },
    searchServiceName: function () {
      this.getService();
    },
    "payload.serviceName": function () {
      this.pageProduct = 1;
      this.getProduct();
    },
    "payload.dateSearch": function (val) {
      this.$store.state.date = val;
      history.replaceState(
        null,
        null,
        `?branch=${this.payload.branch || ""}&date=${
          val || moment().format("YYYY-MM-DD")
        }&category=${this.filter.category}&symptoms=${
          this.filter.symptoms.length == 0 ? "" : this.filter.symptoms.join()
        }`
      );
    },
    filter: {
      async handler() {
        this.pageProduct = 1;
        if (this.loading) {
          return;
        }
        this.loading = true;
        try {
          history.replaceState(
            null,
            null,
            `?branch=${this.payload.branch}&date=${
              this.payload.dateSearch
            }&category=${
              this.filter.category.length == 0
                ? ""
                : this.filter.category.join()
            }&symptoms=${
              this.filter.symptoms.length == 0
                ? ""
                : this.filter.symptoms.join()
            }`
          );
          this.pageProduct = 1;
          await this.getProduct();
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      },
      deep: true,
    },
  },
  async created() {
    this.getProduct();
    await this.getBranch();
    this.getService();
    this.getGender();
    this.getCategory();
    this.getSymptoms();
    if (this.dataProfile) {
      this.getProfile();
    }
    //this.getCategoryOld();
    if (this.$store.state.cart.length > 0) {
      if (this.payload.branch == "" || this.payload.branch == null) {
        this.payload.branch = parseInt(this.$store.state.cart[0].plant.id);
        this.getParBranch = this.$store.state.cart[0].plant.id;
        this.paramBranchSLug = this.$store.state.cart[0].plant.id;
      }
    }

    if (typeof this.$route.query.branch == "string") {
      this.payload.branch = parseInt(this.$route.query.branch);
    }

    if (typeof this.$route.query.category == "string") {
      const category = [...this.$route.query.category.split(",")];
      this.filter.category =
        this.$route.query.category == ""
          ? []
          : category.map(res => {
              return Number(res);
            });
    }
    const dateSearch = moment(this.$route.query.date);
    if (this.$route.query.date != undefined && dateSearch.isValid()) {
      this.payload.dateSearch = dateSearch.format("YYYY-MM-DD");
    } else {
      this.payload.dateSearch = this.minDate;
    }
  },
  computed: {
    // start holiday
    getHoliday() {
      const indexPlant = this.listBranch.findIndex(
        res => res.id == this.payload.branch
      );
      if (indexPlant > -1) {
        return this.listBranch[indexPlant].upcoming_holidays;
      }
      return [];
    },
    // end holiday

    // start enable days
    getEnableDays() {
      const indexPlant = this.listBranch.findIndex(
        res => res.id == this.payload.branch
      );
      let enableDays = [];
      if (indexPlant > -1) {
        const days = [
          "Minggu",
          "Senin",
          "Selasa",
          "Rabu",
          "Kamis",
          "Jumat",
          "Sabtu",
        ];
        const { opening_hours } = this.listBranch[indexPlant];
        for (let i = 0; i < days.length; i++) {
          const indexDays = opening_hours.findIndex(
            item => item.day == days[i]
          );
          if (indexDays > -1) {
            enableDays.push(i);
          }
        }
      }
      return enableDays;
    },
    // end enable days

    dateFormatted() {
      return this.formatDate(this.payload.dateSearch);
    },
    dataProfile() {
      return this.$store.state.profileData;
    },
    minDate() {
      // Set the start and end time boundaries
      let currentTime = moment();
      const indexPlant = this.listBranch.findIndex(
        res => res.id == this.payload.branch
      );
      // check timezone time
      if (this.payload.branch != null && this.payload.branch != "") {
        if (indexPlant > -1) {
          const timezone = moment.tz.guess();
          if (this.listBranch[indexPlant].timezone_area == timezone) {
            currentTime = moment();
          } else {
            const now = moment().format("HH:mm:ss");
            currentTime = moment(
              this.convertTime(
                now,
                moment.tz.guess(),
                this.listBranch[indexPlant].timezone_area
              ),
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        }
      }
      let startTime = "";
      let endTime = "";
      if (indexPlant > -1) {
        const { opening_hours } = this.listBranch[indexPlant];
        const indexByDayName = opening_hours.findIndex(
          item => item.day == currentTime.locale("ID").format("dddd")
        );
        if (indexByDayName > -1) {
          const close = opening_hours[indexByDayName].hours
            .split("-")[1]
            .replace(":00", "");
          startTime = moment()
            .hour(0) // mulai jam 00:00
            .minute(0)
            .second(0);
          endTime = moment().hour(close).minute(0).second(0);
        }
      }
      // Check if the current time is between the start and end time
      const isWithinTimeRange =
        indexPlant > -1 ? currentTime.isBetween(startTime, endTime) : true;

      // Output the result
      let add = 0;
      while (
        add < 10 &&
        this.getHoliday.some(
          data =>
            data.date ==
            currentTime.clone().add(add, "days").format("YYYY-MM-DD")
        )
      ) {
        add++;
      }
      if (!isWithinTimeRange) {
        return moment()
          .add(add + 1, "days")
          .format("YYYY-MM-DD");
      } else {
        return moment().add(add, "days").format("YYYY-MM-DD");
      }
    },
  },
  methods: {
    // start calculate discount per item
    calculateDiscount(price, discount) {
      // if not percent
      if (discount.amount != 0) {
        const finalPrice =
          parseFloat(price.amount) - parseFloat(discount.amount);
        return finalPrice <= 0
          ? this.$t("free")
          : `IDR ${this.formater(finalPrice)}.00`;
      }
      // if percent
      else {
        const totalDiscount = Math.min(
          parseFloat(price.amount) * (discount.percent / 100),
          parseFloat(discount.amount_max)
        );
        const finalPrice = parseFloat(price.amount) - totalDiscount;
        return finalPrice <= 0
          ? this.$t("free")
          : `IDR ${this.formater(finalPrice)}.00`;
      }
    },
    // end calculate discount per item
    // start search item
    isSelected(item) {
      return this.payload.serviceName.some(id => id === item.id);
    },
    toggleSelection(item) {
      const index = this.payload.serviceName.findIndex(id => id === item.id);
      if (index === -1) {
        this.payload.serviceName.push(item.id);
      } else {
        this.payload.serviceName.splice(index, 1);
      }
    },
    // end search item

    // start near date
    findClosestValidDate(minDate, maxDate) {
      const startDate = moment(this.payload.dateSearch);
      const getEnableDays = this.getEnableDays;
      // Exclude the holiday on August 17th, 2023 (which is a Thursday)
      // getEnableDays.push(moment("2023-08-17").day());

      // return same day when enable
      if (
        getEnableDays.includes(startDate.day()) &&
        (!minDate || startDate.isSameOrAfter(minDate)) &&
        (!maxDate || startDate.isSameOrBefore(maxDate))
      ) {
        return startDate.format("YYYY-MM-DD");
      }

      let closestDateBefore = null;
      let closestDateAfter = null;
      let daysDifferenceBefore = Infinity;
      let daysDifferenceAfter = Infinity;

      for (let daysToAdd = 1; daysToAdd <= 7; daysToAdd++) {
        const candidateDateBefore = startDate
          .clone()
          .subtract(daysToAdd, "days");
        const candidateDateAfter = startDate.clone().add(daysToAdd, "days");

        const isAfterMinDateBefore =
          !minDate || candidateDateBefore.isSameOrAfter(minDate);
        const isBeforeMaxDateBefore =
          !maxDate || candidateDateBefore.isSameOrBefore(maxDate);

        if (
          getEnableDays.includes(candidateDateBefore.day()) &&
          isAfterMinDateBefore &&
          isBeforeMaxDateBefore
        ) {
          const currentDifference = Math.abs(
            candidateDateBefore.diff(startDate, "days")
          );
          if (currentDifference < daysDifferenceBefore) {
            closestDateBefore = candidateDateBefore;
            daysDifferenceBefore = currentDifference;
          }
        }

        const isAfterMinDateAfter =
          !minDate || candidateDateAfter.isSameOrAfter(minDate);
        const isBeforeMaxDateAfter =
          !maxDate || candidateDateAfter.isSameOrBefore(maxDate);

        if (
          getEnableDays.includes(candidateDateAfter.day()) &&
          isAfterMinDateAfter &&
          isBeforeMaxDateAfter
        ) {
          const currentDifference = Math.abs(
            candidateDateAfter.diff(startDate, "days")
          );
          if (currentDifference < daysDifferenceAfter) {
            closestDateAfter = candidateDateAfter;
            daysDifferenceAfter = currentDifference;
          }
        }
      }
      // Compare the closest dates before and after and choose the closer one
      if (closestDateBefore && closestDateAfter) {
        return closestDateBefore.isBefore(closestDateAfter)
          ? closestDateBefore.format("YYYY-MM-DD")
          : closestDateAfter.format("YYYY-MM-DD");
      } else if (closestDateBefore) {
        return closestDateBefore.format("YYYY-MM-DD");
      } else if (closestDateAfter) {
        return closestDateAfter.format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    // end near date

    // start convert time
    convertTime(timeString, sourceTimeZone, targetTimeZone) {
      // Parse the original time string in the source timezone
      const originalTime = moment.tz(timeString, "HH:mm:ss", sourceTimeZone);

      // Convert the time to the target timezone
      const convertedTime = originalTime.clone().tz(targetTimeZone);

      // Format the converted time as "HH:mm:ss"
      const formattedTime = convertedTime.format("YYYY-MM-DD HH:mm:ss");

      return formattedTime;
    },
    // end convert time

    getProfile() {
      this.userGender = this.dataProfile.person.gender;
      this.filter.gender = this.userGender.name.toLowerCase();
      if (this.filter.gender.length == 1) {
        this.dialogFilter = true;
      }
    },
    async getSymptoms() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get-symptom`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          let sort = resp.results.sort((a, b) => {
            if (
              a[`name_${this.$i18n.locale}`] < b[`name_${this.$i18n.locale}`]
            ) {
              return -1;
            }
            if (
              a[`name_${this.$i18n.locale}`] > b[`name_${this.$i18n.locale}`]
            ) {
              return 1;
            }
            return 0;
          });
          this.symptoms = sort;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getProduct() {
      if (this.filter.gender == "all" && this.filter.category.length == 0) {
        this.dialogFilter = false;
      } else {
        this.dialogFilter = true;
      }
      try {
        const {
          category: filterCategory,
          gender: filterGender,
          symptoms: filterSymptom,
        } = this.filter;
        this.loading = true;
        let startPage = (this.pageProduct - 1) * this.perPageProduct;
        let showPage = this.perPageProduct;
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get?plant_id=${
            this.payload.branch || ""
          }${
            this.payload.serviceName.length > 0
              ? `&product_ids=${this.payload.serviceName}`
              : `&subcategory_ids=${filterCategory.toString()}&symptom_ids=${filterSymptom
                  .toString()
                  .toLowerCase()}&gender=${
                  filterGender == "all" ? "" : filterGender
                }&product_ids=${
                  this.payload.serviceName
                }&offset=${startPage}&per_page=${showPage}&lang=${
                  this.$i18n.locale
                }`
          }`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          if (this.pageProduct == 1) {
            this.listTest = [];
          }
          this.listTest = [...this.listTest, ...resp.results];
          this.pageProduct = this.pageProduct + 1;
          this.totalPageProduct = resp.total;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    // async filterProduct(data) {
    // sorting by name product
    // if (data.length > 0) {
    //   let filter = data.sort((a, b) => this.sortByName(a, b));
    //   // filter Name
    //   if (this.payload.serviceName.length > 0) {
    //     const filterName = await data.filter(res => {
    //       return this.payload.serviceName.indexOf(res.id) !== -1;
    //     });
    //     filter = filterName;
    //   } else {
    //     this.getBranch();
    //   }
    //   // filter Gender
    //   // const filterGender = await filterName.filter(res =>{
    //   //   if(this.filter.gender.lenght>0){
    //   //     res.gender == null || res.gender.toUpperCase == 'All'
    //   //   }else{
    //   //     return true;
    //   //   }
    //   // })
    //   if (
    //     filter.length > 0 &&
    //     filter.length < this.payload.serviceName.length
    //   ) {
    //     this.getBranchWithParams();
    //     this.listTest = await this.listService.filter(res => {
    //       return this.payload.serviceName.indexOf(res.id) !== -1;
    //     });
    //   } else {
    //     this.listTest = filter;
    //   }
    // } else {
    //   this.listTest = [];
    // }
    // },
    async getBranch() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.listBranch = resp.results;
        this.paramBranchSLug = this.payload.branch;
      } catch (error) {
        console.log(error);
      }
    },
    async getBranchWithParams() {
      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}plant/get-lab-queue?show_opening_hours=true`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          { product_id: this.payload.serviceName }
        );
        this.listBranch = resp.results;
        this.payload.branch = "";
      } catch (error) {
        console.log(error);
      }
    },
    async getService() {
      try {
        this.loadingSearch = true;
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get?query=${this.searchServiceName}&lang=${this.$i18n.locale}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          this.listService = resp.results;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingSearch = false;
      }
    },
    // async searchProductByName() {
    //   if (this.payload.branch == "") {
    //     const indexService = this.listService.findIndex(
    //       res => res.id == this.payload.serviceName
    //     );
    //     this.listTest =
    //       indexService > -1 ? [this.listService[indexService]] : [];
    //   } else {
    //     await this.getProduct();
    //     const indexProduct = this.listTest.findIndex(
    //       res => res.id == this.payload.serviceName
    //     );
    //     this.listTest = indexProduct > -1 ? [this.listTest[indexProduct]] : [];
    //   }
    // },
    async getCategoryOld() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product-subcategory/get?plant_id=${this.payload.branch}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          let sort = resp.results.sort((a, b) => {
            if (
              a[`sub_category_name_${this.$i18n.locale}`] <
              b[`sub_category_name_${this.$i18n.locale}`]
            ) {
              return -1;
            }
            if (
              a[`sub_category_name_${this.$i18n.locale}`] >
              b[`sub_category_name_${this.$i18n.locale}`]
            ) {
              return 1;
            }
            return 0;
          });
          this.groupCategory = sort;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCategory() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product-test-category/get`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          const _this = this;
          resp.results.sort((a, b) => {
            if (
              a[`name_${_this.$i18n.locale}`] < b[`name_${_this.$i18n.locale}`]
            ) {
              return -1;
            }
            if (
              a[`name_${_this.$i18n.locale}`] > b[`name_${_this.$i18n.locale}`]
            ) {
              return 1;
            }
            return 0;
          });

          resp.results.forEach(function (result) {
            result.sub_categories.sort((a, b) => {
              if (
                a[`name_${_this.$i18n.locale}`] <
                b[`name_${_this.$i18n.locale}`]
              ) {
                return -1;
              }
              if (
                a[`name_${_this.$i18n.locale}`] >
                b[`name_${_this.$i18n.locale}`]
              ) {
                return 1;
              }
              return 0;
            });
          });
          this.groupCategory = resp.results;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getGender() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}gender/get`,
          {}
        );
        if (resp.statusCode == 200) {
          this.gender = [
            { code: "all", name: this.$t("all"), name_id: this.$t("all") },
            ...resp.results.sort((a, b) => this.sortByName(a, b)),
          ];
        }
      } catch (error) {
        console.log(error);
      }
    },
    // validate() {
    //   if (this.$refs.form.validate() == true) {
    //     API.get(
    //       `${process.env.VUE_APP_API_TRUST}product/get?plant_id=${this.payload.branch}`,
    //       {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${Auth.getToken()}`,
    //       }
    //     )
    //       .then(response => {
    //         if (response.statusCode === 200) {
    //           this.paramBranchSLug = this.payload.branch;
    //           this.listTest = response.results;
    //         } else if (response.statusCode == 400) {
    //           Object.keys(response.errors).forEach(element => {
    //             this.toast(
    //               "error",
    //               typeof response.errors[element] == "object"
    //                 ? response.errors[element][0]
    //                 : response.errors[element]
    //             );
    //           });
    //         } else {
    //           this.$swal({
    //             icon: "error",
    //             text: response.message,
    //             //text: this.$t("error_try_again_latter"),
    //           });
    //         }
    //       })
    //       .catch(() => {
    //         this.$swal({
    //           icon: "error",
    //           text: "Error search data!",
    //         });
    //       });
    //     this.$store.state.date = moment(this.dateFormatted)
    //       .format("YYYY-MM-DD")
    //       .toUpperCase();
    //   }
    // },
    linkBranch(message, event) {
      if (this.payload.branch == "") {
        event.preventDefault();
        this.$swal({
          icon: "error",
          text: message,
        }).then(() => {
          this.$refs.autoBranch.focus();
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    resetCheck() {
      this.filter = { category: [], gender: [], symptoms: [] };
    },
    async updateCartApi() {
      await this.$store.dispatch("fetchCartData");
    },
    continueAddToCart() {
      const addCartTemp = {
        from_package: false,
        qty: 1,
        plant: {
          id: this.payload.branch,
        },
        product: {
          id: this.editedItem.id,
          name: this.editedItem.name,
          name_en: this.editedItem.name_en,
          prices: [
            {
              payment_method_id: 1,
              amount: this.editedItem.prices[0].amount,
            },
          ],
        },
      };
      // update cart before store db
      this.$store.commit("updateCart", [
        ...this.$store.state.cart,
        addCartTemp,
      ]);

      this.dialogConfirmation = false;
      const dataReady = [
        {
          plant_id: this.branchPar,
          package_id: null,
          from_package: false,
          product_id: this.editedItem.id,
          qty: 1,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
          this.editedItem = {};
        }
      });
    },
    addCart(data) {
      // const is_package =
      //   this.dataMyPackage.findIndex(
      //     (dataGetPackage) => dataGetPackage.id == data.id
      //   ) >= 0;
      this.serviceSelected = data;
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.payload.branch;
      } else {
        //jika ada param url branch tapi memilih branch lain di menu pilihan branch
        if (this.$route.query.branch === this.payload.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.payload.branch;
        }
      }

      const addCartTemp = {
        from_package: false,
        qty: 1,
        plant: {
          id: this.payload.branch,
        },
        product: {
          id: data.id,
          name: data.name,
          name_en: data.name_en,
          prices: [
            {
              payment_method_id: 1,
              amount: data.prices[0].amount,
            },
          ],
        },
        package: null,
        deal: null,
      };

      const indexCart = this.$store.state.cart.find(
        res => res.deal !== null || res.package !== null
      );

      if (typeof indexCart === "undefined") {
        if (this.$store.state.cart.length > 0) {
          if (this.branchPar == this.$store.state.cart[0].plant.id) {
            this.addStoreCart(addCartTemp);
          } else {
            this.dialogConfirmation = true;
            this.editedItem = data;
          }
        } else {
          this.addStoreCart(addCartTemp);
        }
      } else {
        this.$swal({
          text: this.$t("not_have_deal_subs_cart"),
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
          showCancelButton: true,
          showCloseButton: true,
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.state.cart.forEach(el => {
              if (el.deal != null) {
                this.removeCartAll(el.package.id, el.deal.id, el.plant.id);
              } else {
                this.removeCartAll(el.package.id, null, el.plant.id);
              }
            });
            this.addStoreCart(addCartTemp);
          }
        });
      }

      /*
      if (this.$store.state.cart.length > 0) {
        if (this.branchPar == this.$store.state.cart[0].plant.id) {
          // update cart before store db
          this.$store.commit("updateCart", [
            ...this.$store.state.cart,
            addCartTemp,
          ]);
          const dataReady = [
            {
              plant_id: this.branchPar,
              package_id: null,
              deal_id: null,
              from_package: false,
              product_id: data.id,
              qty: 1,
            },
          ];
          API.post(
            `${process.env.VUE_APP_API_TRUST}cart/store`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            dataReady
          ).then(res => {
            if (res.statusCode == 200) {
              this.updateCartApi();
            }
          });
        } else {
          this.dialogConfirmation = true;
          this.editedItem = data;
        }
      } else {
        // update cart before store db
        this.$store.commit("updateCart", [
          ...this.$store.state.cart,
          addCartTemp,
        ]);
        const dataReady = [
          {
            plant_id: this.branchPar,
            package_id: null,
            deal_id: null,
            from_package: false,
            product_id: data.id,
            qty: 1,
          },
        ];
        API.post(
          `${process.env.VUE_APP_API_TRUST}cart/store`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        ).then(res => {
          if (res.statusCode == 200) {
            this.updateCartApi();
          }
        });
      }
      */
    },
    addStoreCart(addCartTemp) {
      this.$store.commit("updateCart", [
        ...this.$store.state.cart,
        addCartTemp,
      ]);
      const dataReady = [
        {
          plant_id: this.branchPar,
          package_id: null,
          deal_id: null,
          from_package: false,
          product_id: this.serviceSelected.id,
          qty: 1,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    removeCartAll(package_id, deal_id, branch) {
      const dataReady = [
        {
          plant_id: branch,
          package_id: package_id,
          deal_id: deal_id,
          from_package: false,
          product_id: null,
          qty: 0,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        // ?lang=${this.$i18n.locale}
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        } else if (res.statusCode == 403) {
          Object.keys(res.errors).forEach(element => {
            this.toast(
              "error",
              typeof res.errors[element] == "object"
                ? res.errors[element][0]
                : res.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: res.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      });
    },
    removeCart(data) {
      // remove before store
      const cart = this.$store.state.cart;
      const indexCart = cart.findIndex(res => res.product.id == data.id);
      cart.splice(indexCart, 1);
      this.$store.commit("updateCart", cart);
      // const is_package =
      //   this.dataMyPackage.findIndex(
      //     (dataGetPackage) => dataGetPackage.id == data.id
      //   ) >= 0;
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.payload.branch;
      } else {
        if (this.$route.query.branch === this.payload.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.payload.branch;
        }
      }
      const dataReady = [
        {
          plant_id: this.branchPar,
          package_id: null,
          deal_id: null,
          from_package: false,
          product_id: data.id,
          qty: 0,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    checkItem(item) {
      if (typeof this.$route.query.branch === "undefined") {
        this.branchPar = this.payload.branch;
      } else {
        if (this.$route.query.branch === this.payload.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.payload.branch;
        }
      }
      if (
        this.$store.state.cart.length > 0 &&
        this.$store.state.cart[0].plant != undefined
      ) {
        if (this.branchPar == this.$store.state.cart[0].plant.id) {
          return this.$store.state.cart.findIndex(
            res => res.product != null && res.product.id == item.id
          ) < 0
            ? false
            : true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
  },
};
</script>
<style lang="scss">
.inp-filter-test {
  .v-text-field__slot {
    padding: 3px 2px !important;
  }
  .v-icon {
    font-size: 20px !important;
  }
  .v-input__icon--clear {
    padding-top: 8px !important;
  }
  .v-select__slot {
    padding: 3px 2px !important;
  }
}
.filter {
  .v-list-item .v-list-item__title {
    display: block !important;
  }
  .theme--light.v-list-item--active::before {
    opacity: 0;
  }
  .input-filter.v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0 2px;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .v-expansion-panel-header {
    padding: 0;
    min-height: 34px;
  }
  // .v-expansion-panel--active > .v-expansion-panel-header{
  //   min-height: 28px;
  // }
}
.test-filter {
  .v-list-item {
    padding: 0;
  }
  .title-item {
    font-size: 12px;
    line-height: 24px;
    color: #1f3c87;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .radio-size {
    font-size: 20px;
    .v-input__control
      .v-input__slot
      .v-input--selection-controls__input
      .v-icon {
      font-size: 20px !important;
    }
  }
  .padding-left-radio {
    padding-left: 1px;
  }
}
.search-product__list {
  .radio-size {
    font-size: 20px;
    .v-input__control
      .v-input__slot
      .v-input--selection-controls__input
      .v-icon {
      font-size: 20px !important;
    }
  }
}

.inp-elipse
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > input[type="text"] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
}
.sub-group-item .v-expansion-panel {
  margin-top: 0;
}
</style>
<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
@import "@/assets/scss/color.scss";

.service--close {
  position: sticky;
  top: 0;
  font-size: 12px;
  color: white;
  z-index: 9;
  text-align: right;
  background: $main_1;
}

// start only desktop sticky
.filter--sticky {
  position: sticky;
  top: 14px;
}
// end only desktop sticky
.comming-soon {
  img {
    width: 100%;
    max-width: 500px;
  }
  h4 {
    color: $dark_blue_2;
    font-size: 20px;
  }
}
.overflow-elipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.mr-mobile {
  margin-top: 64px;
  margin-right: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
}
@media only screen and (max-width: 767px) {
  .d-btn-show {
    display: none !important;
  }
  .m-btn-show {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .mr-mobile {
    margin: 55px 0 0 0 !important;
    box-shadow: unset !important;
  }
  .v-application .pt-3 {
    padding-top: 14px !important;
  }
  .my-package {
    background: white;
  }
}
@media only screen and (min-width: 1200px) {
  .popular-price {
    margin-bottom: 10px !important;
    padding: 0 !important;
    font-size: 14px !important;
    margin-top: 10px;
  }
  .btn-margin {
    margin-top: 7px !important;
    margin-left: 0 !important;
  }
  .btn-detail-price {
    font-size: 12px !important;
  }
}

@media screen and (width: 1024px) {
  // .col-md-3 {
  //     flex: 0 0 25% !important;
  //     max-width: 25% !important;
  // }
  // .col-md-6 {
  //     flex: 0 0 100% !important;
  //     max-width: 100% !important;
  // }
  // .col-md-9 {
  //     flex: 0 0 75% !important;
  //     max-width: 75% !important;
  // }
  // .popular-action{
  //     flex: 0 0 25% !important;
  //     max-width: 25% !important;
  // }
  .popular-price {
    font-size: 12px !important;
    padding: 0 !important;
    margin-bottom: 5px !important;
    margin-top: 7px;
  }
  .btn-margin {
    margin-top: 7px !important;
    margin-left: 0 !important;
  }
  .btn-detail-price {
    font-size: 11px !important;
    height: 30px !important;
    min-width: 50px !important;
  }
  .btn-detail {
    padding: 0 12px !important;
  }
}

// @media screen and (min-width: 961px) {
//   .pr-card {
//     padding-right: 5px;
//   }
// }

@media screen and (min-width: 541px) {
  .button-show-md {
    display: block;
  }
  .button-show-sm {
    display: none;
  }

  // .my-package .section {
  //   // padding: 10px !important;
  // }
}

@media screen and (max-width: 540px) {
  .button-show-sm {
    display: block;
  }
  .button-show-md {
    display: none;
  }
  .btn-center {
    text-align: center;
  }
  // .my-package .section {
  //   // padding: 10px !important;
  // }
}

@media screen and (max-width: 600px) {
  .card-top {
    width: 100%;
    right: 0;
    left: 0;
  }

  .text-field {
    max-width: 80% !important;
    margin-left: 2px !important;
    margin-top: -4px !important;
  }

  .bell {
    margin-top: -73px;
    margin-left: 256px;
  }

  .cart-right {
    margin-left: 0px !important;
  }
  .cart {
    margin-left: 11px;
    margin-right: 96px;
    z-index: 1;
    margin-top: -20px !important;
  }

  .bell-mobile {
    margin-left: 8px;
  }
  .user-card {
    margin-top: -40px;
    max-width: 36% !important;
    margin-left: 141px;
    margin-right: -106px;
  }
  .notification {
    height: 55px;
    border-radius: 3px;
    margin-top: -15px;
    margin-left: -35px !important;
  }
}

.remove {
  background-color: white !important;
  color: #00a4b3 !important;
  border: thin solid currentColor;
  border-color: #00a4b3 !important;
}

.my-package {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  &__section {
    padding: 0 20px;
  }
  // .section {
  //   padding: 20px;
  // }
  a {
    text-decoration: none;
  }
  .h-full {
    height: 100%;
  }
}

.inp-text {
  background-color: #ffffff;
  border-radius: 10px;
  height: 40px;
  padding: 4px;
  font-size: 12px !important;
  align-items: center;
}

.branch-title {
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 4px;
  margin-top: 8px;
}
.title-page {
  color: $blue_1;
  margin-top: 10px;
}
.text-field {
  max-width: 70%;
  margin-top: -3px;
}

.v-application--is-ltr .v-text-field .v-label {
  display: none !important;
}

.card-search {
  background: #00a4b3;
  //box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px 20px 20px 20px;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  // margin-top: 5px;
}
.card-popular {
  padding: 10px 10px;
  border-radius: 10px;
  background: #ffffff;
  // height: 100%;
  //border: 1px solid #777777;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  box-sizing: border-box;
  //filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
}

.popular-action {
  text-align: right;
  font-size: 13px;
  color: #1f3c87;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: -20px;
}
.popular-title-card {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #1f3c87;
  margin-bottom: 0;
  text-transform: uppercase;
}
.popular-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1f3c87;
  margin-bottom: 0;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.filter-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #1f3c87;
  margin-bottom: 0;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.filter-title-sub {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1f3c87;
  margin-bottom: 0;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.listbox-sub {
  margin-left: 20px;
}
.icon-sub {
  order: 0;
}

.header-sub {
  order: 1;
}

.popular-detail {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #00a4b3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.popular-price {
  font-weight: 700;
  font-size: 14px;
  // line-height: 24px;
  text-align: left;
  // color: #1f3c87;
  padding: 0 0 10px 0;
  margin-bottom: 0;

  color: #1f3c87;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.btn-detail {
  background-color: #00a4b3 !important;
  border-radius: 8px;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #ffffff !important;
  text-transform: capitalize;
}
.btn-detail-price {
  background-color: #00a4b3 !important;
  border-radius: 8px;
  font-weight: 300;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  color: #ffffff !important;
  text-transform: capitalize;
  padding: 5px !important;
}
.btn-search {
  background-color: #213b8f !important;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff !important;
  text-transform: capitalize;
  width: 200px !important;
}
.no-record {
  text-align: center;
  padding-top: 50px;
}
.list-height {
  min-height: 22px;
}

.date-filter {
  margin-top: 5px;
}

.margin-symptom {
  //margin-bottom: -5px;
  height: 35px;
}

.margin-item {
  //margin-bottom: -18px;
  height: 22px;
  &:nth-child(1) {
    margin-top: -4px;
  }
  &:nth-last-child(1) {
    margin-bottom: 4px;
  }
}

.footer-class {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: -32px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: $main_2 !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 64px;
  padding: 0 16px;
}

.button {
  width: 0;
}

.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-radius: 5px;
  width: 100%;
  background-color: #00a4b3;
  color: white;
  text-transform: capitalize;
}

.cart {
  margin-top: 10px;
}
// .row--dense > .col,
// .row--dense > [class*="col-"] {
//   padding: 6px;
// }
.crs-check {
  cursor: pointer;
}
.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  margin-top: -18px;
  float: right;
}
.mg-symptom {
  padding: 0 !important;
  margin-bottom: -5px;
}
.icon-loading {
  height: 50px;
  transform: translateX(12px);
}

// custom search product
.search-product {
  position: relative;
  &__box {
    background: white;
    position: absolute;
    left: 0;
    top: 110%;
    width: 100%;
    box-shadow: 0px 0px 8px grey;
    border-radius: 12px;
    z-index: 9;
    display: none;
    &.active {
      display: block;
    }
  }
  &__list {
    overflow-x: scroll;
    max-height: 200px;
    padding-top: 16px;
  }
}
.f12 {
  font-size: 12px;
}
</style>
