<template>
  <div class="voucher" :class="{ 'd-none': checkCart == null }">
    <div
      class="voucher__notice d-flex px-3 px-md-5 py-2"
      :class="{ 'cursor-pointer': !active }"
    >
      <p class="mb-0 flex-grow-1" @click="!active ? toggleActive() : () => {}">
        <v-icon color="white" class="mr-3">mdi-ticket-percent-outline</v-icon
        >{{ $t("page.cart.click_have_voucher") }}
      </p>
      <v-icon color="white" v-if="active" @click="removeVoucher()"
        >mdi-close</v-icon
      >
    </div>
    <div class="voucher__body pa-3 pa-md-5" v-if="active">
      <div class="voucher__input">
        <div class="d-flex">
          <v-text-field
            v-model="voucherCode"
            :label="$t('page.cart.voucher_code')"
            hide-details
            dense
            outlined
          >
          </v-text-field>
          <c-button
            bold
            shadow
            small
            rounded
            @click="applyVoucher()"
            :disabled="voucherCode == '' || checkCart == null"
            class="ml-3 ml-md-5"
          >
            {{ $t("page.cart.apply") }}</c-button
          >
        </div>
        <p class="voucher__error mb-0" v-if="error">{{ errorMessage }}</p>
      </div>
      <div class="voucher__applied d-flex pa-2 mt-3" v-if="value">
        <v-icon color="#00a6b6" class="mr-3">mdi-sale</v-icon>
        <div class="flex-grow-1">
          <h5>{{ value.name || "" }}</h5>
          <p class="mb-0">
            {{ $t("page.cart.promo_code") }} {{ value.code || "" }}
            {{ $t("page.cart.has_success_applied") }}
          </p>
        </div>
        <v-icon @click="$emit('input', null)">mdi-delete</v-icon>
      </div>
      <div class="voucher__list mt-5 d-none">
        <v-row>
          <v-col cols="6">
            <voucher
              discount="Promo member Biznet"
              :desc="`${$t('page.lab_test.up_to_idr')} 20.0000`"
              code="XYZ"
              :valid="`${$t('page.cart.valid_until')}`"
              :dateValid="formatDate('12-09-2024')"
            />
          </v-col>
          <v-col cols="6">
            <voucher
              discount="Promo member Biznet"
              :desc="`${$t('page.lab_test.up_to_idr')} 20.0000`"
              code="XYZ"
              :valid="`${$t('page.cart.valid_until')}`"
              :dateValid="formatDate('12-09-2024')"
            />
          </v-col>
          <v-col cols="6">
            <voucher
              discount="Promo member Biznet"
              :desc="`${$t('page.lab_test.up_to_idr')} 20.0000`"
              code="XYZ"
              :valid="`${$t('page.cart.valid_until')}`"
              :dateValid="formatDate('12-09-2024')"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from "@/components/v2/button/index.vue";
import Voucher from "@/components/v2/voucher/index.vue";
import moment from "moment-timezone";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";

export default {
  components: {
    CButton,
    Voucher,
  },
  props: {
    email: {
      type: String,
      default: null,
    },
    branch: {
      type: [String, Number],
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    active: false,
    error: false,
    errorMessage: "",
    voucherCode: "",
  }),
  computed: {
    checkCart() {
      if (this.$store.state.cart.length > 0) {
        return this.$store.state.cart[0].package
          ? this.$store.state.cart[0].package.subscription
            ? null
            : "package"
          : "product";
      } else {
        return null;
      }
    },
  },
  watch: {
    "$store.state.cart": function (val) {
      if (this.voucherCode != "" && val.length > 0) {
        this.applyVoucher();
      }
    },
  },
  methods: {
    toggleActive() {
      this.active = !this.active;
    },
    removeVoucher() {
      this.active = false;
      this.voucherCode = "";
      this.$emit("input", null);
    },
    async applyVoucher() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}voucher/detail-voucher-code?code=${this.voucherCode}&item_category=${this.checkCart}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          if (resp.results.length > 0) {
            this.error = false;
            this.errorMessage = "";
            const voucher = resp.results[0];
            // check voucher with email or not
            if (voucher.email) {
              // check validation with profile
              if (voucher.email.toUpperCase() == this.email.toUpperCase()) {
                if (
                  voucher.plant.some(plant => plant.plant_id == this.branch)
                ) {
                  this.$store.state.cart.forEach(item => {
                    // check cart all include voucher 100%
                    const error = !voucher[this.checkCart].some(pack => {
                      return (
                        pack[`${this.checkCart}_id`] == item[this.checkCart].id
                      );
                    });
                    if (error) {
                      this.error = true;
                      this.errorMessage = this.$t(
                        "page.cart.voucher_cant_used"
                      );
                    }
                  });
                  if (!this.error) {
                    this.$emit("input", voucher);
                  } else {
                    this.$emit("input", null);
                  }
                } else {
                  this.errorMessage = this.$t(
                    "page.cart.voucher_not_cover_branch"
                  );
                  this.error = true;
                  this.$emit("input", null);
                }
              } else {
                this.errorMessage = this.$t("page.cart.voucher_not_available");
                this.error = true;
                this.$emit("input", null);
              }
            } else {
              if (voucher.plant.some(plant => plant.plant_id == this.branch)) {
                this.$store.state.cart.forEach(item => {
                  // check cart all include voucher 100%
                  const error = !voucher[this.checkCart].some(pack => {
                    return (
                      pack[`${this.checkCart}_id`] == item[this.checkCart].id
                    );
                  });
                  if (error) {
                    this.error = true;
                    this.errorMessage = this.$t("page.cart.voucher_cant_used");
                  }
                });
                if (!this.error) {
                  this.$emit("input", voucher);
                } else {
                  this.$emit("input", null);
                }
              } else {
                this.errorMessage = this.$t(
                  "page.cart.voucher_not_cover_branch"
                );
                this.error = true;
                this.$emit("input", null);
              }
            }
          } else {
            this.errorMessage = this.$t("page.cart.cart_cant_empty");
            this.error = true;
            this.$emit("input", null);
          }
        } else {
          this.errorMessage = this.$t("page.cart.voucher_not_available");
          this.error = true;
          this.$emit("input", null);
        }
      } catch (error) {
        console.log("Error 403! get voucher", error);
      }
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/color.scss";

.voucher {
  border: 1px solid $main_1;

  &__notice {
    background: $main_1;
    color: white;
    font-size: 14px;
    opacity: 0.8;
  }
  &__applied {
    border-radius: 4px;
    border: 1px solid $gray_1;
    h5 {
      font-size: 14px;
    }
    p {
      color: $gray_10;
      font-size: 14px;
    }
  }
  &__error {
    font-size: 12px;
    color: $red;
  }
}
</style>
