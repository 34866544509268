<template>
  <div class="cart pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne>
    <!-- </section> -->

    <v-card class="card-top ma-3 mt-16 ma-md-5">
      <custom-section class="section">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="7">
              <v-card
                class="card pa-3 mb-3 pa-md-5 mb-md-5"
                outlined
                elevation="1"
              >
                <div class="mb-3">
                  <heading-card
                    bold
                    color="dark-blue"
                    :title="$t('page.office_care.branch_appointment')"
                  />
                </div>
                <!-- start merge homecare -->
                <v-checkbox
                  v-model="mergeHomecare"
                  dense
                  hide-details
                  class="mb-3"
                  v-if="this.familyList.length > 0"
                >
                  <template v-slot:label>
                    <div class="checkbox--booking-else">
                      {{ $t("join_session_homecare") }}
                    </div>
                  </template>
                </v-checkbox>
                <v-form
                  ref="formMergeHomecare"
                  v-model="valid"
                  lazy-validation
                  v-if="mergeHomecare"
                >
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        hide-details
                        label="registration group"
                        v-model="payload.registration_group"
                        dense
                        outlined
                        :rules="[v => !!v || `${$t('is_required')}`]"
                        class="uppercase-input"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <c-button rounded @click="checkMerge()">
                        {{ $t("search") }}
                      </c-button>
                    </v-col>
                  </v-row>
                </v-form>
                <!-- end merge homecare -->
                <v-row v-if="profile != null">
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      class="font-size-14"
                      v-model="payload.selectBranch"
                      :items="branchtest"
                      item-text="name"
                      item-value="id"
                      :rules="[
                        v => !!v || `${$t('branch_test')} ${$t('is_required')}`,
                      ]"
                      :label="$t('branch')"
                      outlined
                      required
                      dense
                      hide-details
                      :disabled="mergeHomecare"
                      ref="autocomplete"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="bookingHomecare"
                      dense
                      :label="$t('page.office_care.book_date')"
                      :rules="[
                        v => !!v || `${$t('book_date')} ${$t('is_required')}`,
                      ]"
                      readonly
                      @click.stop="
                        modalScheduleCheck($t('branch_cannot_empty'))
                      "
                      outlined
                      hide-details
                      :disabled="mergeHomecare"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      hide-details
                      :label="$t('page.office_care.address_detail')"
                      v-model="payload.address"
                      dense
                      outlined
                      class="uppercase-input"
                      :disabled="mergeHomecare"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      hide-details
                      :label="$t('page.office_care.address')"
                      v-model="payload.addressInput2"
                      readonly
                      dense
                      outlined
                      @click.stop="openModalAddress"
                      class="uppercase-input"
                      :disabled="mergeHomecare"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <input
                  type="hidden"
                  class="uppercase-input"
                  v-model="payload.addressInput"
                />
                <input type="hidden" v-model="payload.longlat" />
              </v-card>
              <v-card class="card pa-3 pa-md-5" outlined elevation="1">
                <v-row>
                  <v-col cols="9">
                    <heading-card
                      bold
                      color="dark-blue"
                      :title="$t('page.cart.customer_information')"
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="!showEditingItem"
                    style="text-align: end"
                  >
                    <v-icon
                      @click="addChooseForm"
                      color="#00a6b6"
                      :disabled="
                        payload.selectBranch == '' ||
                        payload.dateBooking == '' ||
                        payload.address == '' ||
                        payload.addressInput2 == '' ||
                        familyListNotSelected.length == 0
                      "
                    >
                      mdi-account-plus
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- <v-col cols="12" v-if="!openEdit"> 
                    @click="openEditClick($t('branch_cannot_empty'))" 
                    <c-button rounded @click=" openEdit = true "  :disabled="payload.selectBranch == '' || payload.dateBooking == '' || payload.address == ''">
>>>>>>> develop
                      {{ $t("add") }}
                    </c-button>
                  </v-col> -->
                  <v-col cols="12" v-if="openEdit">
                    <div class="card--cart">
                      <h4 class="mb-4">
                        {{ isEdit ? $t("edit") : $t("add") }}
                        {{ $t("page.office_care.family_member") }}
                      </h4>
                      <v-form
                        ref="formMember"
                        v-model="validFormMember"
                        lazy-validation
                      >
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              hide-details
                              v-model="editedItem.memberFamily"
                              :items="familyList"
                              item-text="related_person_name"
                              item-value="patient_id"
                              :label="`${$t('select')} ${$t('patient')}`"
                              :rules="[
                                v =>
                                  !!v ||
                                  `${$t('page.office_care.family_member')} ${$t(
                                    'is_required'
                                  )}`,
                              ]"
                              outlined
                              :readonly="isEdit"
                              dense
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="12" v-if="typeOfBook == 'test'">
                            <v-autocomplete
                              v-model="editedItem.test"
                              :items="appointmentList"
                              :label="$t('page.office_care.choose_test')"
                              :item-text="
                                $i18n.locale == 'id'
                                  ? `name`
                                  : `name_${$i18n.locale}`
                              "
                              :rules="[
                                v =>
                                  !!v || `${$t('test')} ${$t('is_required')}`,
                              ]"
                              item-value="id"
                              multiple
                              chips
                              persistent-hint
                              outlined
                              hide-details
                            >
                              <template slot="item" slot-scope="{ item }">
                                <div class="item-test">
                                  {{
                                    $i18n.locale == "id"
                                      ? item.name
                                      : item[`name_${$i18n.locale}`]
                                  }}<br />
                                  <p class="item-test__price">
                                    IDR
                                    {{ formatPrice(item.prices[0].amount) }}.00
                                  </p>
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            v-if="typeOfBook == 'package'"
                          >
                            <v-autocomplete
                              v-model="editedItem.package"
                              :items="appointmentListPackage"
                              :label="$t('page.office_care.choose_package')"
                              item-text="name"
                              :rules="[
                                v =>
                                  !!v ||
                                  `${$t('package')} ${$t('is_required')}`,
                              ]"
                              item-value="id"
                              multiple
                              chips
                              persistent-hint
                              outlined
                              hide-details
                            >
                              <template slot="item" slot-scope="{ item }">
                                <div class="item-test">
                                  {{ item.name }}<br />
                                  <p class="item-test__price">
                                    IDR
                                    {{ formatPrice(item.prices[0].amount) }}.00
                                  </p>
                                </div>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" class="d-flex justify-end">
                            <v-btn
                              class="minWidth bg-white"
                              @click.stop="close"
                              >{{ $t("cancel") }}</v-btn
                            >
                            <v-btn
                              class="ml-4 minWidth bg-blue"
                              :disabled="!validFormMember"
                              @click.stop="save"
                              :class="{
                                active: showVoucher,
                              }"
                            >
                              {{ $t("add") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-for="(order, i) in rowsOrder" :key="i" cols="12">
                    <card-hc-test
                      :title="getFamilyById(order.memberFamily)"
                      :index="i"
                      :sub_title="`IDR ${formatPrice(
                        order.type == 'test'
                          ? getTotalAllTestPrice(order.items)
                          : getTotalAllPackagePrice(order.items)
                      )}.00`"
                      :desc="
                        order.type == 'test'
                          ? getAllTest(order.items)
                          : getAllPackage(order.items)
                      "
                      @deleteRow="index => deleteRow(index)"
                      @editRow="index => editRow(index)"
                    ></card-hc-test>
                  </v-col>
                </v-row>
                <!-- remove -->
                <v-row class="d-none">
                  <v-col cols="12">
                    <v-data-table
                      :headers="headerTable"
                      :items="dataTable"
                      hide-default-footer
                      :mobile-breakpoint="0"
                      class="elevation-1"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td valign="top" class="pt-2">
                            {{ getFamilyById(item.memberFamily) }}
                          </td>
                          <td valign="top" class="pt-2">
                            <ul>
                              <li
                                v-for="(test, index) of item.test"
                                :key="`test_${index}`"
                              >
                                {{ getTest(test) }}
                              </li>
                              <li
                                v-for="(test, index) of item.package"
                                :key="`package_${index}`"
                              >
                                {{ getPackage(test) }}
                              </li>
                            </ul>
                          </td>
                          <td valign="top" class="pt-2" align="right">
                            IDR {{ getTotalTestPrice(item) }}.00
                          </td>
                          <td valign="top" class="pt-2" align="center">
                            <v-icon small @click="editItem(item)">
                              mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteItem(item)">
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
              <!-- sementara hide voucher -->
              <v-card
                class="card pa-3 mt-3 pa-md-5 mt-md-5 d-none"
                v-if="listDiscount.length > 0"
                outlined
                elevation="1"
              >
                <div class="d-flex justify-space-between align-content-start">
                  <heading-card
                    :class="{ 'mb-4': !collapse.voucher }"
                    bold
                    color="dark-blue"
                    title="Vouchers"
                  />
                  <div @click="collapse.voucher = !collapse.voucher">
                    <icons
                      icon="icon-arrow-collapse"
                      :class="{ 'collapse--active': collapse.voucher }"
                    />
                  </div>
                </div>
                <template v-if="!collapse.voucher && listDiscount != null">
                  <template v-if="listDiscount.length > 0">
                    <voucher
                      v-for="(item, index) of listDiscount"
                      :key="index"
                      :discount="item.name"
                      :desc="`${$t('page.lab_test.up_to_idr')} ${formatPrice(
                        item.amount_max
                      )}.00`"
                      :valid="$t('page.cart.valid_until')"
                      :dateValid="formatDate(item.end_date)"
                      class="voucher"
                      :isApply="checkVoucherActive(item.code)"
                      :item="item"
                      @apply="id => applyVoucher(id)"
                      @unApply="id => unApplyVoucher(id)"
                    />
                  </template>
                  <p class="mb-0 count-item" v-else>
                    {{ $t("voucher_not_available") }}
                  </p>
                </template>
              </v-card>
            </v-col>
            <v-col cols="12" md="5">
              <v-card
                class="card pa-3 mb-3 pa-md-5 mb-md-5"
                outlined
                elevation="1"
              >
                <div class="d-flex justify-space-between align-content-start">
                  <heading-card
                    :class="{ 'mb-4': !collapse.order }"
                    bold
                    color="dark-blue"
                    :title="$t('page.cart.order_review')"
                  />
                  <div @click="collapse.order = !collapse.order">
                    <icons
                      icon="icon-arrow-collapse"
                      :class="{ 'collapse--active': collapse.order }"
                    />
                  </div>
                </div>

                <template v-if="!collapse.order">
                  <p class="count-item" v-if="rowsOrder.length == 0">
                    {{ $t("empty_data") }}
                  </p>
                  <div>
                    <div :key="i" v-for="(order, i) in rowsOrder">
                      <v-divider class="my-1"></v-divider>
                      <heading-price
                        bold
                        :text="getFamilyById(order.memberFamily)"
                        position="left"
                        color="main"
                      />
                      <template v-if="order.type == 'package'">
                        <div
                          :key="it"
                          v-for="(item, it) in order.items"
                          class="mb-2"
                        >
                          <v-divider class="my-1"></v-divider>
                          <div class="d-flex justify-space-between">
                            <heading-price
                              :text="
                                it == 0
                                  ? item.name
                                  : item[
                                      $i18n.locale == 'id'
                                        ? `product_name`
                                        : `product_name_${$i18n.locale}`
                                    ]
                              "
                              position="left"
                              color="gray"
                            />
                            <heading-price
                              :text="`IDR ${formatPrice(
                                parseFloat(
                                  it == 0
                                    ? parseFloat(item.prices[0].amount)
                                    : parseFloat(item.amount)
                                )
                              )}.00`"
                              position="right"
                              color="gray"
                            />
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div
                          :key="it"
                          v-for="(item, it) in order.items"
                          class="mb-2"
                        >
                          <v-divider class="my-1"></v-divider>
                          <div class="d-flex justify-space-between">
                            <heading-price
                              :text="item.name"
                              position="left"
                              color="gray"
                            />
                            <div>
                              <heading-price
                                :text="`IDR ${formatPrice(
                                  item.applyDiscount &&
                                    activeVoucher.length > 0 &&
                                    item.applyDiscount.voucher
                                    ? parseFloat(item.applyDiscount.final_price)
                                    : parseFloat(item.prices[0].amount)
                                )}.00`"
                                position="right"
                                color="gray"
                              />
                              <heading-price
                                v-if="
                                  item.applyDiscount &&
                                  item.applyDiscount.voucher &&
                                  activeVoucher.length > 0
                                "
                                discount
                                :text="`IDR ${formatPrice(
                                  parseFloat(item.applyDiscount.price_per_item)
                                )}.00`"
                                position="right"
                                color="gray"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- <card-cart-item
                    v-for="(item, index) of dataTable"
                    :key="index"
                    :name="`${getFamilyById(item.memberFamily)}`"
                    :price="`IDR ${formatPrice(
                      getTotalPyamentInvoice(item.payment).final_price
                    )}.00`"
                    :realPrice="
                      getTotalPyamentInvoice(item.payment).final_price ==
                      getTotalPyamentInvoice(item.payment).price_total
                        ? ''
                        : `IDR ${formatPrice(
                            getTotalPyamentInvoice(item.payment).price_total
                          )}.00`
                    "
                    @close="deleteItem(item)"
                  /> -->
                </template>
                <p class="mb-0 count-item" v-else>{{ $t("empty_data") }}</p>
              </v-card>
              <v-card class="card pa-3 pa-md-5" outlined elevation="1">
                <div class="d-flex justify-space-between align-content-start">
                  <heading-card
                    :class="{ 'mb-4': !collapse.payment }"
                    bold
                    color="dark-blue"
                    :title="$t('page.cart.billing_summary')"
                  />
                  <div @click="collapse.payment = !collapse.payment">
                    <icons
                      icon="icon-arrow-collapse"
                      :class="{ 'collapse--active': collapse.payment }"
                    />
                  </div>
                </div>
                <template v-if="!collapse.payment">
                  <div class="billing-box">
                    <div class="d-flex justify-space-between mb-3">
                      <heading-price
                        :text="$t('page.cart.subtotal')"
                        position="left"
                        color="gray"
                      />
                      <heading-price
                        :text="`IDR ${formatPrice(
                          getAllTotalPyamentInvoice().price_total
                        )}.00`"
                        position="right"
                        color="gray"
                        bold
                      />
                    </div>
                    <div class="d-flex justify-space-between mb-3">
                      <heading-price
                        text="Service Fee"
                        position="left"
                        color="gray"
                      />
                      <heading-price
                        :text="`IDR ${formatPrice(this.service_fee)}.00`"
                        position="right"
                        color="gray"
                        bold
                      />
                    </div>
                    <div class="d-flex justify-space-between mb-3">
                      <heading-price
                        :text="$t('page.cart.voucher')"
                        position="left"
                        color="gray"
                      />
                      <heading-price
                        :text="`- IDR ${formatPrice(this.totalDiscount)}.00`"
                        position="right"
                        color="gray"
                        bold
                      />
                    </div>
                  </div>
                </template>
                <div class="d-flex justify-space-between mt-3 mb-2">
                  <heading-price
                    extraBold
                    :text="$t('page.cart.grand_total')"
                    position="left"
                    color="main"
                  />
                  <heading-price
                    :text="`IDR ${formatPrice(
                      getAllTotalPyamentInvoice().final_price
                    )}.00`"
                    position="right"
                    color="main"
                    extraBold
                  />
                </div>
                <v-checkbox
                  v-model="checkTnc"
                  dense
                  hide-details
                  class="d-flex align-start mb-3 pa-0"
                >
                  <template v-slot:label>
                    <div class="checkbox--booking-else">
                      {{ $t("page.cart.by_clic_pay") }}
                      <tnc />
                    </div>
                  </template>
                </v-checkbox>

                <c-button
                  bold
                  shadow
                  rounded
                  fluid
                  @click="validate"
                  :disabled="!checkTnc || rowsOrder.length == 0 || !valid"
                >
                  {{ $t("pay") }} IDR
                  {{
                    formatPrice(getAllTotalPyamentInvoice().final_price)
                  }}.00</c-button
                >
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </custom-section>
    </v-card>
    <address-pop-up
      v-if="modalAddressIsOpen"
      @modalAddressClose="closeModalAddress"
      @setAddress="setAddress"
    />

    <!-- google maps -->
    <!-- <map-pop-up
      v-if="modalMapIsOpen"
      @modalAddressClose="closeModalMap"
      @myLatLng="useThisLatLng($event)"
      @addressChange="useThisAddress($event)"
      @secondAddressChange="useThisAddressTwo($event)"
      @thirdAddressChange="useThisAddressThree($event)"
    /> -->
    <!-- <map-pop-up
      v-if="modalMapIsOpen"
      @modalAddressClose="closeModalMap"
      @addressChange="useThisAddress($event)"
    /> -->
    <!-- leaflet v1.3 address popup-->
    <!-- <homecare-address :isOpen="modalMapIsOpen" @closeDialog="modalMapIsOpen = false" /> -->
    <v-dialog
      v-if="modalScheduleIsOpen"
      v-model="modalScheduleIsOpen"
      content-class="modal-schedule"
      width="465px"
    >
      <date-home-care
        :value="payload.dateBooking"
        :minDate="minDate"
        :plant="branchforAddress"
        :holiday="holiday"
        :enableDays="getEnableDays"
        @toggleModal="modalScheduleIsOpen = !modalScheduleIsOpen"
        @changeTime="time => changeTime(time)"
      >
      </date-home-care>
    </v-dialog>
    <dialog-pick-test
      :tampilkan="showEditingItem"
      :editData="currentEntry"
      :cantChangeService="
        (rowsOrder.length == 1 && !isEditingItem) || rowsOrder.length > 1
      "
      v-if="showEditingItem"
      :filters="{ plant_id: payload.selectBranch }"
      :familyList="familyListNotSelected"
      @updateChooseTest="data => updateChooseForm(data)"
      @cancelChooseTest="cancelChooseForm"
    ></dialog-pick-test>
  </div>
</template>

<script>
import CardHcTest from "@/components/v3/cards/card-hc-test.vue";
import CustomSection from "@/components/v2/section/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import Icons from "@/components/elements/Icons.vue";
// import CardCartItem from "@/components/v2/card/card_cart_item.vue";
import HeadingPrice from "@/components/Heading/HeadingPrice.vue";
import CButton from "@/components/v2/button/index.vue";
import Voucher from "@/components/v2/voucher/homecare.vue";
import AddressPopUp from "@/components/general/AddressPopUp";
import DateHomeCare from "@/components/v3/date/dateHomeCare.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import Tnc from "@/components/v2/tnc/index.vue";
import DialogPickTest from "@/components/v2/dialog/dialogPickTest.vue";

import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment-timezone";

export default {
  metaInfo() {
    return {
      script: [
        {
          src: process.env.VUE_APP_MIDTRANS_URL,
          "data-client-key": process.env.VUE_APP_MIDTRANS_CLIENT_KEY,
          defer: true,
          callback: () => this.$store.commit("loadMidtrans"),
        },
      ],
    };
  },
  components: {
    CustomSection,
    HeadingCard,
    Icons,
    // CardCartItem,
    HeadingPrice,
    CButton,
    Voucher,
    AddressPopUp,
    NavbarOne,
    Tnc,
    DateHomeCare,
    DialogPickTest,
    CardHcTest,
  },
  data: () => ({
    service_fee: 0,
    typeOfBook: "test",
    dateBook: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modalDate: false,

    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
    branch: [],
    showAddress: false,
    mypackage: null,
    checkbox: false,
    dialogMoreFamily: false,
    searchById: "",
    found: false,
    searchResult: { id: "", name: "" },
    relationItem: [],
    collapse: {
      order: false,
      payment: false,
      voucher: false,
    },
    notForMe: false,
    totalDiscount: 0,
    discountSet: 0,
    tax: 0,
    opendatepicker: false,
    formHasErrors: false,
    errorMessages: "",
    midtransStatus: 406,
    //home care
    product_order: [],
    newDataProduct: [],
    arrProd: [],
    newSetData: [],
    showVoucher: false,
    invoice: [],
    listDiscount: [],
    activeVoucher: [],
    maxList: 10,
    inputfam: false,
    formAdd: false,
    editedIndex: -1,
    isEdit: false,
    modalAddressIsOpen: false,
    modalMapIsOpen: false,
    modalScheduleIsOpen: false,
    valid: true,
    validFormMember: true,
    validMemberFamily: true,
    modal: false,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    branchtest: [],
    testlab: [],
    country: [],
    province: [],
    // opendatepicker: false,
    branchforAddress: "",
    city: [],
    district: [],
    subdistrict: [],
    familyList: [],
    testDate: [],
    profile: {
      person: {
        id: "",
        name: "",
        registered_ids: [{ description: "" }, { description: "" }],
        gender: { name: "" },
        practitioner: { plant: { name: "" } },
        addresses: [{ country: "", address: "" }],
        contacts: [{ description: "" }],
        patient: {
          id: "",
        },
      },
    },
    appointmentList: [],
    appointmentListPackage: [],
    // branchNow: [],
    nameMember: "",
    idMember: "",
    memberFamily: "",
    searchIDMember: "",
    typeRelation: [],
    setRelation: "",
    payload: {
      longlat: {},
      appointment: "",
      setMember: "",
      payor: "",
      selectSubdistrict: "",
      address: "",
      selectBranch: "",
      booking_date: "",
      addressInput: "",
      addressInput2: "",
      dateBooking: "",
      timeBooking: {},
      registration_group: "",
    },
    mergeHomecare: false,
    patientMergeHomecare: [],
    headerTable: [],
    dataTable: [],
    rowsOrder: [],
    isEditingItem: false,
    showEditingItem: false,
    editingIndex: -1,
    currentEntry: {},
    defaultItem: {
      memberFamily: "",
      payment: [],
      test: [],
    },
    openEdit: false,
    editedItemMoreFamily: {
      related_person_name: "",
      relation_name: "",
      relation: "",
      person_id: "",
      description: "-",
    },
    defaultItemMoreFamily: {
      id: "",
      fullname: "",
      birthdate: "",
      relationship: "",
    },
    editedItem: {
      memberFamily: "",
      payment: [],
      test: [],
      package: [],
    },
    dialog: false,

    newAddress: "",

    holiday: [],

    checkTnc: false,
  }),
  watch: {
    rowsOrder() {
      this.getDiscount();
    },
    typeOfBook(now, then) {
      this.editedItem[then] = [];
    },
    payload: {
      handler() {
        this.valid = true;
      },
      deep: true,
    },
    checkTnc() {
      this.valid = true;
    },
    "payload.selectBranch": function (val) {
      const indexHoliday = this.branchtest.findIndex(item => item.id == val);
      if (indexHoliday > -1) {
        this.holiday = this.branchtest[indexHoliday].upcoming_holidays;
      }
      if (!this.mergeHomecare) this.payload.dateBooking = "";
      this.branchforAddress = this.payload.selectBranch.toString();
      this.getServiceFee();
    },
    "editedItem.memberFamily": function (now) {
      if (now) {
        // const indexperson = this.familyList.findIndex(
        //   res => res.patient_id == now
        // );
      }
    },
    mergeHomecare() {
      this.payload = {
        longlat: {},
        appointment: "",
        setMember: "",
        payor: "",
        selectSubdistrict: "",
        address: "",
        selectBranch: "",
        booking_date: "",
        addressInput: "",
        addressInput2: "",
        dateBooking: "",
        timeBooking: {},
        registration_group: "",
      };
    },
  },
  computed: {
    familyListNotSelected() {
      return this.familyList.filter(family => {
        if (this.isEditingItem) {
          return family.patient_id == this.currentEntry.memberFamily;
        } else {
          if (this.mergeHomecare && this.patientMergeHomecare.length > 0) {
            return !this.patientMergeHomecare.some(
              row => row.patient_id == family.patient_id
            );
          }
          return !this.rowsOrder.some(
            row => row.memberFamily == family.patient_id
          );
        }
      });
    },
    // start enable days
    getEnableDays() {
      let enableDays = [];
      const days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      const { opening_hours } = !this.getDataPlant || this.getDataPlant;

      for (let i = 0; i < days.length; i++) {
        const indexDays =
          opening_hours == undefined
            ? -1
            : opening_hours.findIndex(item => item.day == days[i]);
        if (indexDays > -1) {
          enableDays.push(i);
        }
      }
      return enableDays;
    },
    // end enable days
    // start get plant detail
    getDataPlant() {
      const indexPlant = this.branchtest.findIndex(
        item => item.id == this.payload.selectBranch
      );
      if (indexPlant > -1) {
        return this.branchtest[indexPlant];
      }
      return null;
    },
    // end get plant detail

    minDate() {
      let currentTime = moment();
      const indexPlant = this.branchtest.findIndex(
        res => res.id == this.payload.selectBranch
      );
      // check timezone time
      if (
        this.payload.selectBranch != null &&
        this.payload.selectBranch != ""
      ) {
        if (indexPlant > -1) {
          const timezone = moment.tz.guess();
          if (this.branchtest[indexPlant].timezone_area == timezone) {
            currentTime = moment();
          } else {
            const now = moment().format("HH:mm:ss");
            currentTime = moment(
              this.convertTime(
                now,
                moment.tz.guess(),
                this.branchtest[indexPlant].timezone_area
              ),
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        }
      }
      // Set the start and end time boundaries
      let startTime = "";
      let endTime = "";

      if (indexPlant > -1) {
        const { opening_hours } = this.branchtest[indexPlant];
        const indexByDayName = opening_hours.findIndex(
          item => item.day == currentTime.locale("ID").format("dddd")
        );
        if (indexByDayName > -1) {
          const close = opening_hours[indexByDayName].hours
            .split("-")[1]
            .replace(":00", "");
          startTime = moment()
            .hour(0) // mulai jam 00:00
            .minute(0)
            .second(0);
          endTime = moment().hour(close).minute(0).second(0);
        }
      }

      // Check if the current time is between the start and end time
      const isWithinTimeRange =
        indexPlant > -1 ? currentTime.isBetween(startTime, endTime) : true;
      // Output the result
      let found = false;
      let i = !isWithinTimeRange ? 2 : 1;
      if (this.getEnableDays.length > 0) {
        while (!found) {
          let date = moment().add(i, "days");
          if (
            this.holiday.findIndex(item => date.isSame(item.date, "day")) < 0 &&
            this.getEnableDays.includes(date.day())
          ) {
            found = true;
          } else {
            found = false;
            i++;
          }
        }
        return moment().add(i, "days").format("YYYY-MM-DD");
      } else {
        return moment().format("YYYY-MM-DD");
      }
    },

    formMergeHomecare() {
      return { registration_group: this.payload.registration_group };
    },
    bookingHomecare() {
      return this.payload.dateBooking
        ? moment(this.payload.dateBooking).format("HH:mm, DD-MMM-YYYY")
        : "";
    },
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
    dateFormatted() {
      return this.formatDate(this.payload.booking_date);
    },
    form() {
      return {
        booking_date: this.payload.booking_date,
        branch: this.payload.selectBranch,
      };
    },
    formMoreFamily() {
      return {
        id: this.name,
        fullname: this.fullname,
        birthdate: this.birthdate,
        relationship: this.relationship,
      };
    },
  },
  async mounted() {
    this.headerTable = [
      {
        text: this.$t("page.office_care.family_member"),
        value: "memberFamily",
        sortable: false,
        width: "150px",
        align: "center",
      },
      {
        text: this.$t("field.description"),
        value: "test",
        sortable: false,
        width: "240px",
        align: "center",
      },
      {
        text: this.$t("page.cart.total_price"),
        value: "total_price",
        sortable: false,
        align: "center",
        width: "150px",
      },
      {
        text: "",
        value: "tools",
        sortable: false,
        align: "center",
        width: "90px",
      },
    ];
    this.$store.commit("setLoading", true);
    // MM YY DD
    const now = new Date();
    this.testDate = this.getDates(
      new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
      new Date(new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()))
    );
    //get profile
    if (this.dataProfile) {
      this.profile = this.dataProfile;
      this.payload.payor = this.profile.person.name;
      this.payload.address = "";
      this.payload.addressInput = "";
      this.payload.addressInput2 = "";
      this.payload.selectSubdistrict = "";
    }

    //get branch by slug
    API.get(
      `${process.env.VUE_APP_API_TRUST}plant/get?show_opening_hours=true`,
      {
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then(res => {
      if (res.statusCode === 200) {
        this.branchtest = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // place
    await API.get(`${process.env.VUE_APP_API_TRUST}city/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.birthPlace = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // sub district
    await API.get(`${process.env.VUE_APP_API_TRUST}subdistrict/get`, {}).then(
      res => {
        if (res.statusCode == 200) {
          this.subdistrict = res.results.sort((a, b) => this.sortByName(a, b));
        }
      }
    );
    // district
    await API.get(`${process.env.VUE_APP_API_TRUST}district/get`, {}).then(
      res => {
        if (res.statusCode == 200) {
          this.district = res.results.sort((a, b) => this.sortByName(a, b));
        }
      }
    );

    // city
    await API.get(`${process.env.VUE_APP_API_TRUST}city/get`, {}).then(res => {
      if (res.statusCode == 200) {
        this.city = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });
    // province
    await API.get(
      `${process.env.VUE_APP_API_TRUST}province/get?country_code=ID`,
      {}
    ).then(res => {
      if (res.statusCode == 200) {
        this.province = res.results.sort((a, b) => this.sortByName(a, b));
      }
    });

    // country
    await API.get(`${process.env.VUE_APP_API_TRUST}country/get`, {}).then(
      res => {
        if (res.statusCode == 200) {
          this.country = res.results.sort((a, b) => this.sortByName(a, b));
        }
      }
    );

    // get service
    this.$store.commit("setLoading", false);
  },
  created() {
    if (this.$route.query.transaction_status) {
      if (this.$route.query.transaction_status == "pending") {
        this.$router.push(`/${this.$i18n.locale}/payment-pending`);
      } else {
        this.$router.push(
          `/${this.$i18n.locale}/payment-success?order_id=${this.$route.query.order_id}&status_code=${this.$route.query.status_code}&transaction_status=${this.$route.query.transaction_status}&home_care=true`
        );
      }
    }
    this.getRelationList();
    this.getfamily();
    // set up data frst load
    this.currentEntry = this.getEmptyEntry();
    // end setup
  },
  methods: {
    getEmptyEntry() {
      return {
        type: this.rowsOrder.length > 0 ? this.rowsOrder[0].type : "test",
        items: [],
        memberFamily: "",
      };
    },

    // start convert time
    convertTime(timeString, sourceTimeZone, targetTimeZone) {
      // Parse the original time string in the source timezone
      const originalTime = moment.tz(timeString, "HH:mm:ss", sourceTimeZone);

      // Convert the time to the target timezone
      const convertedTime = originalTime.clone().tz(targetTimeZone);

      // Format the converted time as "HH:mm:ss"
      const formattedTime = convertedTime.format("YYYY-MM-DD HH:mm:ss");

      return formattedTime;
    },
    // end convert time
    addChooseForm() {
      this.isEditingItem = false;
      this.currentEntry = this.getEmptyEntry();
      this.showEditingItem = true;
    },
    deleteRow(index) {
      if (index > -1 && index < this.rowsOrder.length) {
        this.rowsOrder.splice(index, 1);
      } else {
        console.error("Invalid index:", index);
      }
    },
    editRow(index) {
      this.isEditingItem = true;
      this.editingIndex = index;
      this.currentEntry = { ...this.rowsOrder[index] };
      this.showEditingItem = true;
    },
    updateChooseForm(data) {
      if (this.isEditingItem) {
        this.$set(this.rowsOrder, this.editingIndex, { ...data });
      } else {
        this.rowsOrder.push({ ...data });
      }
      this.cancelChooseForm();
    },
    cancelChooseForm() {
      this.showEditingItem = false;
      this.isEditingItem = false;
      this.editingIndex = -1;
      this.currentEntry = this.getEmptyEntry();
    },
    // start merge homecare
    async checkMerge() {
      if (this.$refs.formMergeHomecare.validate()) {
        try {
          let res = await API.get(
            `${process.env.VUE_APP_API_TRUST}invoices/get-detail-homecare?registration_group=${this.payload.registration_group}`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            }
          );
          this.patientMergeHomecare = [];
          if (res.statusCode === 200) {
            const {
              sample_pickup_location,
              booking_date,
              plant_id,
              address_subdistric_name,
              address_district_name,
              address_city_name,
              address_province_name,
              address_country_name,
              address_postal_code,
            } = res.results;
            this.payload.address = sample_pickup_location;
            this.payload.dateBooking = booking_date;
            this.payload.timeBooking.start_at = booking_date;
            this.payload.selectBranch = plant_id;
            this.payload.addressInput2 = `${address_subdistric_name || "-"}, ${
              address_district_name || "-"
            }, ${address_city_name || "-"}, ${address_province_name || "-"}, ${
              address_postal_code || "-"
            }, ${address_country_name || "-"}`;
            this.patientMergeHomecare = res.results.participant;
          } else {
            this.$swal({
              icon: "error",
              text: res.message,
            });
          }
        } catch (error) {
          console.log("Error 403! hc detail");
        }
      }
    },
    // end merge homecare
    //get family list
    async getfamily() {
      try {
        let res = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/list`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (res.statusCode === 200) {
          if (res.results != null) {
            var dataDefault = [
              ...res.results.sort((a, b) => this.sortByName(a, b)),
            ];
            this.familyList = dataDefault;
          } else {
            this.familyList = "data not available";
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getRelationList() {
      try {
        const res = await API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/get-rel-type`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.relationItem = res.results;
      } catch (error) {
        console.log(error);
      }
    },
    saveMoreFamily() {
      if (this.$refs.formMoreFamily.validate() == true) {
        const indexrelation = this.relationItem.findIndex(
          e => e.id == this.editedItemMoreFamily.relation
        );
        this.editedItemMoreFamily.relation_name =
          this.relationItem[indexrelation].name;
        API.post(
          `${process.env.VUE_APP_API_TRUST}patient-related/add`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          { ...this.editedItemMoreFamily }
        ).then(res => {
          if (res.statusCode == 200) {
            this.found = false;
            location.reload();
            // this.closeMoreFamily();
          }
        });
      }
    },
    closeMoreFamily() {
      this.errorMessages = [];
      this.getfamily();
      this.formHasErrors = false;
      this.dialogMoreFamily = false;
      this.$refs.formMoreFamily.reset();
      this.$refs.formMoreFamily.resetValidation();
      this.$nextTick(() => {
        this.editedItemMoreFamily = Object.assign(
          {},
          this.defaultItemMoreFamily
        );
        this.editedIndexMoreFamily = -1;
      });
      this.notForMe = false;
    },
    searchFamily() {
      if (this.searchById != "") {
        API.get(
          `${process.env.VUE_APP_API_TRUST}patient-related/search-id?id_number=${this.searchById}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        ).then(res => {
          if (res.statusCode == 200) {
            this.found = res.results != null;
            this.searchResult = res.results;
            if (res.results != null) {
              const indexFamily = this.familyList.findIndex(
                item => item.person_id == res.results.id
              );
              if (indexFamily > -1) {
                this.found = false;
                this.searchResult = "already";
              } else {
                this.editedItemMoreFamily.person_id = res.results.id;
                this.editedItemMoreFamily.related_person_name =
                  res.results.name;
              }
            } else {
              this.found = false;
              this.searchResult = "notfound";
            }
          }
        });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    changeTime(time) {
      this.modalScheduleIsOpen = false;
      this.payload.timeBooking = {
        start_at: moment(time).format("YYYY-MM-DD HH:mm:ss"),
      };
      this.payload.dateBooking = time;
    },
    formatDateTime(date) {
      return date == null
        ? moment().locale(this.$i18n.locale).format("HH:mm, DD MMM yyyy")
        : moment(date).locale(this.$i18n.locale).format("HH:mm, DD MMM yyyy");
    },
    getDates(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      const addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      while (currentDate <= endDate) {
        // if ((currentDate.getDay() != 0) & (currentDate.getDay() < 6))
        dates.push(currentDate);
        currentDate = addDays.call(currentDate, 1);
      }
      return dates;
    },
    validate() {
      if (this.$refs.form.validate() == true) {
        this.valid = true;
        const { address, selectBranch, selectSubdistrict } = this.payload;
        if (this.rowsOrder.length < 1) {
          this.$swal({
            text: this.$t("patient_cant_empty"),
            showCancelButton: false,
            confirmButtonColor: "#1A428A",
            confirmButtonText: this.$t("ok"),
          });
        } else {
          this.valid = false;
          const listPatient = [];
          this.rowsOrder.forEach(order => {
            let test = [];
            let product_order_detail = [];
            let tmpOrder = {
              patient_id: order.memberFamily,
              description: "-",
            };
            if (order.type == "package") {
              order.items.forEach((item, index) => {
                if (index == 0) {
                  tmpOrder.package = {
                    id: item.id,
                    amount: parseFloat(item.prices[0].amount),
                  };
                  item.detail_package.forEach(detail => {
                    product_order_detail.push({
                      discount_amount: 0,
                      discount_in_percent: null,
                      id: detail.product_id,
                      item_applied: 0,
                      item_total: 1,
                      product_id: detail.product_id,
                      product_status: "main",
                      qty: 1,
                    });
                  });
                } else {
                  product_order_detail.push({
                    discount_amount: item.applyDiscount
                      ? item.applyDiscount.discount_amount
                      : 0,
                    discount_in_percent: item.applyDiscount
                      ? item.applyDiscount.discount_in_percent
                      : null,
                    final_price: parseFloat(item.amount),
                    id: item.product_id,
                    item_applied: item.applyDiscount
                      ? item.applyDiscount.item_applied
                      : 0,
                    item_total: 1,
                    price_per_item: parseFloat(item.amount),
                    price_total: parseFloat(item.amount),
                    product_id: item.product_id,
                    product_status: "addons",
                    qty: 1,
                    voucher: item.applyDiscount
                      ? item.applyDiscount.voucher
                      : null,
                  });
                }
              });
            } else {
              order.items.forEach(item => {
                test.push(item.id);
                product_order_detail.push({
                  discount_amount: item.applyDiscount
                    ? item.applyDiscount.discount_amount
                    : 0,
                  discount_in_percent: item.applyDiscount
                    ? item.applyDiscount.discount_in_percent
                    : null,
                  final_price: parseFloat(item.prices[0].amount),
                  id: item.id,
                  item_applied: item.applyDiscount
                    ? item.applyDiscount.item_applied
                    : 0,
                  item_total: 1,
                  price_per_item: parseFloat(item.prices[0].amount),
                  price_total: parseFloat(item.prices[0].amount),
                  product_id: item.id,
                  product_status: "main",
                  qty: 1,
                  voucher: item.applyDiscount
                    ? item.applyDiscount.voucher
                    : null,
                });
              });
              tmpOrder.test = test;
            }
            tmpOrder.product_order_detail = product_order_detail;

            listPatient.push({ ...tmpOrder });
          });
          // console.log(listPatient);
          // return;

          // eslint-disable-next-line no-unreachable
          let dataReady = [];
          if (this.mergeHomecare) {
            dataReady = [
              {
                registration_group: this.payload.registration_group,
                subdistrict_code: selectSubdistrict,
                address,
                start_at: this.payload.timeBooking.start_at,
                payment_method_id: 1,
                patient_id: this.profile.person.patient.id,
                latitude: "0",
                longitude: "0",
                plant_id: selectBranch,
                homecare_detail: listPatient,
              },
            ];
          } else {
            dataReady = [
              {
                subdistrict_code: selectSubdistrict,
                address,
                start_at: this.payload.timeBooking.start_at,
                payment_method_id: 1,
                patient_id: this.profile.person.patient.id,
                latitude: "0",
                longitude: "0",
                plant_id: selectBranch,
                homecare_detail: listPatient,
              },
            ];
          }

          API.post(
            `${process.env.VUE_APP_API_TRUST}${
              this.mergeHomecare
                ? "registration/join-homecare"
                : "registration/book-homecare"
            }`,
            // ?lang=${this.$i18n.locale}
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            dataReady
          )
            .then(res => {
              if (res.statusCode === 200) {
                if (this.$store.state.midtransIsLoaded) {
                  const vuethis = this;
                  window.snap.pay(res.results[0].midtrans_token, {
                    onSuccess: function (result) {
                      vuethis.midtransStatus = result.statusCode;
                      vuethis.$router.push(
                        `/${vuethis.$i18n.locale}/payment-success${result.finish_redirect_url}&home_care=true`
                      );
                    },
                    onPending: function (result) {
                      vuethis.midtransStatus = result.statusCode;

                      API.get(
                        `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${result.order_id}`,
                        {
                          Authorization: `Bearer ${Auth.getToken()}`,
                        }
                      ).then(res => {
                        if (res.status_code == 200) {
                          vuethis.midtransStatus = res.status_code;
                          vuethis.$router.push(
                            // `/${vuethis.$i18n.locale}/payment-success${result.finish_redirect_url}`
                            `/${vuethis.$i18n.locale}/payment-success?order_id=${res.order_id}&status_code=${res.status_code}&transaction_status=${res.transaction_status}&home_care=true`
                          );
                        } else {
                          vuethis.$router.push(
                            `/${vuethis.$i18n.locale}/payment-pending?order_id=${res.order_id}&status_code=${res.status_code}&transaction_status=${res.transaction_status}&home_care=true`
                          );
                        }
                      });
                    },
                    onClose: function () {
                      vuethis
                        .$swal({
                          text: vuethis.$t("the_order_has_been_saved"),
                          showCancelButton: false,
                          confirmButtonColor: "#f7685b",
                          confirmButtonText: vuethis.$t("close"),
                        })
                        .then(() => {
                          vuethis.$router.push(
                            `/${vuethis.$i18n.locale}/patient/profile?tab=paymentinfo`
                          );
                        });
                    },
                    onError: function (result) {
                      vuethis.midtransStatus = result.statusCode;
                      vuethis.$swal({
                        icon: "error",
                        text: vuethis.$t("error_payment"),
                      });
                    },
                  });
                }
              } else if (res.statusCode == 403) {
                Object.keys(res.errors).forEach(element => {
                  this.toast(
                    "error",
                    typeof res.errors[element] == "object"
                      ? res.errors[element][0]
                      : res.errors[element]
                  );
                });
              } else {
                this.$swal({
                  icon: "error",
                  text: res.message,
                });
              }
            })
            .catch(() => {
              this.$swal({
                icon: "error",
                text: this.$t("error_booking"),
              });
            });
        }
      }
    },
    sortByName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    formatDate(date) {
      if (!date) return null;
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY")
        .toUpperCase();
    },
    formatDateBooking(date) {
      if (!date) return null;
      // const MMM = {
      //   "01": "Jan",
      //   "02": "Feb",
      //   "03": "Mar",
      //   "04": "Apr",
      //   "05": "May",
      //   "06": "June",
      //   "07": "July",
      //   "08": "Aug",
      //   "09": "Sept",
      //   "10": "Oct",
      //   "11": "Nov",
      //   "12": "Dec",
      // };
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    openModalAddress() {
      this.modalAddressIsOpen = true;
    },
    closeModalAddress() {
      this.modalAddressIsOpen = false;
    },
    openModalMap() {
      this.modalMapIsOpen = true;
    },
    closeModalMap() {
      this.modalMapIsOpen = false;
      this.showAddress = true;
    },
    setAddress(param) {
      this.payload.addressInput2 = param.name;
      this.payload.selectSubdistrict = param.idsubdistrict;
    },
    getServiceFee() {
      API.get(`${process.env.VUE_APP_API_TRUST}homecare/get-service-price`, {
        Authorization: `Bearer ${Auth.getToken()}`,
      }).then(res => {
        if (res.statusCode == 200) {
          this.service_fee = parseFloat(res.results["homecare-fee"].value);
        }
      });
    },

    close() {
      this.isEdit = false;
      this.openEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    // save edit
    async save() {
      // if (this.$refs.formMember.validate() == true) {
      //   if (this.editedIndex > -1) {
      //     let testNew = [];
      //     if (this.typeOfBook == "test") {
      //       testNew = this.appointmentList.filter(item => {
      //         return this.editedItem[this.typeOfBook].some(
      //           itm => itm.id == item.id
      //         );
      //       });
      //       // this.editedItem.test.forEach(id => {
      //       //   testNew = [...testNew, { id: id, qty: 1 }];
      //       // });
      //     } else {
      //       testNew = this.appointmentListPackage.filter(item => {
      //         return this.editedItem[this.typeOfBook].some(
      //           itm => itm.id == item.id
      //         );
      //       });
      //       // this.editedItem.package.forEach(id => {
      //       //   testNew = [...testNew, { id: id, qty: 1 }];
      //       // });
      //     }
      //     console.log("edit > -1");
      //     console.log({ testNew });
      //     console.log(this.editedItem);
      //     const payload = {
      //       plant_id: this.payload.selectBranch,
      //       items: testNew,
      //       vouchers: this.activeVoucher,
      //       type: this.typeOfBook == "test" ? "product" : "package",
      //     };

      //     try {
      //       const resp = await API.post(
      //         `${process.env.VUE_APP_API_TRUST}discount/apply?lang=${this.$i18n.locale}`,
      //         {
      //           "Content-Type": "application/json",
      //           Authorization: `Bearer ${Auth.getToken()}`,
      //         },
      //         payload
      //       );
      //       if (resp.statusCode == 200) {
      //         this.editedItem.payment = resp.results;
      //         Object.assign(this.dataTable[this.editedIndex], this.editedItem);
      //       } else if (resp.statusCode == 403) {
      //         Object.keys(resp.errors).forEach(element => {
      //           this.toast(
      //             "error",
      //             typeof resp.errors[element] == "object"
      //               ? resp.errors[element][0]
      //               : resp.errors[element]
      //           );
      //         });
      //       } else {
      //         this.$swal({
      //           icon: "error",
      //           text: resp.message,
      //         });
      //       }
      //     } catch (error) {
      //       console.log(error);
      //     }
      //   } else {
      const indexEdit = this.dataTable.findIndex(
        res => res.memberFamily == this.editedItem.memberFamily
      );
      if (indexEdit >= 0) {
        let testNew = [];
        if (this.typeOfBook == "test") {
          this.appointmentList.forEach(item => {
            if (this.editedItem[this.typeOfBook].includes(item.id)) {
              testNew = [
                ...testNew,
                {
                  price_total: parseFloat(item.prices[0].amount),
                  final_price: parseFloat(item.prices[0].amount),
                },
              ];
            }
          });
        } else {
          this.appointmentListPackage.forEach(item => {
            if (this.editedItem[this.typeOfBook].includes(item.id)) {
              testNew = [
                ...testNew,
                {
                  price_total: parseFloat(item.prices[0].amount),
                  final_price: parseFloat(item.prices[0].amount),
                },
              ];
            }
          });
        }
        this.editedItem.payment = testNew;
        Object.assign(this.dataTable[indexEdit], this.editedItem);

        // const payload = {
        //   plant_id: this.payload.selectBranch,
        //   items: testNew,
        //   vouchers: this.activeVoucher,
        //   type: "product",
        // };

        // try {
        //   const resp = await API.post(
        //     `${process.env.VUE_APP_API_TRUST}discount/apply?lang=${this.$i18n.locale}`,
        //     {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${Auth.getToken()}`,
        //     },
        //     payload
        //   );
        //   if (resp.statusCode == 200) {
        //     this.editedItem.payment = resp.results;
        //     Object.assign(this.dataTable[indexEdit], this.editedItem);
        //   } else if (resp.statusCode == 403) {
        //     Object.keys(resp.errors).forEach(element => {
        //       this.toast(
        //         "error",
        //         typeof resp.errors[element] == "object"
        //           ? resp.errors[element][0]
        //           : resp.errors[element]
        //       );
        //     });
        //   } else {
        //     this.$swal({
        //       icon: "error",
        //       text: resp.message,
        //     });
        //   }
        // } catch (error) {
        //   console.log(error);
        // }
      } else {
        if (this.dataTable.length < this.maxList) {
          let testNew = [];
          if (this.typeOfBook == "test") {
            this.appointmentList.forEach(item => {
              if (this.editedItem[this.typeOfBook].includes(item.id)) {
                testNew = [
                  ...testNew,
                  {
                    price_total: parseFloat(item.prices[0].amount),
                    final_price: parseFloat(item.prices[0].amount),
                  },
                ];
              }
            });
          } else {
            this.appointmentListPackage.forEach(item => {
              if (this.editedItem[this.typeOfBook].includes(item.id)) {
                testNew = [
                  ...testNew,
                  {
                    price_total: parseFloat(item.prices[0].amount),
                    final_price: parseFloat(item.prices[0].amount),
                  },
                ];
              }
            });
          }
          this.editedItem.payment = testNew;
          this.dataTable = [...this.dataTable, this.editedItem];

          // const payload = {
          //   plant_id: this.payload.selectBranch,
          //   items: testNew,
          //   vouchers: this.activeVoucher,
          //   type: "product",
          // };

          // try {
          //   const resp = await API.post(
          //     `${process.env.VUE_APP_API_TRUST}discount/apply?lang=${this.$i18n.locale}`,
          //     {
          //       "Content-Type": "application/json",
          //       Authorization: `Bearer ${Auth.getToken()}`,
          //     },
          //     payload
          //   );
          //   if (resp.statusCode == 200) {
          //     this.editedItem.payment = resp.results;
          //     this.dataTable = [...this.dataTable, this.editedItem];
          //   } else if (resp.statusCode == 403) {
          //     Object.keys(resp.errors).forEach(element => {
          //       this.toast(
          //         "error",
          //         typeof resp.errors[element] == "object"
          //           ? resp.errors[element][0]
          //           : resp.errors[element]
          //       );
          //     });
          //   } else {
          //     this.$swal({
          //       icon: "error",
          //       text: resp.message,
          //     });
          //   }
          // } catch (error) {
          //   console.log(error);
          // }
        } else {
          this.$swal({
            text: this.$t("limit_member_order"),
            confirmButtonColor: "#1A428A",
            confirmButtonText: this.$t("ok"),
          });
        }
      }
      // }

      this.getDiscount();
      console.log("voucher active", this.activeVoucher);
      if (this.activeVoucher) {
        this.updateInvoice();
      }
      this.close();
      // }
    },
    getTotalPyamentInvoice(item) {
      let total = { price_total: 0, final_price: 0 };
      item.forEach(element => {
        total.price_total += element.price_total;
        total.final_price += element.final_price;
      });
      return total;
    },
    getAllTotalPyamentInvoice() {
      let total = { price_total: 0, final_price: 0 };
      this.rowsOrder.forEach(order => {
        order.items.forEach(item => {
          total.price_total += parseFloat(
            item.prices ? item.prices[0].amount : item.amount
          );
          total.final_price += parseFloat(
            item.prices ? item.prices[0].amount : item.amount
          );
        });
      });
      // add service fee
      total.final_price += this.service_fee;
      // - discount
      total.final_price -= this.totalDiscount;
      // this.invoice.forEach(res => {
      //   res.payment.forEach(test => {
      //     total.price_total += test.price_total;
      //     total.final_price += test.final_price;
      //   });
      // });
      return total;
    },
    async updateInvoice() {
      this.invoice = [];
      await this.dataTable.forEach(async item => {
        const payment = await this.calculatePayment(item.test);
        this.invoice = [
          ...this.invoice,
          { memberFamily: item.memberFamily, test: item.test, payment },
        ];

        payment.forEach(item => {
          this.discountSet += item.discount_amount;
        });
        this.totalDiscount = this.totalDiscount + this.discountSet;
      });
      // this.dataTable = this.invoice;
    },
    updateInvoiceVoucher() {
      this.totalDiscount = 0;
      this.rowsOrder.map(async row => {
        let discount = 0;
        let invoice = await this.calculatePayment(row.items);
        invoice.forEach(item => {
          discount += item.discount_amount;
        });
        this.totalDiscount += discount;
        row.items.map(item => {
          item.applyDiscount = null;
          const indexInv = invoice.findIndex(inv => inv.id == item.id);
          if (indexInv > -1) {
            item.applyDiscount = invoice[indexInv];
          }
          return item;
        });
        return row;
      });
    },
    // calculate payment
    async calculatePayment(items) {
      let test = [];
      items.forEach(item => {
        test = [...test, { id: item.id, qty: 1 }];
      });
      const payload = {
        plant_id: this.payload.selectBranch,
        items: test,
        vouchers: this.activeVoucher,
        type: "product",
      };

      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}discount/apply?lang=${this.$i18n.locale}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          payload
        );
        if (resp.statusCode == 200) {
          return resp.results;
        } else if (resp.statusCode == 403) {
          Object.keys(resp.errors).forEach(element => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // check voucher active
    checkVoucherActive(voucher) {
      return this.activeVoucher.some(item => item == voucher);
    },

    // apply discount
    applyVoucher(id) {
      if (!this.checkVoucherActive(id)) {
        this.activeVoucher.push(id);
        this.totalDiscount = 0;
        this.discountSet = 0;
        this.updateInvoiceVoucher();
      }
    },
    // unapply discount
    unApplyVoucher(id) {
      if (this.checkVoucherActive(id)) {
        this.activeVoucher.splice(this.activeVoucher.indexOf(id), 1);
        this.totalDiscount = 0;
        this.discountSet = 0;
        this.updateInvoiceVoucher();
      }
    },
    // get discount
    async getDiscount() {
      this.activeVoucher = [];
      this.totalDiscount = 0;
      if (this.rowsOrder.length > 0) {
        let allTest = [];
        await this.rowsOrder.forEach(async item => {
          await item.items.forEach(product => {
            const exist = allTest.some(idTest => idTest == product.id);
            if (!exist) {
              allTest = [...allTest, product.id];
            }
          });
        });
        const payloadDiscount = {
          ids: allTest,
          plant_ids: [this.payload.selectBranch],
          type: "product",
        };
        try {
          const resp = await API.post(
            `${process.env.VUE_APP_API_TRUST}discount/get`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            payloadDiscount
          );
          if (resp.statusCode === 200 && resp.results.length > 0) {
            this.listDiscount = resp.results;
          } else {
            this.listDiscount = [];
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.listDiscount = [];
      }
    },
    getFamilyById(id) {
      const indexperson = this.familyList.findIndex(
        res => res.patient_id == id
      );
      if (indexperson >= 0) {
        return this.familyList[indexperson].related_person_name;
      } else {
        return "";
      }
    },
    getAllTest(items) {
      let text = "";
      items.forEach((item, index) => {
        if (this.$i18n.locale == "id") {
          text += item.name;
        } else {
          text += item.name_en;
        }

        text += index < items.length - 1 ? ` | ` : "";
      });
      return text;
    },
    getAllPackage(items) {
      let text = "";
      items.forEach((item, index) => {
        text +=
          index == 0
            ? item.name
            : item[
                this.$i18n.locale == "id"
                  ? `product_name`
                  : `product_name_${this.$i18n.locale}`
              ];

        text += index < items.length - 1 ? ` | ` : "";
      });
      return text;
    },
    getTest(id) {
      const indexTest = this.appointmentList.findIndex(res => res.id == id);
      if (indexTest < 0) {
        return "Test Not Found";
      } else {
        if (this.$i18n.locale == "id") {
          return this.appointmentList[indexTest].name;
        } else {
          return this.appointmentList[indexTest].name_en;
        }
        //return this.appointmentList[indexTest].name;
      }
    },
    getPackage(id) {
      const indexTest = this.appointmentListPackage.findIndex(
        res => res.id == id
      );
      if (indexTest < 0) {
        return "Test Not Found";
      } else {
        return this.appointmentListPackage[indexTest].name;
      }
    },
    getTotalAllTestPrice(items) {
      let total = 0;
      items.forEach(item => {
        if (item.prices.length > 0) {
          total += parseFloat(item.prices[0].amount);
        } else {
          total += 0;
        }
      });
      return total;
    },
    getTotalAllPackagePrice(items) {
      let total = 0;
      items.forEach(item => {
        if (item.prices && item.prices.length > 0) {
          total += parseFloat(item.prices[0].amount);
        } else {
          if (item.amount) {
            total += parseFloat(item.amount);
          } else {
            total += 0;
          }
        }
      });

      return total;
    },
    getTotalTestPrice(item) {
      let total = 0;
      item.test.forEach(id => {
        const indexTest = this.appointmentList.findIndex(res => res.id == id);
        const test = this.appointmentList[indexTest];
        total = total + parseFloat(test.prices[0].amount);
      });
      item.package.forEach(id => {
        const indexTest = this.appointmentListPackage.findIndex(
          res => res.id == id
        );
        const paket = this.appointmentListPackage[indexTest];
        total = total + parseFloat(paket.prices[0].amount);
      });
      return this.formatPrice(total);
    },
    deleteItem(param) {
      this.$swal({
        text: this.$t("are_u_sure_delete_item"),
        showCancelButton: true,
        confirmButtonColor: "#1A428A",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("ok"),
        cancelButtonText: this.$t("cancel"),
      }).then(result => {
        if (result.isConfirmed) {
          this.editedIndex = this.dataTable.indexOf(param);
          this.editedItem = Object.assign({}, param);
          this.dataTable.splice(this.editedIndex, 1);
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
          this.getDiscount();
          this.updateInvoice();
        }
      });
    },
    editItem(param) {
      this.editedIndex = this.dataTable.indexOf(param);
      this.editedItem = Object.assign({}, param);
      this.isEdit = true;
      this.openEdit = true;
    },

    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
    useThisAddress(e) {
      this.payload.address = e;
    },
    useThisAddressTwo(e) {
      this.payload.addressInput = e;
    },
    useThisAddressThree(e) {
      this.payload.addressInput2 = e;
    },
    useThisLatLng(e) {
      this.payload.longlat = e;
    },
    //this.$refs.autocomplete.focus();
    openEditClick(message) {
      if (this.payload.selectBranch == "") {
        this.$swal({
          icon: "error",
          text: message,
        }).then(() => {
          this.$refs.autocomplete.focus();
        });
      } else {
        this.openEdit = true;
      }
    },
    modalScheduleCheck(message) {
      if (this.payload.selectBranch == "") {
        this.$swal({
          icon: "error",
          text: message,
        }).then(() => {
          this.modalScheduleIsOpen = false;
          this.$refs.autocomplete.focus();
        });
      } else {
        this.modalScheduleIsOpen = true;
      }
    },
    onClickOutside() {
      this.modalScheduleIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-test {
  font-size: 14px !important;
  color: #4b5058;
  border-bottom: 1px solid #858585;
  padding: 10px 0px;
  width: 100%;
  &__price {
    font-size: 14px;
    font-weight: 400;
    color: #858585;
    margin: 0px;
  }
}
.cart {
  .count-item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .billing-box {
    border-bottom: 1px solid #e0e0e0;
  }
  .checkbox {
    &--booking-else {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: gray;
      text-transform: none;
    }
  }
  .voucher {
    margin-bottom: 20px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .collapse--active {
    transform: rotate(180deg);
  }
  a {
    text-decoration: none;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}

.card-cart {
  width: auto;
  height: 60px;
}

.text-field {
  max-width: 70%;
  margin-left: 10px;
}
.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  margin-right: 10px;
  margin-top: -10px;
}

@media only screen and (max-width: 600px) {
  .text-field {
    max-width: 75%;
    margin-left: 9px !important;
    margin-top: 0px;
  }

  .card-cart {
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    left: 0;
    right: 0;
    margin-top: -5px;
  }

  .bell-mobile {
    margin-left: -24px;
  }

  .user-card {
    max-width: 56% !important;
    margin-top: -41px;
    margin-left: 164px;
  }

  .notification {
    height: 55px;
    border-radius: 3px;
    margin-top: -9px;
    margin-left: -97px;
  }

  .cart-right {
    z-index: 1;
    margin-top: -13px;
    margin-right: 49px;
    margin-left: -63px;
  }
}
</style>

<style lang="scss">
.cart {
  .v-text-field {
    &.v-input--dense {
      input {
        font-size: 14px !important;
      }
    }
  }
  .v-select {
    &.v-input--dense {
      font-size: 14px !important;
    }
  }
}
.upper-font {
  text-transform: uppercase !important;
}
.link-to-add {
  color: #00a4b3;
}
.minWidth {
  width: 110px;
}
.bg-white {
  background-color: white !important;
  color: #00a6b6 !important;
  font-weight: 700;
}
.bg-blue {
  background-color: #00a6b6 !important;
  color: white !important;
  font-weight: 700;
}
</style>
